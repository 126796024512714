import React, { useState, useRef, useEffect } from 'react'
import Header from '../../../components/Header'
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  CloseButton,
  FormLabel,
  Heading,
  Input,
  Select,
  Switch,
  Text,
  useDisclosure,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Tag,
  TagCloseButton,
  useToast,
  Spinner,
} from '@chakra-ui/react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import {
  Cog6ToothIcon,
  ChevronRightIcon,
  ClipboardIcon,
  XMarkIcon,
  CheckCircleIcon,
  AdjustmentsHorizontalIcon,
  ClockIcon,
  EnvelopeIcon,
  PaperAirplaneIcon,
  ChatBubbleBottomCenterIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/solid'
import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import FormBuilder from '../../../components/utils/FormBuilder'
import TemplateLaunch from '../../../components/TemplateLaunch'
import Editor from 'react-simple-wysiwyg'
import DrawerExample from './ConfigDrawer'
import { MultiSelect } from 'chakra-multiselect'
import {
  openInviteMemberModal,
  closeInviteMemberModal,
} from '../../../redux/InviteMemberModal'
import {
  openCreateGroupModal,
  closeCreateGroupModal,
} from '../../../redux/CreateGroupsModal'
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Textarea,
} from '@chakra-ui/react'
import AddAutomations from './AddAutomations'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import {
  openTemplateLaunchModal,
  closeTemplateLaunchModal,
} from '../../../redux/TemplateLaunchModal'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import TemplateStep from '../../../components/TemplateStep'
import CreateGroups from '../../../components/CreateGroups'
import {
  addComment,
  fetchTemplates,
  updateTemplateWithoutPull,
  updateSteps,
  updateTemplate,
  deleteStep,
} from '../../../redux/TemplateSlice'
import { fetchMembers } from '../../../redux/MemberSlice'
import Cookies from 'js-cookie'
import moment from 'moment-timezone';
import { format } from 'date-fns';
import FormRender from '../../../components/utils/FormRender'
import RichTextEditor from '../../../components/utils/RichTextEditor'

function getFormattedDate (utcDate) {
  if(!utcDate) return 
  const date = moment.tz(utcDate, Cookies.get('timeZone'));
  return format(new Date(date.format()), Cookies.get('dateFormat'));
};

function CreateProcedure() {
  const { id } = useParams()
  const navigate = useNavigate()
  if (!id) {
    navigate('/dashboard/templates')
  }
  const dispatch = useDispatch()
  const toast = useToast()

  const [html, setHtml] = useState('my <b>HTML</b>')
  const [trigger, setTrigger] = useState(null)
  const [step, setStep] = useState(null)
  const [assign, setAssign] = useState('fixed')

  const [milestoneName, setMilestoneName] = useState('')
  const [selectedMilestone, setSelectedMilestone] = useState('')

  const [startOnSpecificTime, setStartOnSpecificTime] = useState(false)

  const [taskType, setTaskType] = useState('task')

  const [value, setValue] = useState([])
  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    // Add more options as needed
  ]

  function onChange(html) {
    if(Cookies.get('role') != 'member'){
    setHtml(html)
    }
  }

  const [tags, setTags] = useState(['foo', 'bar'])
  const [tagSearch, setTagSearch] = useState('')

  const { isOpen, onOpen, onClose } = useDisclosure()

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1024px)' })

  const [data, setData] = useState({

  })

  const state = useSelector((state) => state)

  const [steps, setSteps] = useState([])
  const [milestones, setMilestones] = useState([])

  const [stepData, setStepData] = useState({})

  const [isLoading, setIsLoading] = useState(true)

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault() // Prevent form submission
      if (tagSearch.trim() !== '') {
        setStepData({ ...stepData, tags: [...stepData.tags, tagSearch.trim()] })
        setTagSearch('') // Clear input field after adding tag
      }
    }
  }

  const removeTag = (index) => {
    const newTags = [...data.tags]
    newTags.splice(index, 1)
    setStepData({ ...data, tags: newTags })
  }

  const handleAddComment = async (e, text) => {
    e.preventDefault()

    if (text.length > 1) {
      console.log(text)
      dispatch(addComment({ stepId: stepData._id, text: text })) // Dispatch the addTask action with the new task data
        .then(() => {
          toast({
            title: 'Comment Updated!',
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
        })
        .catch((error) => {
          toast({
            title: error.message || 'Something went wrong!',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        })
    } else {
      toast({
        title: 'Invalid comment data!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    dispatch(fetchTemplates())
    dispatch(fetchMembers())
    setIsLoading(false)
  }, [dispatch, addComment])

  useEffect(() => {

    if (stepData && stepData._id) {
      console.log("MAIN PAGE", stepData)
      const temp = steps.map((el, i) => {
        if (el._id == stepData._id) {
          return stepData
        } else {
          return el
        }
      })

      dispatch(updateSteps(temp))

      setSteps(temp)
    }
  }, [stepData])
  

  useEffect(() => {
    if (
      state &&
      state.templates &&
      state.templates.data &&
      state.templates.data.length > 0
    ) {
      console.log(state?.templates?.data)
      setData(state?.templates?.data?.filter((el, i) => el._id == id)[0])
      setSteps(
        state?.templates?.data?.filter((el, i) => el._id == id)[0]?.steps,
      )
      const temp = state.templates.data.filter((el, i) => el._id == id)[0]
      const temp2 =
        temp && temp.steps && temp.steps.length > 0 && stepData && stepData._id
          ? temp.steps.find((el, i) => el._id == stepData._id)
          : {}
      console.log(temp2, 'temp')
      setStepData(temp2)
    } else {
      setData([])
    }
  }, [state?.templates?.data, addComment])

  return (
    <div className="overflow-y-hidden max-h-screen">
      <Modal isCentered isOpen={isLoading}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
          className="items-center flex justify-center"
        >
          <Spinner size="xl" />
        </ModalOverlay>
      </Modal>{' '}
      <Header />
      <div className="ml-2 lg:ml-40 mr-2 space-y-4 py-10 flex flex-row gap-x-4 max-h-[90vh] overflow-y-scroll">
        <div className="w-full mx-auto lg:p-6">
          <div className="w-full border-b border-gray-200 pb-4 mb-4">
            <div className="w-full flex justify-between items-center">
              <div className="w-full flex flex-col">
                <div className="w-full flex flex-col space-y-4 lg:flex-row">
                  {' '}
                  <div className="w-full flex-1">
                    <div className="mt-2 font-bold text-gray-600">
                      Procedure (templateId: {id})
                    </div>
                    <Link className="text-blue-600" href="#">
                      Templates
                    </Link>
                    <span className="mx-1 text-gray-500">{`>`}</span>
                    <Link className="text-blue-600" href="#">
                      {data?.name}
                    </Link>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <Button
                      onClick={onOpen}
                      className="text-gray-600"
                      variant="ghost"
                    >
                      <Cog6ToothIcon className="w-4 h-4 text-current" />
                    </Button>
                    <Button
                      onClick={() => dispatch(openTemplateLaunchModal())}
                      className="bg-blue-600 text-white"
                    >
                      LAUNCH PROCESS
                    </Button>
                  </div>
                </div>

                <Tabs>
                  <TabList className="my-4">
                    <Tab>
                      <Button size="xs lg:md" variant="ghost">
                        Edit
                      </Button>
                    </Tab>
                    {/* <Tab>
                      <Button size="xs lg:md" variant="ghost">
                        Read
                      </Button>
                    </Tab>
                    <Tab>
                      <Button size="xs lg:md" variant="ghost">
                        Automations (0)
                      </Button>
                    </Tab>
                    <Tab>
                      <Button size="xs lg:md" variant="ghost">
                        Tools
                      </Button>
                    </Tab> */}
                  </TabList>

                  <TabPanels>
                    <TabPanel className="flex flex-row space-x-4 mb-20">
                      <div className="w-full lg:w-1/2 pb-20">
                        {' '}
                        <div className="grid grid-cols-1 gap-4">
                          <div className=" flex flex-row justify-between border rounded-lg ">
                            <div className="flex justify-between items-center p-4 border-r-2">
                              <h3 className="text-lg font-semibold">
                                Triggers
                              </h3>
                            </div>
                            <div className="flex flex-col w-full">
                              <div
                                onClick={() => setTrigger('kickOff')}
                                className="h-full flex justify-between items-center border-b-2 border-l-2 p-4"
                              >
                                <span className="text-gray-700 w-full">
                                  Kick Off Form
                                </span>
                                <ChevronRightIcon className="w-4 h-4 text-gray-400" />
                              </div>
                              {/* <div
                                onClick={() => setTrigger('other')}
                                className="h-1/2 flex justify-between items-center border-l-2 p-4"
                              >
                                <span className="text-gray-700 w-full">
                                  Other Options
                                </span>
                                <ChevronRightIcon className="w-4 h-4 text-gray-400" />
                              </div> */}
                            </div>
                          </div>
                          <div className="border rounded-lg p-4 space-y-2">
                            <TemplateStep
                              _id={id}
                              setTrigger={setTrigger}
                              setStepData={setStepData}
                              steps={steps}
                              setSteps={setSteps}
                              milestones={milestones}
                              setMilestones={setMilestones}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="hidden lg:block lg:w-1/2 ">
                        {trigger == 'kickOff' ? (
                          <div className="space-y-4 ">
                            <div className="flex flex-row items-center space-x-4">
                              <h1 className="p-2 rounded-lg bg-blue-500 text-white">
                                Trigger
                              </h1>
                              <p>Kick Off Form</p>
                              <div className="flex flex-1 justify-end">
                              
                                <CloseButton
                                  onClick={() => {
                                    setTrigger(null)
                                    setStep(null)
                                  }}
                                  className=" mx-2"
                                />
                              </div>
                            </div>
                            <div>
                              <Accordion allowToggle>
                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Forms
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel pb={4}>
                                  {Cookies.get('role') == "member" ? <FormRender
                                    formData={data?.kickOffForm?.forms}
                                    formResponses={data}
                                    setFormResponses={setData}
                                  /> : <FormBuilder
                                      formData={data?.kickOffForm?.forms}
                                      data={data}
                                      setData={setData}
                                    />}
                                  </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Description
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    className="space-y-4 w-full"
                                    pb={4}
                                  >
                                     {Cookies.get('role') == "member" ? <div dangerouslySetInnerHTML={{ __html: data?.kickOffForm?.desc }} />: <RichTextEditor
                                      value={data?.kickOffForm?.desc}
                                      onChange={(html) => {
                                        setData({
                                          ...data,
                                          kickOffForm: {
                                            ...data.kickOffForm,
                                            desc: html,
                                          },
                                        })
                                        dispatch(
                                          updateTemplateWithoutPull({
                                            ...data,
                                            kickOffForm: {
                                              ...data.kickOffForm,
                                              desc: html,
                                            },
                                          }),
                                        )
                                      }
                                    }
                                    /> }
                                  </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Auto-name Process?
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    className="space-y-4 w-full"
                                    pb={4}
                                  >
                                    <div
                                      onClick={(e) => {
                                        if(Cookies.get('role') != 'member'){
                                        setData({
                                          ...data,
                                          kickOffForm: {
                                            ...data.kickOffForm,
                                            autoNameProcess: e.target.checked,
                                          },
                                        })
                                        dispatch(
                                          updateTemplateWithoutPull({
                                            ...data,
                                            kickOffForm: {
                                              ...data.kickOffForm,
                                              autoNameProcess: e.target.checked,
                                            },
                                          }),
                                        )
                                      }
                                      }}
                                      className="flex justify-between"
                                    >
                                      <FormLabel htmlFor="2" mb="0">
                                        Automatically name processes?
                                      </FormLabel>
                                      <Switch
                                        id="2"
                                        isChecked={
                                          data?.kickOffForm?.autoNameProcess
                                        }
                                      />
                                    </div>
                                    <Input
                                      type="text"
                                      hidden={!data?.kickOffForm?.autoNameProcess}
                                      placeholder="Type a default name for template. (You might assign new one while instantiating)"
                                      value={data?.kickOffForm?.name}
                                      onChange={(e) => {
                                        if(Cookies.get('role') != 'member'){
                                        setData({
                                          ...data,
                                          kickOffForm: {
                                            ...data.kickOffForm,
                                            name: e.target.value,
                                          },
                                        })
                                        dispatch(
                                          updateTemplateWithoutPull({
                                            ...data,
                                            kickOffForm: {
                                              ...data.kickOffForm,
                                              name: e.target.value,
                                            },
                                          }),
                                        )
                                      }
                                      }}
                                    />
                                  </AccordionPanel>
                                </AccordionItem>
                              </Accordion>
                            </div>
                          </div>
                        ) : null}

                        {trigger == 'other' ? (
                          <div className="space-y-4 ">
                            <div className="flex flex-row items-center space-x-4">
                              <h1 className="p-2 rounded-lg bg-blue-500 text-white">
                                Trigger
                              </h1>
                              <p>Other Options</p>
                              <div className="flex flex-1 justify-end">
                                <CloseButton
                                  onClick={() => {
                                    setTrigger(null)
                                    setStep(null)
                                  }}
                                  className=" mx-2"
                                />
                              </div>
                            </div>

                            <div>
                              <Accordion allowToggle>
                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Via Middleware
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel pb={4}>
                                    Middleware connectors move data from one app
                                    to another app.
                                    <div className="flex flex-row space-x-4 mt-4">
                                      <Button>Power Automate</Button>
                                      <Button>Zapier</Button>
                                    </div>
                                  </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Via Recurring Schedule
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    className="space-y-4 w-full"
                                    pb={4}
                                  >
                                    Coming soon!
                                  </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Via Inbound Email
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    className="space-y-4 w-full"
                                    pb={4}
                                  >
                                    <div className="bg-white p-6 rounded-lg mx-auto">
                                      <div className="flex justify-between items-center mb-4">
                                        <h2 className="text-lg font-semibold text-gray-900">
                                          This email address launches a process
                                          for this specific template -{' '}
                                          <a
                                            className="text-blue-600 underline"
                                            href="#"
                                          >
                                            LEARN MORE HERE
                                          </a>
                                        </h2>
                                        <Button className="bg-blue-600 text-white">
                                          COPY LINK
                                        </Button>
                                      </div>
                                      <div className="space-y-4">
                                        <div className="flex items-center justify-between">
                                          <span className="text-gray-700">
                                            bot+62ab8aad9726b6dc4d07ecad33c80249-launch@tallyfy.com
                                          </span>
                                          <ClipboardIcon className="w-4 h-4 text-blue-600" />
                                        </div>
                                        <div className="border-t border-gray-200 pt-4">
                                          <div className="flex flex-col space-y-1">
                                            <span className="font-medium text-gray-700">
                                              From:
                                            </span>
                                            <span className="text-gray-500">
                                              rasmus@infospray.dk (Any member)
                                            </span>
                                          </div>
                                        </div>
                                        <div className="border-t border-gray-200 pt-4">
                                          <div className="flex flex-col space-y-1">
                                            <span className="font-medium text-gray-700">
                                              Subject:
                                            </span>
                                            <span className="text-gray-500">
                                              {data?.name} (Process name)
                                            </span>
                                          </div>
                                        </div>
                                        <div className="border-t border-gray-200 pt-4">
                                          <div className="flex flex-col space-y-1">
                                            <span className="font-medium text-gray-700">
                                              Body:
                                            </span>
                                            <span className="text-gray-500">
                                              The body can be captured in a
                                              special kick-off field named
                                              below:
                                            </span>
                                          </div>
                                        </div>
                                        <div className="border-t border-gray-200 pt-4">
                                          <div className="flex flex-col space-y-1">
                                            <span className="font-medium text-gray-700">
                                              Attachments:
                                            </span>
                                            <span className="text-gray-500">
                                              Any attachments can be stored in a
                                              special kick-off field named
                                              below:
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Via Inbound Webhook
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel pb={4}>
                                    Coming soon! Pretty soon - you'll be able to
                                    launch a process by shooting a pre-defined
                                    webhook to a pre-defined URL, securely.
                                  </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Via Magic Link
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    className="space-y-4 w-full"
                                    pb={4}
                                  >
                                    <div
                                      key="1"
                                      className="bg-white p-6 rounded-lg mx-auto"
                                    >
                                      <p className="text-sm leading-6">
                                        A magic link is just a link which
                                        performs an action and passes through
                                        data from other systems.{' '}
                                        <a
                                          className="text-blue-600 hover:underline"
                                          href="#"
                                        >
                                          LEARN MORE HERE
                                        </a>
                                      </p>
                                      <div className="mt-4">
                                        <p className="font-semibold text-lg">
                                          MAGIC LINK
                                        </p>
                                        <div className="flex justify-between items-center mt-2">
                                          <a
                                            className="text-blue-600 hover:underline break-all"
                                            href="#"
                                          >
                                            https://go.tallyfy.com/1c6882578e022862f2bae804d95bb931/process/6...
                                          </a>
                                          <Button className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700">
                                            COPY LINK
                                          </Button>
                                        </div>
                                      </div>
                                      <div className="mt-6">
                                        <p className="font-semibold text-lg">
                                          UNDERSTANDING YOUR MAGIC LINK
                                        </p>
                                        <div className="mt-2">
                                          <a
                                            className="text-blue-600 hover:underline break-all"
                                            href="#"
                                          >
                                            https://go.tallyfy.com/
                                            <span className="bg-blue-100 ">
                                              1c6882578e022862f2bae83d95bb931
                                            </span>
                                            /process/
                                            <span className="bg-green-100 ">
                                              62ab8aad9476b6dc4d07ecad3c380249
                                            </span>
                                            /create?
                                            <span className="bg-red-100 ">
                                              default_run_name=Template%20Test%20Name
                                            </span>
                                            &
                                            <span className="bg-yellow-100 ">
                                              ko_fields=%5B%5D
                                            </span>
                                            &launchprocess=true
                                          </a>
                                          <ol className="list-decimal list-inside mt-4 space-y-1">
                                            <li className="flex items-center bg-blue-100 ">
                                              <Badge
                                                className="mr-2"
                                                variant="secondary"
                                              >
                                                1.
                                              </Badge>
                                              Organization ID
                                            </li>
                                            <li className="flex items-center bg-green-100 ">
                                              <Badge
                                                className="mr-2"
                                                variant="secondary"
                                              >
                                                2.
                                              </Badge>
                                              Template ID
                                            </li>
                                            <li className="flex items-center bg-red-100">
                                              <Badge
                                                className="mr-2"
                                                variant="secondary"
                                              >
                                                3.
                                              </Badge>
                                              Name of the process
                                            </li>
                                            <li className="flex items-center bg-yellow-100">
                                              <Badge
                                                className="mr-2"
                                                variant="secondary"
                                              >
                                                4.
                                              </Badge>
                                              Kick Off Fields - required fields
                                              only
                                            </li>
                                          </ol>
                                        </div>
                                      </div>
                                    </div>
                                  </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Via API
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    className="space-y-4 w-full"
                                    pb={4}
                                  >
                                    Infospray BPM is an API-first platform. LEARN MORE
                                    HERE
                                  </AccordionPanel>
                                </AccordionItem>
                              </Accordion>
                            </div>
                          </div>
                        ) : null}

                        {trigger == 'stepView' ? (
                          <div className="space-y-4 ">
                            <div className="flex flex-row items-center space-x-4">
                              <h1 className="p-2 rounded-lg bg-blue-500 text-white">
                                {stepData?.name}
                              </h1>
                              <div className="flex flex-1 justify-end">
                              <Button onClick={()=>{dispatch(deleteStep(stepData)); setStep(null); setTrigger(null); setStepData({})}} colorScheme="red">Delete Step</Button>
                                <CloseButton
                                  onClick={() => {
                                    setTrigger(null)
                                    setStep(null)
                                  }}
                                  className=" mx-2"
                                />
                              </div>
                            </div>

                            <div>
                              <Accordion allowToggle>
                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Milestones
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel pb={4}>
                                    <Select
                                      id="status-toggle"
                                      placeholder="Milestone"
                                      className="w-full"
                                      onChange={(e) => {
                                        setStepData({
                                          ...stepData,
                                          milestone: e.target.value,
                                        })
                                      }}
                                      value={stepData?.milestone}
                                      disabled={Cookies.get('role') == "member"}
                                    >
                                      <option value="">None</option>
                                      {milestones.map((el, i) => (
                                        <option value={el}>{el}</option>
                                      ))}
                                    </Select>
                                    <div className="flex flex-row space-x-4 mt-4">
                                      <Input
                                        onChange={(e) =>
                                          setMilestoneName(e.target.value)
                                        }
                                        placeholder="New milestone name"
                                        value={milestoneName}
                                        hidden={Cookies.get('role') == "member"}
                                      />
                                      <Button
                                      hidden={Cookies.get('role') == "member"}
                                        onClick={() => {
                                          // Find the step to be updated
                                          const stepToUpdate = steps.find(
                                            (step) => step._id === stepData._id,
                                          )
                                          // Create a new step object with the updated milestone
                                          const updatedStep = {
                                            ...stepToUpdate,
                                            milestone: milestoneName,
                                          }

                                          // Create a new array with the updated step
                                          const updatedSteps = steps.map(
                                            (step) =>
                                              step._id === stepData._id
                                                ? updatedStep
                                                : step,
                                          )

                                          // Update the state with the new array
                                          setSteps(updatedSteps)
                                          dispatch(updateSteps(updatedSteps))
                                          setMilestoneName('')
                                        }}
                                      >
                                        Add Milestone
                                      </Button>
                                    </div>
                                  </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Description
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    className="space-y-4 w-full"
                                    pb={4}
                                  >
                                     {Cookies.get('role') == "member" ? <div dangerouslySetInnerHTML={{ __html: stepData?.desc  }} />: <RichTextEditor
                                    
                                      value={stepData?.desc || ""}
                                      onChange={(html) =>
                                        setStepData({
                                          ...stepData,
                                          desc: html
                                        })
                                      }
                                    />}
                                  </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Assign
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    className="space-y-4 w-full"
                                    pb={4}
                                  >
                                    Who does this step every time?
                                    <div className="my-4">
                                      <Button
                                      disabled={Cookies.get('role') == "member"}
                                        onClick={() =>
                                          setStepData({
                                            ...stepData,
                                            assignedTo: {
                                              ...stepData?.assignedTo,
                                              type: 'fixed',
                                            },
                                          })
                                        }
                                        colorScheme={
                                          stepData?.assignedTo?.type == 'fixed'
                                            ? 'blue'
                                            : 'gray'
                                        }
                                        className="p-4 rounded-r-none border"
                                      >
                                        Fixed
                                      </Button>
                                      {/* <Button
                                        onClick={() =>
                                          setStepData({
                                            ...stepData,
                                            assignedTo: {
                                              ...stepData?.assignedTo,
                                              type: 'dynamic',
                                            },
                                          })
                                        }
                                        colorScheme={
                                          stepData?.assignedTo?.type ==
                                          'dynamic'
                                            ? 'blue'
                                            : 'gray'
                                        }
                                        className="p-4 rounded-r-none rounded-l-none border-y"
                                      >
                                        Dynamic
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          setStepData({
                                            ...stepData,
                                            assignedTo: {
                                              ...stepData?.assignedTo,
                                              type: 'guest',
                                            },
                                          })
                                        }
                                        colorScheme={
                                          stepData?.assignedTo?.type == 'guest'
                                            ? 'blue'
                                            : 'gray'
                                        }
                                        className="p-4 rounded-l-none border"
                                      >
                                        Guest
                                      </Button> */}
                                    </div>
                                    <div
                                    
                                      hidden={
                                        stepData?.assignedTo?.type !== 'fixed'
                                      }
                                    >
                                      Add fixed people or groups that will
                                      always be assigned
                                      <Select
                                        className="my-2"
                                        placeholder="Type member name, email."
                                        disabled={Cookies.get('role') == "member"}
                                        onChange={(e) => {
                                          setStepData({
                                            ...stepData,
                                            assignedTo: {
                                              ...stepData.assignedTo,
                                              value: [
                                                ...stepData?.assignedTo?.value,
                                                e.target.value,
                                              ],
                                            },
                                          })
                                        }}
                                      >
                                        {state?.members?.data?.members?.map(
                                          (el, i) => (
                                            <option
                                              key={i}
                                              value={el.email}
                                              disabled={Cookies.get('role') == "member"}
                                              onClick={(e) =>
                                                setStepData({
                                                  ...stepData,
                                                  assignedTo: {
                                                    ...stepData.assignedTo,
                                                    value: [
                                                      ...stepData?.assignedTo
                                                        ?.value,
                                                      el.email,
                                                    ],
                                                  },
                                                })
                                              }
                                            >
                                              {el.fName + ' ' + el.lName}
                                            </option>
                                          ),
                                        )}
                                      </Select>
                                      {stepData?.assignedTo?.value.map(
                                        (el, i) => (
                                          <Tag
                                            size={'md'}
                                            key={i}
                                            variant="solid"
                                            colorScheme="teal"
                                            className="w-fit"
                                          >
                                            {el}
                                            <button
                                            disabled={Cookies.get('role') == "member"}
                                              onClick={() => {
                                                if(Cookies.get('role') != 'member'){
                                                const stepDataUpdated =
                                                  stepData?.assignedTo?.value
                                                stepDataUpdated.splice(i, 1)
                                                setData({
                                                  ...stepData,
                                                  assignedTo: {
                                                    ...stepData.assignedTo,
                                                    value: stepDataUpdated,
                                                  },
                                                })
                                              }
                                              }}
                                            >
                                              <TagCloseButton />
                                            </button>
                                          </Tag>
                                        ),
                                      )}
                                    </div>
                                    {/* <div
                                      hidden={
                                        stepData?.assignedTo?.type !== 'dynamic'
                                      }
                                    >
                                      Choose or create a job title that
                                      completes this step
                                      <MultiSelect
                                        options={options}
                                        value={value}
                                        label="Search..."
                                        onChange={setValue}
                                      />
                                    </div>
                                    <div
                                      hidden={
                                        stepData?.assignedTo?.type !== 'guest'
                                      }
                                    >
                                      You've set this step to be assigned to a
                                      guest. Is there other people that should
                                      be assigned?
                                      <Input
                                        placeholder="ADD ADDITIONAL ASSIGNEES"
                                        value={stepData?.assignedTo?.value?.toString()}
                                        onChange={(e) =>
                                          setStepData({
                                            ...stepData,
                                            assignedTo: {
                                              ...stepData?.assignedTo,
                                              value: e.target.value?.split(','),
                                            },
                                          })
                                        }
                                      />
                                    </div> */}
                                    {/* <div
                                      onClick={(e) =>
                                        setStepData({
                                          ...stepData,
                                          enforceMaxAssignees: e.target.checked,
                                        })
                                      }
                                      disabled={Cookies.get('role') == "member"}
                                      className="flex justify-between"
                                    >
                                      <FormLabel htmlFor="1" mb="0">
                                        Enforce a maximum number of assignees?
                                      </FormLabel>
                                      <Switch
                                        id="1"
                                        disabled={Cookies.get('role') == "member"}
                                        isChecked={
                                          stepData?.enforceMaxAssignees
                                        }
                                      />
                                    </div> */}
                                  </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Type
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    className="space-y-4 w-full"
                                    pb={4}
                                  >
                                    <Select
                                      value={stepData?.type}
                                      disabled={Cookies.get('role') == "member"}
                                      onChange={(e) =>
                                        setStepData({
                                          ...stepData,
                                          type: e.target.value,
                                        })
                                      }
                                      placeholder="Tasks"
                                      className="w-full"
                                    >
                                      <option value="task">
                                        {' '}
                                        <CheckCircleIcon className="w-4 h-4" />{' '}
                                        Task
                                      </option>
                                      <option value="approve/reject">
                                        {' '}
                                        <AdjustmentsHorizontalIcon className="w-4 h-4" />{' '}
                                        Approve/Reject
                                      </option>
                                      <option value="expiring">
                                        {' '}
                                        <ClockIcon className="w-4 h-4" />{' '}
                                        Expiring
                                      </option>
                                      <option value="emailDraft">
                                        {' '}
                                        <EnvelopeIcon className="w-4 h-4" />{' '}
                                        Email Draft
                                      </option>
                                      <option value="emailAutoSend">
                                        {' '}
                                        <PaperAirplaneIcon className="w-4 h-4" />{' '}
                                        Email Auto-Send
                                      </option>
                                    </Select>
                                  </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Form Fields
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel pb={4}>
                                  {Cookies.get('role') == "member" ? <FormRender
                                    formData={stepData.forms}
                                    formResponses={stepData}
                                    setFormResponses={setStepData}
                                  /> :  <FormBuilder
                                      formData={stepData.forms}
                                      data={stepData}
                                      setData={setStepData}
                                    />}
                                  </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Timings
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    className="space-y-4 w-full"
                                    pb={4}
                                  >
                                    <div>
                                      Deadline
                                      <InputGroup className="space-x-2">
                                        <Input
                                          type="number"
                                          value={
                                            stepData?.deadline?.number || 5
                                          }
                                          disabled={Cookies.get('role') == "member"}
                                          onChange={(e) =>
                                            setStepData({
                                              ...stepData,
                                              deadline: {
                                                ...stepData.deadline,
                                                number: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                        <Select
                                          id="status-toggle"
                                          placeholder="time"
                                          className="w-full"
                                          disabled={Cookies.get('role') == "member"}
                                          value={
                                            stepData?.deadline?.unit || 'days'
                                          }
                                          onChange={(e) =>
                                            setStepData({
                                              ...stepData,
                                              deadline: {
                                                ...stepData.deadline,
                                                unit: e.target.value,
                                              },
                                            })
                                          }
                                        >
                                          <option value="min">minutes</option>
                                          <option value="hrs">hours</option>
                                          <option value="days">days</option>
                                          <option value="weeks">weeks</option>
                                          <option value="months">months</option>
                                        </Select>
                                        <Select
                                          id="status-toggle"
                                          placeholder="after"
                                          className="w-full"
                                          disabled={Cookies.get('role') == "member"}
                                        ></Select>
                                        <Select
                                          id="status-toggle"
                                          placeholder="launch"
                                          className="w-full"
                                          disabled={Cookies.get('role') == "member"}
                                          value={
                                            stepData?.deadline?.launch ||
                                            'launch'
                                          }
                                          onChange={(e) =>
                                            setStepData({
                                              ...stepData,
                                              deadline: {
                                                ...stepData.deadline,
                                                launch: e.target.value,
                                              },
                                            })
                                          }
                                        >
                                          {milestones.map((el, i) => (
                                            <option value={el}>{el}</option>
                                          ))}
                                        </Select>
                                      </InputGroup>
                                      <div
                                      disabled={Cookies.get('role') == "member"}
                                        onClick={(e) =>
                                          setStepData({
                                            ...stepData,
                                            startOnSpecificTime:
                                              e.target.checked,
                                          })
                                        }
                                        className="flex  justify-between my-4"
                                      >
                                        <FormLabel htmlFor="2" mb="0">
                                          Start on specific time ?
                                        </FormLabel>
                                        <Switch
                                          id="2"
                                          disabled={Cookies.get('role') == "member"}
                                          isChecked={
                                            stepData?.startOnSpecificTime ||
                                            false
                                          }
                                        />
                                      </div>
                                      <InputGroup
                                        hidden={
                                          !stepData?.startOnSpecificTime ||
                                          false
                                        }
                                        className="space-x-2"
                                        disabled={Cookies.get('role') == "member"}
                                      >
                                        Start
                                        <Input
                                          type="number"
                                          value={stepData?.start?.number || 5}
                                          disabled={Cookies.get('role') == "member"}
                                          onChange={(e) =>
                                            setStepData({
                                              ...stepData,
                                              start: {
                                                ...stepData.start,
                                                number: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                        <Select
                                          id="status-toggle"
                                          placeholder="time"
                                          className="w-full"
                                          disabled={Cookies.get('role') == "member"}
                                          value={
                                            stepData?.start?.unit || 'days'
                                          }
                                          onChange={(e) =>
                                            setStepData({
                                              ...stepData,
                                              start: {
                                                ...stepData.start,
                                                unit: e.target.value,
                                              },
                                            })
                                          }
                                        >
                                          <option value="min">minutes</option>
                                          <option value="hrs">hours</option>
                                          <option value="days">days</option>
                                          <option value="weeks">weeks</option>
                                          <option value="months">months</option>
                                        </Select>
                                        <Select
                                          id="status-toggle"
                                          placeholder="after"
                                          className="w-full"
                                          disabled={Cookies.get('role') == "member"}
                                        ></Select>
                                        <Select
                                          id="status-toggle"
                                          placeholder="launch"
                                          className="w-full"
                                          disabled={Cookies.get('role') == "member"}
                                          value={
                                            stepData?.start?.launch || 'launch'
                                          }
                                          onChange={(e) =>
                                            setStepData({
                                              ...stepData,
                                              start: {
                                                ...stepData.start,
                                                launch: e.target.value,
                                              },
                                            })
                                          }
                                        >
                                          {milestones.map((el, i) => (
                                            <option value={el}>{el}</option>
                                          ))}
                                        </Select>
                                      </InputGroup>
                                    </div>
                                  </AccordionPanel>
                                </AccordionItem>
{/* 
                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Automations
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    className="space-y-4 w-full"
                                    pb={4}
                                  >
                                    This step is not involved in any
                                    automations. Review and create automations
                                    here
                                  </AccordionPanel>
                                </AccordionItem> */}

                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Advanced
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    className="space-y-4 w-full"
                                    pb={4}
                                  >
                                    {/* <div
                                    disabled={Cookies.get('role') == "member"}
                                      onClick={(e) =>
                                        setStepData({
                                          ...stepData,
                                          launchAnotherProcess:
                                            e.target.checked,
                                        })
                                      }
                                      className="flex justify-between"
                                    >
                                      <FormLabel htmlFor="1" mb="0">
                                        Launch another process when this task is
                                        complete?
                                      </FormLabel>
                                      <Switch
                                      disabled={Cookies.get('role') == "member"}
                                        isChecked={
                                          stepData?.launchAnotherProcess ||
                                          false
                                        }
                                        id="1"
                                      />
                                    </div>

                                    <div
                                    disabled={Cookies.get('role') == "member"}
                                      onClick={(e) =>
                                        setStepData({
                                          ...stepData,
                                          allAssigneesMustComplete:
                                            e.target.checked,
                                        })
                                      }
                                      className="flex justify-between"
                                    >
                                      <FormLabel htmlFor="1" mb="0">
                                        All assignees must complete this task
                                      </FormLabel>
                                      <Switch
                                      disabled={Cookies.get('role') == "member"}
                                        isChecked={
                                          stepData?.allAssigneesMustComplete ||
                                          false
                                        }
                                        id="1"
                                      />
                                    </div>

                                    <div
                                    disabled={Cookies.get('role') == "member"}
                                      onClick={(e) =>
                                        setStepData({
                                          ...stepData,
                                          mandatoryTask: e.target.checked,
                                        })
                                      }
                                      className="flex justify-between"
                                    >
                                      <FormLabel htmlFor="1" mb="0">
                                        Task is mandatory and cannot be removed
                                        at launch
                                      </FormLabel>
                                      <Switch
                                      disabled={Cookies.get('role') == "member"}
                                        isChecked={
                                          stepData?.mandatoryTask || false
                                        }
                                        id="1"
                                      />
                                    </div>

                                    <div
                                    disabled={Cookies.get('role') == "member"}
                                      onClick={(e) =>
                                        setStepData({
                                          ...stepData,
                                          onlyAssignedMembersCanEdit:
                                            e.target.checked,
                                        })
                                      }
                                      className="flex justify-between"
                                    >
                                      <FormLabel htmlFor="1" mb="0">
                                        Only assigned members can complete or
                                        edit this task
                                      </FormLabel>
                                      <Switch
                                      disabled={Cookies.get('role') == "member"}
                                        isChecked={
                                          stepData?.onlyAssignedMembersCanEdit ||
                                          false
                                        }
                                        id="1"
                                      />
                                    </div> */}

                                    {/* <div
                                      onClick={(e) =>
                                        setStepData({
                                          ...stepData,
                                          preventGuestComments:
                                            e.target.checked,
                                        })
                                      }
                                      className="flex justify-between"
                                    >
                                      <FormLabel htmlFor="1" mb="0">
                                        Prevent guests from making or seeing any
                                        comments?
                                      </FormLabel>
                                      <Switch
                                        isChecked={
                                          stepData?.preventGuestComments ||
                                          false
                                        }
                                        id="1"
                                      />
                                    </div> */}

                                    <div className="flex flex-col justify-between">
                                      <Input
                                        type="text"
                                        placeholder="Tags"
                                        disabled={Cookies.get('role') == "member"}
                                        onChange={(e) =>
                                          setTagSearch(e.target.value)
                                        }
                                        value={tagSearch}
                                        onKeyDown={handleKeyDown}
                                      />
                                      <div className="flex flex-row space-x-2 mt-2">
                                        {stepData?.tags?.map((title, i) => (
                                          <Tag
                                            size={'md'}
                                            key={i}
                                            variant="solid"
                                            colorScheme="teal"
                                            className="w-fit"
                                          >
                                            {title}
                                            <TagCloseButton
                                              onClick={() => removeTag(i)}
                                            />
                                          </Tag>
                                        ))}
                                      </div>
                                    </div>
                                  </AccordionPanel>
                                </AccordionItem>
{/* 
                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Outbound Webhooks
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    className="space-y-4 w-full"
                                    pb={4}
                                  >
                                    <div>
                                      Emit a webhook of the entire process in
                                      JSON.
                                    </div>

                                    <div className="flex justify-between">
                                      <FormLabel htmlFor="1" mb="0">
                                        Emit a webhook when this task is
                                        completed?
                                      </FormLabel>
                                      <Switch id="1" />
                                    </div>
                                    <div>
                                      <Input placeholder="Target URL for webhook *" />
                                    </div>

                                    <div>
                                      <h1 className="font-bold">
                                        Unique Step Alias
                                      </h1>
                                      <p>
                                        This alias uniquely identifies this
                                        step.
                                      </p>
                                      <InputGroup>
                                        <Input value="step-1-8023" />
                                        <InputRightAddon>
                                          <Button>Copy</Button>
                                        </InputRightAddon>
                                      </InputGroup>
                                    </div>
                                  </AccordionPanel>
                                </AccordionItem>

                                <AccordionItem>
                                  <h2>
                                    <AccordionButton>
                                      <Text className="flex text-lg py-2 px-2">
                                        Incoming Webhooks
                                      </Text>
                                      <AccordionIcon />
                                    </AccordionButton>
                                  </h2>
                                  <AccordionPanel
                                    className="space-y-4 w-full"
                                    pb={4}
                                  >
                                    <div>
                                      Our REST API handles inbound actions from
                                      external systems.
                                    </div>

                                    <div className="space-y-4">
                                      <InputGroup className="items-center">
                                        <InputLeftAddon className="w-[7rem]">
                                          Process ID
                                        </InputLeftAddon>
                                        <div className="border p-2 rounded-r-lg">
                                          Generated when you launch a process
                                        </div>
                                      </InputGroup>

                                      <InputGroup className="items-center">
                                        <InputLeftAddon className="w-[7rem]">
                                          Step Alias
                                        </InputLeftAddon>
                                        <Input value="step-1-8023" />
                                        <InputRightAddon>
                                          <Button>Copy</Button>
                                        </InputRightAddon>
                                      </InputGroup>
                                    </div>
                                  </AccordionPanel>
                                </AccordionItem> */}
                              </Accordion>

                              <div className="bg-gray-100 rounded-lg p-4 mt-28">
                                <Heading
                                  className="flex flex-row items-center"
                                  size={'md'}
                                >
                                  <ChatBubbleBottomCenterIcon className="w-4 h-4 mr-4" />{' '}
                                  Comments{' '}
                                </Heading>
                                <div className="py-6 max-h-[20rem] overflow-y-scroll">
                                  {/* Comments */}
                                  {stepData?.comments?.map((el, i) => (
                                    <div className="p-2 border rounded-lg my-2 bg-gray-50 ">
                                      <Avatar size="sm" />
                                      <div className="flex-col flex mx-auto space-y-4">
                                        <div className="flex flex-row  justify-left space-x-4 items-center">
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: el?.text,
                                            }}
                                          ></p>
                                        </div>
                                        <div className="text-sm flex flex-row space-between text-gray-400">
                                          {el.email}{' '}
                                          {getFormattedDate(el?.createdAt)}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div className="my-4 border bg-white p-4 space-y-2">
                                  <RichTextEditor value={html} onChange={(html) => setHtml(html)} />
                                  <Button
                                    onClick={(e) => handleAddComment(e, html)}
                                  >
                                    Add Comment
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                      <Modal
                        isOpen={
                          isSmallScreen &&
                          (trigger == 'kickOff' ||
                            trigger == 'other' ||
                            (trigger == null && step))
                        }
                        onClose={() => {
                          setTrigger(null)
                          setStep(null)
                        }}
                      >
                        <ModalOverlay className="block lg:hidden" />
                        <ModalContent className="block lg:hidden">
                          <ModalBody>
                            <div className="py-4">
                              {trigger == 'kickOff' ? (
                                <div className="space-y-4 ">
                                  <div className="flex flex-row items-center space-x-4">
                                    <h1 className="p-2 rounded-lg bg-blue-500 text-white">
                                      Trigger
                                    </h1>
                                    <p>Kick Off Form</p>
                                    <div className="flex flex-1 justify-end">
                                      <CloseButton
                                        onClick={() => {
                                          setTrigger(null)
                                          setStep(null)
                                        }}
                                        className=" mx-2"
                                      />
                                    </div>
                                  </div>
                                  <div>
                                    <Accordion allowToggle>
                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Forms
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                        {Cookies.get('role') == "member" ? <FormRender
                                    formData={data?.kickOffForm?.forms}
                                    formResponses={data}
                                    setFormResponses={setData}
                                  /> : <FormBuilder
                                            formData={data?.kickOffForm?.forms}
                                            data={data}
                                            setData={setData}
                                          />}
                                        </AccordionPanel>
                                      </AccordionItem>

                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Description
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel
                                          className="space-y-4 w-full"
                                          pb={4}
                                        >
                                          {Cookies.get('role') == "member" ? <div dangerouslySetInnerHTML={{ __html: data?.kickOffForm?.desc }} />: <RichTextEditor
                                            value={data?.kickOffForm?.desc}
                                            onChange={(html) => {
                                              setData({
                                                ...data,
                                                kickOffForm: {
                                                  ...data.kickOffForm,
                                                  desc: html
                                                },
                                              })
                                              dispatch(
                                                updateTemplateWithoutPull({
                                                  ...data,
                                                  kickOffForm: {
                                                    ...data.kickOffForm,
                                                    desc: html,
                                                  },
                                                }),
                                              )
                                            }}
                                          />}
                                        </AccordionPanel>
                                      </AccordionItem>

                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Auto-name Process?
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel
                                          className="space-y-4 w-full"
                                          pb={4}
                                        >
                                          <div
                                            onClick={(e) => {
                                              if(Cookies.get('role') != 'member'){
                                              setData({
                                                ...data,
                                                kickOffForm: {
                                                  ...data.kickOffForm,
                                                  autoNameProcess:
                                                    e.target.checked,
                                                },
                                              })
                                              dispatch(
                                                updateTemplateWithoutPull({
                                                  ...data,
                                                  kickOffForm: {
                                                    ...data.kickOffForm,
                                                    autoNameProcess:
                                                      e.target.checked,
                                                  },
                                                }),
                                              )
                                            }
                                            }}
                                            className="flex justify-between"
                                          >
                                            <FormLabel htmlFor="2" mb="0">
                                              Automatically name processes?
                                            </FormLabel>
                                            <Switch
                                              id="2"
                                              isChecked={
                                                data?.kickOffForm?.autoNameProcess
                                              }
                                            />
                                          </div>
                                          <Input
                                            type="text"
                                            hidden={
                                              !data?.kickOffForm?.autoNameProcess
                                            }
                                            placeholder="Type a default name for template. (You might assign new one while instantiating)"
                                            value={data?.kickOffForm?.name}
                                            onChange={(e) => {
                                              if(Cookies.get('role') != 'member'){
                                              setData({
                                                ...data,
                                                kickOffForm: {
                                                  ...data.kickOffForm,
                                                  name: e.target.value,
                                                },
                                              })
                                              dispatch(
                                                updateTemplate({
                                                  ...data,
                                                  kickOffForm: {
                                                    ...data.kickOffForm,
                                                    name: e.target.value,
                                                  },
                                                }),
                                              )
                                            }
                                            }}
                                          />
                                        </AccordionPanel>
                                      </AccordionItem>
                                    </Accordion>
                                  </div>
                                </div>
                              ) : null}

                              {trigger == 'other' ? (
                                <div className="space-y-4 ">
                                  <div className="flex flex-row items-center space-x-4">
                                    <h1 className="p-2 rounded-lg bg-blue-500 text-white">
                                      Trigger
                                    </h1>
                                    <p>Other Options</p>
                                    <div className="flex flex-1 justify-end">
                                      <CloseButton
                                        onClick={() => {
                                          setTrigger(null)
                                          setStep(null)
                                        }}
                                        className=" mx-2"
                                      />
                                    </div>
                                  </div>

                                  <div>
                                    <Accordion allowToggle>
                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Via Middleware
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                          Middleware connectors move data from
                                          one app to another app.
                                          <div className="flex flex-row space-x-4 mt-4">
                                            <Button>Power Automate</Button>
                                            <Button>Zapier</Button>
                                          </div>
                                        </AccordionPanel>
                                      </AccordionItem>

                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Via Recurring Schedule
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel
                                          className="space-y-4 w-full"
                                          pb={4}
                                        >
                                          Coming soon!
                                        </AccordionPanel>
                                      </AccordionItem>

                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Via Inbound Email
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel
                                          className="space-y-4 w-full"
                                          pb={4}
                                        >
                                          <div className="bg-white p-6 rounded-lg mx-auto">
                                            <div className="flex justify-between items-center mb-4">
                                              <h2 className="text-lg font-semibold text-gray-900">
                                                This email address launches a
                                                process for this specific
                                                template -{' '}
                                                <a
                                                  className="text-blue-600 underline"
                                                  href="#"
                                                >
                                                  LEARN MORE HERE
                                                </a>
                                              </h2>
                                              <Button className="bg-blue-600 text-white">
                                                COPY LINK
                                              </Button>
                                            </div>
                                            <div className="space-y-4">
                                              <div className="flex items-center justify-between">
                                                <span className="text-gray-700">
                                                  bot+62ab8aad9726b6dc4d07ecad33c80249-launch@tallyfy.com
                                                </span>
                                                <ClipboardIcon className="w-4 h-4 text-blue-600" />
                                              </div>
                                              <div className="border-t border-gray-200 pt-4">
                                                <div className="flex flex-col space-y-1">
                                                  <span className="font-medium text-gray-700">
                                                    From:
                                                  </span>
                                                  <span className="text-gray-500">
                                                    rasmus@infospray.dk (Any
                                                    member)
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="border-t border-gray-200 pt-4">
                                                <div className="flex flex-col space-y-1">
                                                  <span className="font-medium text-gray-700">
                                                    Subject:
                                                  </span>
                                                  <span className="text-gray-500">
                                                    {data?.name} (Process name)
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="border-t border-gray-200 pt-4">
                                                <div className="flex flex-col space-y-1">
                                                  <span className="font-medium text-gray-700">
                                                    Body:
                                                  </span>
                                                  <span className="text-gray-500">
                                                    The body can be captured in
                                                    a special kick-off field
                                                    named below:
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="border-t border-gray-200 pt-4">
                                                <div className="flex flex-col space-y-1">
                                                  <span className="font-medium text-gray-700">
                                                    Attachments:
                                                  </span>
                                                  <span className="text-gray-500">
                                                    Any attachments can be
                                                    stored in a special kick-off
                                                    field named below:
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </AccordionPanel>
                                      </AccordionItem>

                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Via Inbound Webhook
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                          Coming soon! Pretty soon - you'll be
                                          able to launch a process by shooting a
                                          pre-defined webhook to a pre-defined
                                          URL, securely.
                                        </AccordionPanel>
                                      </AccordionItem>

                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Via Magic Link
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel
                                          className="space-y-4 w-full"
                                          pb={4}
                                        >
                                          <div
                                            key="1"
                                            className="bg-white p-6 rounded-lg mx-auto"
                                          >
                                            <p className="text-sm leading-6">
                                              A magic link is just a link which
                                              performs an action and passes
                                              through data from other systems.{' '}
                                              <a
                                                className="text-blue-600 hover:underline"
                                                href="#"
                                              >
                                                LEARN MORE HERE
                                              </a>
                                            </p>
                                            <div className="mt-4">
                                              <p className="font-semibold text-lg">
                                                MAGIC LINK
                                              </p>
                                              <div className="flex justify-between items-center mt-2">
                                                <a
                                                  className="text-blue-600 hover:underline break-all"
                                                  href="#"
                                                >
                                                  https://go.tallyfy.com/1c6882578e022862f2bae804d95bb931/process/6...
                                                </a>
                                                <Button className="bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700">
                                                  COPY LINK
                                                </Button>
                                              </div>
                                            </div>
                                            <div className="mt-6">
                                              <p className="font-semibold text-lg">
                                                UNDERSTANDING YOUR MAGIC LINK
                                              </p>
                                              <div className="mt-2">
                                                <a
                                                  className="text-blue-600 hover:underline break-all"
                                                  href="#"
                                                >
                                                  https://go.tallyfy.com/
                                                  <span className="bg-blue-100 ">
                                                    1c6882578e022862f2bae83d95bb931
                                                  </span>
                                                  /process/
                                                  <span className="bg-green-100 ">
                                                    62ab8aad9476b6dc4d07ecad3c380249
                                                  </span>
                                                  /create?
                                                  <span className="bg-red-100 ">
                                                    default_run_name=Template%20Test%20Name
                                                  </span>
                                                  &
                                                  <span className="bg-yellow-100 ">
                                                    ko_fields=%5B%5D
                                                  </span>
                                                  &launchprocess=true
                                                </a>
                                                <ol className="list-decimal list-inside mt-4 space-y-1">
                                                  <li className="flex items-center bg-blue-100 ">
                                                    <Badge
                                                      className="mr-2"
                                                      variant="secondary"
                                                    >
                                                      1.
                                                    </Badge>
                                                    Organization ID
                                                  </li>
                                                  <li className="flex items-center bg-green-100 ">
                                                    <Badge
                                                      className="mr-2"
                                                      variant="secondary"
                                                    >
                                                      2.
                                                    </Badge>
                                                    Template ID
                                                  </li>
                                                  <li className="flex items-center bg-red-100">
                                                    <Badge
                                                      className="mr-2"
                                                      variant="secondary"
                                                    >
                                                      3.
                                                    </Badge>
                                                    Name of the process
                                                  </li>
                                                  <li className="flex items-center bg-yellow-100">
                                                    <Badge
                                                      className="mr-2"
                                                      variant="secondary"
                                                    >
                                                      4.
                                                    </Badge>
                                                    Kick Off Fields - required
                                                    fields only
                                                  </li>
                                                </ol>
                                              </div>
                                            </div>
                                          </div>
                                        </AccordionPanel>
                                      </AccordionItem>

                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Via API
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel
                                          className="space-y-4 w-full"
                                          pb={4}
                                        >
                                          Infospray BPM is an API-first platform.
                                          LEARN MORE HERE
                                        </AccordionPanel>
                                      </AccordionItem>
                                    </Accordion>
                                  </div>
                                </div>
                              ) : null}

                              {trigger == null && step ? (
                                <div className="space-y-4 ">
                                  <div className="flex flex-row items-center space-x-4">
                                    <h1 className="p-2 rounded-lg bg-blue-500 text-white">
                                      Step 1
                                    </h1>
                                    <div className="flex flex-1 justify-end">
                                      <CloseButton
                                        onClick={() => {
                                          setTrigger(null)
                                          setStep(null)
                                        }}
                                        className=" mx-2"
                                      />
                                    </div>
                                  </div>

                                  <div>
                                    <Accordion allowToggle>
                                      <AccordionItem hidden={Cookies.get('role') == 'member'}>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Milestones
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                          <Select
                                            id="status-toggle"
                                            placeholder="Milestone"
                                            className="w-full"
                                          >
                                            <option value="started">
                                              None
                                            </option>
                                            <option value="started">
                                              Milestone 1
                                            </option>
                                            <option value="inProgress">
                                              Milestone 2
                                            </option>
                                          </Select>
                                          <div className="flex flex-row space-x-4 mt-4">
                                            <Button>
                                              Create New Milestone
                                            </Button>
                                          </div>
                                        </AccordionPanel>
                                      </AccordionItem>

                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Description
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel
                                          className="space-y-4 w-full"
                                          pb={4}
                                        >
                                          <RichTextEditor
                                            value={html}
                                            onChange={(html) => setHtml(html)}
                                          />
                                        </AccordionPanel>
                                      </AccordionItem>

                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Assign
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel
                                          className="space-y-4 w-full"
                                          pb={4}
                                        >
                                          Who does this step every time?
                                          <div className="my-4">
                                            <Button
                                              onClick={() =>{
                                                if(Cookies.get('role') != 'member'){
                                                setStepData({
                                                  ...stepData,
                                                  assignedTo: {
                                                    ...stepData?.assignedTo,
                                                    type: 'fixed',
                                                  },
                                                })
                                              }}
                                              }
                                              colorScheme={
                                                stepData?.assignedTo?.type ==
                                                'fixed'
                                                  ? 'blue'
                                                  : 'gray'
                                              }
                                              className="p-4 rounded-r-none border"
                                            >
                                              Fixed
                                            </Button>
                                            {/* <Button
                                        onClick={() =>
                                          setStepData({
                                            ...stepData,
                                            assignedTo: {
                                              ...stepData?.assignedTo,
                                              type: 'dynamic',
                                            },
                                          })
                                        }
                                        colorScheme={
                                          stepData?.assignedTo?.type ==
                                          'dynamic'
                                            ? 'blue'
                                            : 'gray'
                                        }
                                        className="p-4 rounded-r-none rounded-l-none border-y"
                                      >
                                        Dynamic
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          setStepData({
                                            ...stepData,
                                            assignedTo: {
                                              ...stepData?.assignedTo,
                                              type: 'guest',
                                            },
                                          })
                                        }
                                        colorScheme={
                                          stepData?.assignedTo?.type == 'guest'
                                            ? 'blue'
                                            : 'gray'
                                        }
                                        className="p-4 rounded-l-none border"
                                      >
                                        Guest
                                      </Button> */}
                                          </div>
                                          <div
                                            hidden={
                                              stepData?.assignedTo?.type !==
                                              'fixed'
                                            }
                                          >
                                            Add fixed people or groups that will
                                            always be assigned
                                            <Select
                                              className="my-2"
                                              placeholder="Type member name, email."
                                              onChange={(e) => {
                                                if(Cookies.get('role') != 'member'){
                                                setStepData({
                                                  ...stepData,
                                                  assignedTo: {
                                                    ...stepData.assignedTo,
                                                    value: [
                                                      ...stepData?.assignedTo
                                                        ?.value,
                                                      e.target.value,
                                                    ],
                                                  },
                                                })
                                              }
                                              }}
                                            >
                                              {state?.members?.data?.members?.map(
                                                (el, i) => (
                                                  <option
                                                    key={i}
                                                    value={el.email}
                                                    onClick={(e) =>{
                                                      if(Cookies.get('role') != 'member'){
                                                      setStepData({
                                                        ...stepData,
                                                        assignedTo: {
                                                          ...stepData.assignedTo,
                                                          value: [
                                                            ...stepData
                                                              ?.assignedTo
                                                              ?.value,
                                                            el.email,
                                                          ],
                                                        },
                                                      })
                                                    }}
                                                    }
                                                  >
                                                    {el.fName + ' ' + el.lName}
                                                  </option>
                                                ),
                                              )}
                                            </Select>
                                            {stepData?.assignedTo?.value.map(
                                              (el, i) => (
                                                <Tag
                                                  size={'md'}
                                                  key={i}
                                                  variant="solid"
                                                  colorScheme="teal"
                                                  className="w-fit"
                                                >
                                                  {el}
                                                  <button
                                                    onClick={() => {
                                                      if(Cookies.get('role') != 'member'){
                                                      const stepDataUpdated =
                                                        stepData?.assignedTo
                                                          ?.value
                                                      stepDataUpdated.splice(
                                                        i,
                                                        1,
                                                      )
                                                      setData({
                                                        ...stepData,
                                                        assignedTo: {
                                                          ...stepData.assignedTo,
                                                          value: stepDataUpdated,
                                                        },
                                                      })
                                                    }
                                                    }}
                                                  >
                                                    <TagCloseButton />
                                                  </button>
                                                </Tag>
                                              ),
                                            )}
                                          </div>
                                          {/* <div
                                      hidden={
                                        stepData?.assignedTo?.type !== 'dynamic'
                                      }
                                    >
                                      Choose or create a job title that
                                      completes this step
                                      <MultiSelect
                                        options={options}
                                        value={value}
                                        label="Search..."
                                        onChange={setValue}
                                      />
                                    </div>
                                    <div
                                      hidden={
                                        stepData?.assignedTo?.type !== 'guest'
                                      }
                                    >
                                      You've set this step to be assigned to a
                                      guest. Is there other people that should
                                      be assigned?
                                      <Input
                                        placeholder="ADD ADDITIONAL ASSIGNEES"
                                        value={stepData?.assignedTo?.value?.toString()}
                                        onChange={(e) =>
                                          setStepData({
                                            ...stepData,
                                            assignedTo: {
                                              ...stepData?.assignedTo,
                                              value: e.target.value?.split(','),
                                            },
                                          })
                                        }
                                      />
                                    </div> */}
                                          {/* <div
                                            onClick={(e) =>{
                                              if(Cookies.get('role') != 'member'){
                                              setStepData({
                                                ...stepData,
                                                enforceMaxAssignees:
                                                  e.target.checked,
                                              })
                                            }}
                                            }
                                            className="flex justify-between"
                                          >
                                            <FormLabel htmlFor="1" mb="0">
                                              Enforce a maximum number of
                                              assignees?
                                            </FormLabel>
                                            <Switch
                                              id="1"
                                              isChecked={
                                                stepData?.enforceMaxAssignees
                                              }
                                            />
                                          </div> */}
                                        </AccordionPanel>
                                      </AccordionItem>

                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Type
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel
                                          className="space-y-4 w-full"
                                          pb={4}
                                        >
                                          <Select
                                            value={taskType}
                                            onChange={(e) =>
                                              setTaskType(e.target.value)
                                            }
                                            placeholder="Tasks"
                                            className="w-full"
                                            disabled={Cookies.get('role') == "member"}
                                          >
                                            <option value="task">
                                              {' '}
                                              <CheckCircleIcon className="w-4 h-4" />{' '}
                                              Task
                                            </option>
                                            <option value="approve/reject">
                                              {' '}
                                              <AdjustmentsHorizontalIcon className="w-4 h-4" />{' '}
                                              Approve/Reject
                                            </option>
                                            <option value="expiring">
                                              {' '}
                                              <ClockIcon className="w-4 h-4" />{' '}
                                              Expiring
                                            </option>
                                            <option value="emailDraft">
                                              {' '}
                                              <EnvelopeIcon className="w-4 h-4" />{' '}
                                              Email Draft
                                            </option>
                                            <option value="emailAutoSend">
                                              {' '}
                                              <PaperAirplaneIcon className="w-4 h-4" />{' '}
                                              Email Auto-Send
                                            </option>
                                          </Select>
                                        </AccordionPanel>
                                      </AccordionItem>

                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Form Fields
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel pb={4}>
                                        {Cookies.get('role') == "member" ? <FormRender
                                    formData={data?.forms}
                                    formResponses={data}
                                    setFormResponses={setData}
                                  /> : <FormBuilder
                                            formData={data.forms}
                                            data={data}
                                            setData={setData}
                                          />}
                                        </AccordionPanel>
                                      </AccordionItem>

                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Timings
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel
                                          className="space-y-4 w-full"
                                          pb={4}
                                        >
                                          <div>
                                            Deadline
                                            <InputGroup className="space-x-2">
                                              <Input disabled={Cookies.get('role') == "member"} type="number" value="5" />
                                              <Select
                                                id="status-toggle"
                                                placeholder="time"
                                                className="w-full"
                                                value="days"
                                                disabled={Cookies.get('role') == "member"}
                                              >
                                                <option value="min">
                                                  minutes
                                                </option>
                                                <option value="hrs">
                                                  hours
                                                </option>
                                                <option value="days">
                                                  days
                                                </option>
                                                <option value="weeks">
                                                  weeks
                                                </option>
                                                <option value="months">
                                                  months
                                                </option>
                                              </Select>
                                              <Select
                                                id="status-toggle"
                                                placeholder="after"
                                                className="w-full"
                                                disabled={Cookies.get('role') == "member"}
                                              ></Select>
                                              <Select
                                                id="status-toggle"
                                                placeholder="launch"
                                                className="w-full"
                                                disabled={Cookies.get('role') == "member"}
                                              >
                                                {' '}
                                                <option value="min">
                                                  Milestone 1
                                                </option>
                                              </Select>
                                            </InputGroup>
                                            <div
                                              onClick={() =>
                                                startOnSpecificTime
                                                  ? setStartOnSpecificTime(
                                                      false,
                                                    )
                                                  : setStartOnSpecificTime(true)
                                              }
                                              className="flex  justify-between my-4"
                                            >
                                              <FormLabel htmlFor="2" mb="0">
                                                Start on specific time ?
                                              </FormLabel>
                                              <Switch
                                                id="2"
                                                isChecked={startOnSpecificTime}
                                              />
                                            </div>
                                            <InputGroup
                                              hidden={!startOnSpecificTime}
                                              className="space-x-2"
                                            >
                                              Start
                                              <Input type="number" value="5" />
                                              <Select
                                                id="status-toggle"
                                                placeholder="time"
                                                className="w-full"
                                                value="days"
                                              >
                                                <option value="min">
                                                  minutes
                                                </option>
                                                <option value="hrs">
                                                  hours
                                                </option>
                                                <option value="days">
                                                  days
                                                </option>
                                                <option value="weeks">
                                                  weeks
                                                </option>
                                                <option value="months">
                                                  months
                                                </option>
                                              </Select>
                                              <Select
                                                id="status-toggle"
                                                placeholder="before"
                                                className="w-full"
                                              ></Select>
                                              <Select
                                                id="status-toggle"
                                                placeholder="deadline"
                                                className="w-full"
                                              ></Select>
                                            </InputGroup>
                                          </div>
                                        </AccordionPanel>
                                      </AccordionItem>
{/* 
                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Automations
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel
                                          className="space-y-4 w-full"
                                          pb={4}
                                        >
                                          This step is not involved in any
                                          automations. Review and create
                                          automations here
                                        </AccordionPanel>
                                      </AccordionItem> */}

                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Advanced
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel
                                          className="space-y-4 w-full"
                                          pb={4}
                                        >
                                          {/* <div className="flex justify-between">
                                            <FormLabel htmlFor="1" mb="0">
                                              Launch another process when this
                                              task is complete?
                                            </FormLabel>
                                            <Switch id="1" />
                                          </div>

                                          <div className="flex justify-between">
                                            <FormLabel htmlFor="1" mb="0">
                                              All assignees must complete this
                                              task
                                            </FormLabel>
                                            <Switch id="1" />
                                          </div>

                                          <div className="flex justify-between">
                                            <FormLabel htmlFor="1" mb="0">
                                              Task is mandatory and cannot be
                                              removed at launch
                                            </FormLabel>
                                            <Switch id="1" />
                                          </div>

                                          <div className="flex justify-between">
                                            <FormLabel htmlFor="1" mb="0">
                                              Only assigned members can complete
                                              or edit this task
                                            </FormLabel>
                                            <Switch id="1" />
                                          </div> */}

                                          {/* <div className="flex justify-between">
                                            <FormLabel htmlFor="1" mb="0">
                                              Prevent guests from making or
                                              seeing any comments?
                                            </FormLabel>
                                            <Switch id="1" />
                                          </div> */}

                                          <div className="flex flex-col justify-between">
                                            <Input
                                              type="text"
                                              placeholder="Tags"
                                              onChange={(e) =>
                                                setTagSearch(e.target.value)
                                              }
                                              value={tagSearch}
                                            />
                                            <div className="flex flex-row space-x-2 mt-2">
                                              {tags.map((title, i) => (
                                                <Tag
                                                  size={'md'}
                                                  key={i}
                                                  variant="solid"
                                                  colorScheme="teal"
                                                  className="w-fit"
                                                >
                                                  {title}
                                                  <TagCloseButton />
                                                </Tag>
                                              ))}
                                            </div>
                                          </div>
                                        </AccordionPanel>
                                      </AccordionItem>

                                      {/* <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Outbound Webhooks
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel
                                          className="space-y-4 w-full"
                                          pb={4}
                                        >
                                          <div>
                                            Emit a webhook of the entire process
                                            in JSON.
                                          </div>

                                          <div className="flex justify-between">
                                            <FormLabel htmlFor="1" mb="0">
                                              Emit a webhook when this task is
                                              completed?
                                            </FormLabel>
                                            <Switch id="1" />
                                          </div>
                                          <div>
                                            <Input placeholder="Target URL for webhook *" />
                                          </div>

                                          <div>
                                            <h1 className="font-bold">
                                              Unique Step Alias
                                            </h1>
                                            <p>
                                              This alias uniquely identifies
                                              this step.
                                            </p>
                                            <InputGroup>
                                              <Input value="step-1-8023" />
                                              <InputRightAddon>
                                                <Button>Copy</Button>
                                              </InputRightAddon>
                                            </InputGroup>
                                          </div>
                                        </AccordionPanel>
                                      </AccordionItem>

                                      <AccordionItem>
                                        <h2>
                                          <AccordionButton>
                                            <Text className="flex text-lg py-2 px-2">
                                              Incoming Webhooks
                                            </Text>
                                            <AccordionIcon />
                                          </AccordionButton>
                                        </h2>
                                        <AccordionPanel
                                          className="space-y-4 w-full"
                                          pb={4}
                                        >
                                          <div>
                                            Our REST API handles inbound actions
                                            from external systems.
                                          </div>

                                          <div className="space-y-4">
                                            <InputGroup className="items-center">
                                              <InputLeftAddon className="w-[7rem]">
                                                Process ID
                                              </InputLeftAddon>
                                              <div className="border p-2 rounded-r-lg">
                                                Generated when you launch a
                                                process
                                              </div>
                                            </InputGroup>

                                            <InputGroup className="items-center">
                                              <InputLeftAddon className="w-[7rem]">
                                                Step Alias
                                              </InputLeftAddon>
                                              <Input value="step-1-8023" />
                                              <InputRightAddon>
                                                <Button>Copy</Button>
                                              </InputRightAddon>
                                            </InputGroup>
                                          </div>
                                        </AccordionPanel>
                                      </AccordionItem> */}
                                    </Accordion>

                                    <div className="bg-gray-100 rounded-lg p-4 mt-28">
                                      <Heading
                                        className="flex flex-row items-center"
                                        size={'md'}
                                      >
                                        <ChatBubbleBottomCenterIcon className="w-4 h-4 mr-4" />{' '}
                                        Comments{' '}
                                      </Heading>
                                      <div className="py-6 max-h-[20rem] overflow-y-scroll">
                                        {/* Comments */}
                                        {stepData?.comments?.map((el, i) => (
                                          <div className="p-2 border rounded-lg my-2 bg-gray-50 ">
                                            <Avatar size="sm" />
                                            <div className="flex-col flex mx-auto space-y-4">
                                              <div className="flex flex-row  justify-left space-x-4 items-center">
                                                <p
                                                  dangerouslySetInnerHTML={{
                                                    __html: el?.text,
                                                  }}
                                                ></p>
                                              </div>
                                              <div className="text-sm flex flex-row space-between text-gray-400">
                                                {el.email}{' '}
                                                {el?.createdAt?.replace(
                                                  'T',
                                                  ' ',
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                      <div className="my-4 border bg-white p-4 space-y-2">
                                        <RichTextEditor
                                          value={html}
                                          onChange={(html) => setHtml(html)}
                                        />
                                        <Button
                                          onClick={(e) =>
                                            handleAddComment(e, html)
                                          }
                                        >
                                          Add Comment
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </ModalBody>
                        </ModalContent>
                      </Modal>
                    </TabPanel>
                    <TabPanel>
                      <div className="flex space-x-4 w-full">
                        <aside className="hidden lg:block w-1/3 bg-gray-100 rounded-lg">
                          <nav className="p-4">
                            <h1 className="font-bold">Navigate</h1>
                            <ul className="space-y-2">
                              <li>
                                <a
                                  className="block py-2 px-4 rounded-md hover:bg-gray-200 dark:hover:bg-gray-700"
                                  href="#"
                                >
                                  Step 1
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </aside>
                        <div className="flex justify-center py-10 w-full">
                          <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full lg:w-2/3">
                            <div className="bg-[#D1FAE5] p-6">
                              <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-4">
                                  <CheckCircleIcon className="text-green-500 h-8 w-8" />
                                  <div className="text-lg font-semibold">
                                    step 1
                                  </div>
                                </div>
                                <Button className="bg-green-500 text-white">
                                  GO
                                </Button>
                              </div>
                            </div>
                            <div className="p-6">
                              <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-4">
                                  <Badge variant="secondary">1</Badge>
                                  <div className="text-lg font-semibold">
                                    step 1
                                  </div>
                                </div>
                                <div className="flex items-center space-x-2">
                                  <CheckCircleIcon className="text-green-500 h-6 w-6" />
                                  <div className="text-lg">Task</div>
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-end p-4">
                              <EllipsisHorizontalIcon className="h-6 w-6 text-gray-400" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <AddAutomations />
                    </TabPanel>
                    <TabPanel>
                      <div>
                        <Tabs>
                          <TabList>
                            <Tab className="w-1/4">BULK-CREATE STEPS</Tab>
                            <Tab className="w-1/4">BULK-CHANGE ASSIGNEES</Tab>
                            <Tab className="w-1/4">BULK-CHANGE DEADLINES</Tab>
                            <Tab className="w-1/4">BULK FAVORITE</Tab>
                          </TabList>

                          <TabPanels>
                            <TabPanel>
                              <p className="my-4">
                                Type in every new step you want to create in a
                                new row below. This helps you create lots of
                                steps quickly.
                              </p>
                              <Textarea />
                            </TabPanel>
                            <TabPanel>
                              <div>
                                <InputGroup className="my-4 space-x-4">
                                  <Select
                                    id="status-toggle"
                                    placeholder="To Do"
                                    className="w-full"
                                    value="days"
                                  >
                                    <option value="min">Assign</option>
                                    <option value="hrs">Un Assign</option>
                                  </Select>
                                  <Select
                                    id="status-toggle"
                                    placeholder="Assign"
                                    className="w-full"
                                    value={value}
                                  >
                                    {options.map((el, i) => (
                                      <option value={el.value}>
                                        {el.label}
                                      </option>
                                    ))}
                                  </Select>
                                </InputGroup>
                                <Button>Assign to all steps</Button>
                                <TableContainer>
                                  <Table variant="simple">
                                    <Thead>
                                      <Tr>
                                        <Th>Action</Th>
                                        <Th>Step Name</Th>
                                      </Tr>
                                    </Thead>
                                    <Tbody>
                                      <Tr>
                                        <Td>
                                          <Button>Assign</Button>
                                        </Td>
                                        <Td>Step1</Td>
                                      </Tr>
                                    </Tbody>
                                  </Table>
                                </TableContainer>
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <TableContainer>
                                <Table variant="simple">
                                  <Thead>
                                    <Tr>
                                      <Th>Task name</Th>
                                      <Th>Original</Th>
                                      <Th>New</Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    <Tr>
                                      <Td>1. Step 1</Td>
                                      <Td>5 Days</Td>
                                      <Td>
                                        <div className="flex flex-row space-x-2">
                                          <Input
                                            typr="number"
                                            placeholder="5"
                                          />{' '}
                                          <Select
                                            id="status-toggle"
                                            placeholder="Timeline"
                                            className="w-full"
                                          >
                                            <option value="inProgress">
                                              Mins
                                            </option>
                                            <option value="inProgress">
                                              Hrs
                                            </option>
                                            <option value="started">
                                              Days
                                            </option>
                                            <option value="inProgress">
                                              Weeks
                                            </option>

                                            <option value="started">
                                              Months
                                            </option>
                                          </Select>
                                        </div>
                                      </Td>
                                    </Tr>
                                  </Tbody>
                                </Table>
                              </TableContainer>
                              <Button className="my-4 flex justify-end ml-auto">
                                Save All
                              </Button>
                            </TabPanel>
                            <TabPanel>
                              <p className="my-4">
                                Push this template into the favorites list of
                                these coworkers:
                              </p>
                              <MultiSelect
                                options={options}
                                value={value}
                                label="Type member name, guest email, or group name"
                                onChange={setValue}
                              />
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </div>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DrawerExample
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        _id={id}
      />
      <TemplateLaunch formData={data} />
      <CreateGroups />
    </div>
  )
}

export default CreateProcedure
