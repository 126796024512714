import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Progress,
  Spinner,
  Switch,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
} from '@chakra-ui/react'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import {
  AdjustmentsHorizontalIcon,
  ChatBubbleBottomCenterIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ClockIcon,
  DocumentCheckIcon,
  DocumentDuplicateIcon,
  EllipsisVerticalIcon,
  EnvelopeIcon,
  InboxStackIcon,
  LinkIcon,
  PaperAirplaneIcon,
  PaperClipIcon,
  PencilIcon,
  PlusIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  useDisclosure,
} from '@chakra-ui/react'
import Editor from 'react-simple-wysiwyg'
import {
  openInviteMemberModal,
  closeInviteMemberModal,
} from '../../redux/InviteMemberModal'
import { useDispatch, useSelector } from 'react-redux'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import { useMediaQuery } from 'react-responsive'
import { useNavigate } from 'react-router-dom'
import {
  addTask,
  updateTask,
  addComment,
  fetchAllTasks,
} from '../../redux/TaskSlice'
import { fetchMembers } from '../../redux/MemberSlice'
import Cookies from 'js-cookie'
import moment from 'moment-timezone';
import { format } from 'date-fns';
import {
  openCreateTaskModal,
  closeCreateTaskModal,
} from '../../redux/CreateTaskModal'
import FocusLock from 'react-focus-lock'
import { Select as Select2 } from '@chakra-ui/react'
import { useToast } from '@chakra-ui/react'
import FormBuilder from '../../components/utils/FormBuilder'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import RichTextEditor from '../../components/utils/RichTextEditor'
import Select from "react-select";

function getFormattedDate (utcDate) {
  if(!utcDate) return 
  const date = moment.tz(utcDate, Cookies.get('timeZone'));
  return format(new Date(date.format()), Cookies.get('dateFormat'));
};

const FilterSidebar = ({
  filterSidebarIsOpen,
  filterSidebarOnOpen,
  filterSidebarOnClose,
  applySidebarFilters,
  selectedFilters,
  setSelectedFilters,
  tempFolderName,
  setTempFolderName,
  folderList,
  setFolderList,
  filteredFolderList,
  setFilteredFolderList,
}) => {
  const btnRef = React.useRef()
  const [tags, setTags] = useState(['foo', 'bar'])
  const [tagSearch, setTagSearch] = useState('')

  const state = useSelector((state) => state)

  const handleFilterSelection = (key, value) => {
    setSelectedFilters((prev) => ({ ...prev, [key]: value }))
  }

  const handleApplyFilters = () => {
    applySidebarFilters(selectedFilters)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault() // Prevent form submission
      if (tagSearch.trim() !== '') {
        setSelectedFilters({
          ...selectedFilters,
          tags: [...selectedFilters.tags, tagSearch.trim()],
        })
        setTagSearch('') // Clear input field after adding tag
      }
    }
  }

  const removeTag = (index) => {
    const newTags = [...selectedFilters.tags]
    newTags.splice(index, 1)
    setSelectedFilters({ ...selectedFilters, tags: newTags })
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Drawer
        isOpen={filterSidebarIsOpen}
        placement="right"
        onClose={filterSidebarOnClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Filter</DrawerHeader>
          <DrawerBody>
            <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Status
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel className="flex flex-col space-y-4" pb={4}>
                <Button
                    colorScheme={
                      selectedFilters.status == 'All' ? 'green' : 'gray'
                    }
                    onClick={() => handleFilterSelection('status', 'All')}
                  >
                    All
                  </Button>
                  <Button
                    colorScheme={
                      selectedFilters.status == 'ToDo' ? 'green' : 'gray'
                    }
                    onClick={() => handleFilterSelection('status', 'ToDo')}
                  >
                    To do
                  </Button>
                  <Button
                    colorScheme={
                      selectedFilters.status == 'Completed' ? 'green' : 'gray'
                    }
                    onClick={() => handleFilterSelection('status', 'Completed')}
                  >
                    Completed
                  </Button>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Assignee
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel className="flex flex-col space-y-4" pb={4}>
                <Button
                    colorScheme={
                      selectedFilters.assignee == 'Anyone' ? 'green' : 'gray'
                    }
                    onClick={() => handleFilterSelection('assignee', 'Anyone')}
                  >
                    Anyone
                  </Button>
                  <Button
                    colorScheme={
                      selectedFilters.assignee == 'Me' ? 'green' : 'gray'
                    }
                    onClick={() => handleFilterSelection('assignee', 'Me')}
                  >
                    Me
                  </Button>

                  <Button
                    colorScheme={
                      selectedFilters.assignee != 'Me' && selectedFilters.assignee != 'Anyone' ? 'green' : 'gray'
                    }
                    onClick={() => handleFilterSelection('assignee', 'Username')}
                  >
                    Username
                  </Button>
                  <Select2
                      value={selectedFilters.assignee}
                      className="my-2"
                      placeholder="Search"
                      onChange={(e) => {
                        handleFilterSelection('assignee', e.target.value)
                      }}
                      disabled={selectedFilters.assignee == 'Anyone' || selectedFilters.assignee == 'Me'}
                    >
                      {state?.members?.data?.members?.map((el, i) => (
                        <option
                          key={i}
                          value={el.email}
                          onClick={(e) =>
                            handleFilterSelection('assignee', el.email)
                          }
                        >
                          {el.fName + ' ' + el.lName}
                        </option>
                      ))}
                    </Select2>
                  
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Tags
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel className="flex flex-col space-y-4" pb={4}>
                  <Input
                    type="text"
                    placeholder="Tags"
                    onChange={(e) => setTagSearch(e.target.value)}
                    value={tagSearch}
                    onKeyDown={handleKeyDown}
                  />
                  <div className="flex flex-row space-x-2 mt-2">
                    {selectedFilters.tags.map((title, i) => (
                      <Tag
                        size={'md'}
                        key={i}
                        variant="solid"
                        colorScheme="teal"
                        className="w-fit"
                      >
                        {title}
                        <TagCloseButton onClick={() => removeTag(i)} />
                      </Tag>
                    ))}
                  </div>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={filterSidebarOnClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={tempFolderName}
              onChange={(e) => setTempFolderName(e.target.value)}
              placeholder="Type a name for this Folder"
            />
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant="ghost">
              {' '}
              Close
            </Button>
            <Button
              onClick={() => {
                if (tempFolderName) {
                  setFolderList([...folderList, tempFolderName])
                  onClose()
                }
              }}
              colorScheme="blue"
              ml={3}
            >
              Create Folder
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

function Tasks() {
  const [html, setHtml] = useState('my <b>HTML</b>')
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1024px)' })


  const [isEdit, setIsEdit] = useState('none')
  const [tags, setTags] = useState(['foo'])
  const [tagSearch, setTagSearch] = useState('')

  const [disabled, setDisabled] = useState(false)

  const [tableData, setTableData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [selectedRow, setSelectedRow] = useState(null)
  const [searchTask, setSearchTask] = useState('')

  const [isLoading, setIsLoading] = useState(true)

  const dispatch = useDispatch()
  const router = useNavigate()
  const toast = useToast()

  const tasks = useSelector((state) => state?.tasks?.companyData || [])
  const state = useSelector((state) => state)

  const {
    isOpen: filterSidebarIsOpen,
    onOpen: filterSidebarOnOpen,
    onClose: filterSidebarOnClose,
  } = useDisclosure()

  // Effect to set tableData when tasks change
  useEffect(() => {
    console.log(tasks)
    if (tasks.length > 0) {
      if (searchTask.length > 0) {
        const temp = tasks.filter((item) =>
          item?.name?.toLowerCase().includes(searchTask?.toLowerCase()),
        )
        setTableData(temp)
      } else {
        setTableData(tasks)
      }
    } else {
      setTableData([])
    }

    if (selectedRow) {
      const selectedTask = tasks?.find((task) => task._id === selectedRow)
      console.log(selectedTask)
      setData(selectedTask || null)
    }
  }, [searchTask, tasks])


  // Effect to fetch tasks when component mounts and when certain actions are dispatched
  useEffect(() => {
    dispatch(fetchAllTasks())
    dispatch(fetchMembers())
    setIsLoading(false)

  }, [dispatch, addTask, updateTask])

  const [data, setData] = useState({
    taskType: 'task',
    name: '',
    desc: '',
    assignedTo: { type: 'fixed', value: [] },
    guests: [],
    start: {
      date: null,
    },
    deadline: {
      date: null,
    },
    html: '',
    linkTo: ['', ''],
    forms: [
      // {
      //   type: 'header',
      //   subtype: 'h1',
      //   label: 'formBuilder in React',
      // },
      // {
      //   type: 'paragraph',
      //   label:
      //     'This is a demonstration of formBuilder running in a React project.',
      // },
    ],
    tags: ['Project', 'Proposal'],
    allAssigneesMustComplete: false,
    enforceMaxAssignees: true,
    maxAssignees: null,
    preventGuestComments: true,
    emitWebhookOnCompletion: false,
    targetUrl: null,
    comments: []
  })

  const submitUpdateTask = async (e) => {
    e.preventDefault()

    console.log(data)

    if (data.name.length > 1) {
      setDisabled(true)
      dispatch(updateTask(data)) // Dispatch the addTask action with the new task data
        .then(() => {
          toast({
            title: 'Task Updated!',
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
        })
        .catch((error) => {
          toast({
            title: error.message || 'Something went wrong!',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        })
        .finally(() => {
          setDisabled(false)
        })
    } else {
      toast({
        title: 'Invalid task data!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const submitMarkComplete = async (e, status) => {
    e.preventDefault()
    await setData({ ...data, status })

    await dispatch(updateTask({ ...data, status })) // Dispatch the addTask action with the new task data
      .then(() => {
        toast({
          title: 'Task Updated!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        })
      })
      .catch((error) => {
        toast({
          title: error.message || 'Something went wrong!',
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      })
      .finally(() => {
        setDisabled(false)
      })
  }

  const handleAddComment = async (e, text) => {
    e.preventDefault()

    if (data.name.length > 1) {
      setDisabled(true)
      dispatch(addComment({ ...data, text: text })) // Dispatch the addTask action with the new task data
        .then(() => {
          dispatch(fetchAllTasks())
          toast({
            title: 'Comment Updated!',
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
        })
        .catch((error) => {
          toast({
            title: error.message || 'Something went wrong!',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        })
        .finally(() => {
          setDisabled(false)
        })
    } else {
      toast({
        title: 'Invalid comment data!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const [allActivities, setAllActivities] = useState([])
  useEffect(() => {
    if (data && data.activity && data.activity.length > 0) {
      let temp = []
      data?.activity?.forEach((activity) => {
        temp.push({ ...activity, taskName: data.name })
      })

      setAllActivities(groupActivitiesByDate(temp))
    } else {
      setAllActivities([])
    }
  }, [data])

  // Function to group activities by date
  function groupActivitiesByDate(activities) {
    const groupedActivities = activities.reduce((groups, activity) => {
      const date = new Date(activity?.createdAt?.split('T')).toLocaleDateString(
        'en-US',
      )
      if (!groups[date]) {
        groups[date] = { date, activities: [] }
      }
      groups[date].activities.push(activity)
      return groups
    }, {})
    return Object.values(groupedActivities)
  }

  const renderText = (text, data) => {
    // Regular expression to find double curly braces
    const regex = /{{(.*?)}}/gm

    // Replace placeholders with actual data values
    const result = text?.replace(regex, (_, key) => {
      // Access the nested 'value' field dynamically
      return data[key]?.value || key
    })

    return result
  }

  const [tempFolderName, setTempFolderName] = useState('')
  const [folderList, setFolderList] = useState([])
  const [filteredFolderList, setFilteredFolderList] = useState(folderList)

  const handleClearFilters = () => {
    setFilters({
      status: 'All',
      assignee: 'Anyone',
      tags: [],
    })
  }

  const handleRemoveTag = (tag) => {
    const newTags = filters.tags.filter((t) => t !== tag)
    setFilters((prevFilters) => ({
      ...prevFilters,
      tags: newTags,
    }))
  }

  const [filters, setFilters] = useState({
    status: 'ToDo',
    assignee: 'Me',
    tags: [],
  })
  const handleFilterChange = (key, value) => {
    const newFilters = { ...filters, [key]: value }
    setFilters(newFilters)
  }

  const [sort, setSort] = useState('')
  const handleSortChange = (value) => {
    setSort(value)
    const sortedData = [...filteredData]
    switch (value) {
      case 'Newest First':
        sortedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        break
      case 'Oldest First':
        sortedData.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
        break
      case 'By Due Date':
        sortedData.sort((a, b) => new Date(a.finish) - new Date(b.finish))
        break
      case 'Sort A-Z':
        sortedData.sort((a, b) => a.name.localeCompare(b.name))
        break
      case 'Sort Z-A':
        sortedData.sort((a, b) => b.name.localeCompare(a.name))
        break
      default:
        break
    }
    setFilteredData(sortedData)
  }
  const applyFilters = (filters) => {
    let data = tableData

    if (filters.status) {
      if (filters.status == 'All') {
        data = data
      } else if (filters.status == 'ToDo') {
        data = data.filter((item) => item.status == 'Pending')
      } else if (filters.status == 'Completed') {
        data = data.filter((item) => item.status == 'Completed')
      }
    }

    if (filters.assignee) {
      if (filters.assignee == 'Me') {
        data = data.filter(
          (item) => item.assignedTo.value.includes(Cookies.get('email')),
        )
      } else if (filters.assignee == 'Anyone') {
        data = data.filter(
          (item) => !item.assignedTo.value.includes(Cookies.get('email')),
        )
      }
      else if (filters.assignee != 'Me' && filters.assignee != 'Anyone') {
        data = data.filter(
          (item) => item.assignedTo.value.includes(filters.assignee),
        )
      }
    }

    if (filters.tags.length > 0) {
      data = data.filter((item) =>
        filters.tags.every((tag) => item.tags.includes(tag)),
      )
    }

    setFilteredData(data)
  }
  useEffect(() => {
    if (tableData.length > 0) {
      applyFilters(filters)
    }
  }, [tableData, filters])

  return (
    <div style={{ overflowY: 'hidden' }} className="overflow-y-hidden">
      <Modal isCentered isOpen={isLoading}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
          className="items-center flex justify-center"
        >
          <Spinner size="xl" />
        </ModalOverlay>
      </Modal>{' '}
      <Header />
      <div className="ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-2 py-2 space-y-4 flex flex-row gap-x-4">
        <div
          className={`${selectedRow == null ? 'w-full' : 'w-full lg:w-1/2'} `}
        >
          <div className="flex flex-col space-x-4 p-4 space-y-4">
            <div className="flex flex-col md:flex-row space-y-1 md:space-x-1 md:space-x-4">
              <Tag
                size={'md'}
                variant="solid"
                colorScheme="gray"
                className="w-fit"
              >
                Status: {filters.status}
                <TagCloseButton
                  onClick={() => handleFilterChange('status', 'All')}
                />
              </Tag>
              <Tag
                size={'md'}
                variant="solid"
                colorScheme="gray"
                className="w-fit"
              >
                Assignee: {filters.assignee}
                <TagCloseButton
                  onClick={() => handleFilterChange('assignee', 'Anyone')}
                />
              </Tag>
              {filters.tags.map((tag, index) => (
                <Tag
                  key={index}
                  size={'md'}
                  variant="solid"
                  colorScheme="gray"
                  className="w-fit"
                  onClick={() => handleRemoveTag(tag)}
                >
                  {tag}
                  <TagCloseButton />
                </Tag>
              ))}
              <Button size="xs" colorScheme="red" onClick={handleClearFilters}>
                Clear Filters
              </Button>
            </div>
            <div className="flex flex-col md:flex-row space-y-1 md:space-x-1 md:space-x-4">
              <Button
                className="w-[10rem]"
                leftIcon={<PlusIcon className="w-4 h-4" />}
                onClick={filterSidebarOnOpen}
              >
                Add Filter
              </Button>
              <Menu>
                <MenuButton
                  className="w-[10rem]"
                  as={Button}
                  rightIcon={<ChevronDownIcon className="w-4 h-4" />}
                >
                  {sort || 'Sort'}
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => handleSortChange('Newest First')}>
                    Newest First
                  </MenuItem>
                  <MenuItem onClick={() => handleSortChange('Oldest First')}>
                    Oldest First
                  </MenuItem>
                  <MenuItem onClick={() => handleSortChange('By Due Date')}>
                    By Due Date
                  </MenuItem>
                  <MenuItem onClick={() => handleSortChange('Sort A-Z')}>
                    Sort A-Z
                  </MenuItem>
                  <MenuItem onClick={() => handleSortChange('Sort Z-A')}>
                    Sort Z-A
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
          <div className="flex flex-row space-x-4 p-4">
            <Input
              type="text"
              value={searchTask}
              onChange={(e) => setSearchTask(e.target.value)}
              placeholder="Type in the task"
            />
            <Button
              onClick={(e) => {
                e.preventDefault()
                dispatch(openCreateTaskModal(searchTask))
              }}
            >
              Add Task
            </Button>
          </div>
          <TableContainer>
            <Table variant="simple">
              <Thead className="hidden">
                <Tr>
                  <Th>Title</Th>
                  <Th>Date</Th>
                  <Th>Avatar</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredData && filteredData.length > 0
                  ? filteredData.map((el, i) => (
                      <Tr
                        className={`${
                          el._id == selectedRow
                            ? 'bg-gray-100 border-2 border-blue-500'
                            : ''
                        }`}
                        onClick={() => {
                          setSelectedRow(el._id)
                          setData(
                            tasks.filter((task) => task._id === el._id)[0],
                          )
                        }}
                        
                      >
                        <Td>
                          {renderText(
                            el?.name,
                            el?.templateId?.kickOffForm?.forms,
                          )}
                        </Td>
                        <Td>{getFormattedDate(el?.deadline?.date)}</Td>
                        <Td>
                          <Avatar
                            size="sm"
                            name={el?.assignedTo?.value[0]}
                            src={el.avatarUrl}
                          />
                        </Td>
                        <Td>
                          <CheckCircleIcon
                            className={`w-8 h-8 ${
                              el.status == 'Pending'
                                ? 'text-gray-500'
                                : 'text-green-500'
                            } `}
                          />{' '}
                        </Td>
                      </Tr>
                    ))
                  : null}
              </Tbody>
            </Table>
          </TableContainer>
        </div>
        <div
          hidden={selectedRow == null}
          className="hidden lg:block w-1/2 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300"
        >
          <div hidden={isEdit != 'none'} className="border p-4">
            <div className="flex flex-row">
              <h1 className="flex-grow flex-1 font-bold text-lg">
                {renderText(data?.name, data?.templateId?.kickOffForm?.forms)}
              </h1>
              <div className="flex flex-row space-x-2">
                {/* <Button>
                  <LinkIcon className="w-4 h-4" />
                </Button> */}
                <Menu>
                  <MenuButton as={Button}>
                    <EllipsisVerticalIcon className="w-4 h-4" />
                  </MenuButton>
                  <MenuList>
                    <MenuItem onClick={() => setIsEdit('edit')}>Edit</MenuItem>
                    {/* <MenuItem onClick={() => setIsEdit('activity')}>
                      Activity Logs
                    </MenuItem> */}
                    {/* <MenuItem>Resend to Assignees</MenuItem>
                    <MenuItem>Copy Link</MenuItem>
                    <MenuItem>View Process</MenuItem>
                    <MenuItem>Hide this Task</MenuItem>
                    <MenuItem>Add to Folder(s)</MenuItem>
                    <MenuItem>Duplicate</MenuItem> */}
                  </MenuList>
                </Menu>
                <Button onClick={() => setSelectedRow(null)}>
                  <XMarkIcon className="w-4 h-4" />
                </Button>
              </div>
            </div>
            <div>
              <h1 className="font-semibold"> {data?.status}</h1>
              <p
                dangerouslySetInnerHTML={{
                  __html: renderText(
                    data?.desc,
                    data?.templateId?.kickOffForm?.forms,
                  ),
                }}
              ></p>
            </div>
            <div className="my-4 space-x-2">
              <Button
                onClick={(e) =>
                  submitMarkComplete(
                    e,
                    data.status == 'Pending' ? 'Completed' : 'Pending',
                  )
                }
                colorScheme="green"
              >
                {data.status == 'Pending' ? 'Mark Completed' : 'Mark Pending'}
              </Button>
              {/* <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon className="w-4 h-4" />}
                >
                  Comment
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={(e) => setHtml('I am working on this')}>
                    I'm working on this
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Request status')}>
                    Request status
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Offer help')}>
                    Offer help
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Say thanks')}>
                    Say thanks
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Quick Tutorial')}>
                    Quick Tutorial
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Something else')}>
                    Something else
                  </MenuItem>
                </MenuList>
              </Menu> */}
              <Button onClick={(e) => submitUpdateTask(e)} colorScheme="green">
                Save Changes
              </Button>
            </div>
            <div>
              <div className="flex flex-row space-x-4 border-y-2 p-2">
                <h1 className="font-bold w-1/3">Milestones</h1>
                <p className="w-2/3">None</p>
              </div>
              <div className="flex flex-row space-x-4 p-2 items-center">
                <h1 className="font-bold w-1/3">Assigned</h1>
                <InputGroup className="w-2/3">
                <Select
  value={data?.assignedTo.value?.map((email) => {
    // Find the member corresponding to the email
    const member = state?.members?.data?.members?.find((el) => el.email === email);
    return {
      label: member ? member.fName + ' ' + member.lName : '',
      value: email,
    };
  })}
  className="my-2"
  placeholder="Search"
  onChange={(selectedOptions) => {
    setData({
      ...data,
      assignedTo: {
        ...data.assignedTo,
        value: selectedOptions.map(option => option.value), // Extract email values
      },
    });
  }}
  isMulti // To allow multiple selections
  options={state?.members?.data?.members?.map((el) => ({
    label: el.fName + ' ' + el.lName,
    value: el.email,
  }))}
/>
                </InputGroup>
              </div>
              <div className="flex flex-row space-x-4 border-y-2 p-2 items-center">
                <h1 className="font-bold w-1/3">Due</h1>
                <InputGroup className="w-2/3">
                  <Input
                    placeholder="Select Date and Time"
                    size="md"
                    type="date"
                    value={data?.deadline.date}
                    onChange={(e) =>
                      setData({
                        ...data,
                        deadline: { ...data.deadline, date: e.target.value },
                      })
                    }
                  />
                </InputGroup>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: renderText(
                    data?.desc,
                    data?.templateId?.kickOffForm?.forms,
                  ),
                }}
                className="my-6 p-2 border rounded-lg"
              ></div>

              <div className="bg-gray-100 rounded-lg p-4">
                <Heading
                  className="flex flex-row items-center pb-4"
                  size={'md'}
                >
                  <ChatBubbleBottomCenterIcon className="w-4 h-4 mr-4" />{' '}
                  Comments{' '}
                </Heading>
                <div className="py-6 max-h-[20rem] overflow-y-scroll">
                  {/* Comments */}
                  {data?.comments?.map((el, i) => (
                    <div className="p-2 border rounded-lg my-2 bg-gray-50 ">
                      <Avatar size="sm" />
                      <div className="flex-col flex mx-auto space-y-4">
                        <div className="flex flex-row  justify-left space-x-4 items-center">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: renderText(
                                el?.text,
                                data?.templateId?.kickOffForm?.forms,
                              ),
                            }}
                          ></p>
                        </div>
                        <div className="text-sm flex flex-row space-between text-gray-400">
                          {el.email} {getFormattedDate(el?.createdAt)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="my-4 border bg-white p-4 space-y-2">
                  <RichTextEditor value={html} onChange={(html) => setHtml(html)} />
                  <Button onClick={(e) => handleAddComment(e, html)}>
                    Add Comment
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div hidden={isEdit != 'edit'} className="border p-4 ">
            <div className="flex flex-col mx-auto justify-center items-center space-y-4 w-full">
              <div className="flex flex-row space-x-4">
                {' '}
                <Button
                  onClick={() => {
                    setIsEdit('none')
                    setData(tasks.filter((task) => task._id === selectedRow)[0])
                  }}
                >
                  Discard
                </Button>
                <Button onClick={(e) => submitUpdateTask(e)}>Save</Button>
              </div>
              <InputGroup>
                <Input
                  type="text"
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  placeholder="Name this task"
                />
                <InputRightAddon className="p-0 m-0 w-[10rem]">
                  <Select2
                    value={data.taskType}
                    onChange={(e) =>
                      setData({ ...data, taskType: e.target.value })
                    }
                    placeholder="Select option"
                    className="w-[10rem]"
                  >
                    <option value="task">
                      {' '}
                      <CheckCircleIcon className="w-4 h-4" /> Task
                    </option>
                    <option value="approve/reject">
                      {' '}
                      <AdjustmentsHorizontalIcon className="w-4 h-4" />{' '}
                      Approve/Reject
                    </option>
                    <option value="expiring">
                      {' '}
                      <ClockIcon className="w-4 h-4" /> Expiring
                    </option>
                    <option value="emailDraft">
                      {' '}
                      <EnvelopeIcon className="w-4 h-4" /> Email Draft
                    </option>
                    <option value="emailAutoSend">
                      {' '}
                      <PaperAirplaneIcon className="w-4 h-4" /> Email Auto-Send
                    </option>
                  </Select2>
                </InputRightAddon>
              </InputGroup>
              <InputGroup>
                <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                  Desc
                </InputLeftAddon>
                <Input
                  type="text"
                  value={data.desc}
                  onChange={(e) => setData({ ...data, desc: e.target.value })}
                  placeholder="Write a short description about this..."
                />
              </InputGroup>
              <InputGroup zIndex={1000}>
    <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
      <UserGroupIcon className="w-4 h-4" />
    </InputLeftAddon>
    <div className="flex-1 z-1000">

                                <Select
  value={data?.assignedTo.value?.map((email) => {
    // Find the member corresponding to the email
    const member = state?.members?.data?.members?.find((el) => el.email === email);
    return {
      label: member ? member.fName + ' ' + member.lName : '',
      value: email,
    };
  })}
  placeholder="Search"
  onChange={(selectedOptions) => {
    setData({
      ...data,
      assignedTo: {
        ...data.assignedTo,
        value: selectedOptions.map(option => option.value), // Extract email values
      },
    });
  }}
  isMulti // To allow multiple selections
  options={state?.members?.data?.members?.map((el) => ({
    label: el.fName + ' ' + el.lName,
    value: el.email,
  }))}
  styles={{
    control: (base) => ({
      ...base,
      border: "1px solid #CBD5E0", // Chakra gray-300 equivalent
      borderRadius: "0.375rem", // Chakra borderRadius 'md'
      minHeight: "2.5rem", // Adjust height
      boxShadow: "none",
      zIndex: "1000",
      borderLeft: "0"
    }),
  }}
/>
</div>

              </InputGroup>
              <InputGroup zIndex={10}>
                <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                  Start
                </InputLeftAddon>
                <Input
                  placeholder="Select Date and Time"
                  size="md"
                  type="date"
                  zIndex={10}
                  value={data.start.value}
                  onChange={(e) =>
                    setData({
                      ...data,
                      start: { ...data.start, date: e.target.value },
                    })
                  }
                />
              </InputGroup>
              <InputGroup zIndex={10}>
                <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                  Finish
                </InputLeftAddon>
                <Input
                  placeholder="Select Date and Time"
                  size="md"
                  type="date"
                  zIndex={10}
                  value={data.deadline.date}
                  onChange={(e) =>
                    setData({
                      ...data,
                      deadline: { ...data.deadline, date: e.target.value },
                    })
                  }
                />
              </InputGroup>

              {/* Advance Settings */}
              <Accordion className="w-full" allowToggle>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box
                        as="span"
                        flex="1"
                        textAlign="right"
                        className="mr-2"
                      >
                        Advance Settings
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel className="space-y-4 w-full" pb={4}>
                    <RichTextEditor
                      value={data.html}
                      onChange={(html) =>
                        setData({ ...data, html: html })
                      }
                    />
                    <div>
                      <Text className="flex font-bold text-lg mt-8 mb-2">
                        <PaperClipIcon className="w-6 h-6" />
                        Link this task to
                      </Text>
                      <Select2
                        value={data.linkTo[0]}
                        onChange={(e) => {
                          // Assuming index is the index of the value you want to edit
                          const updatedLinkTo = [...data.linkTo]
                          updatedLinkTo[0] = e.target.value // Update the second value
                          setData({ ...data, linkTo: updatedLinkTo })
                        }}
                        placeholder="Select option"
                        className="w-full"
                      >
                        <option value="process">
                          <DocumentCheckIcon className="w-4 h-4" />{' '}
                          <div>
                            <h1>Process - </h1>
                            <p>Insert an ad-hoc task into an active process.</p>
                          </div>
                        </option>

                        <option value="template">
                          {' '}
                          <InboxStackIcon className="w-4 h-4" />{' '}
                          <div>
                            <h1>Templates - </h1>
                            <p>
                              Link a standalone task to a template or template
                              step.
                            </p>
                          </div>
                        </option>
                      </Select2>
                      {data.linkTo[0] ? (
                        <Select2
                          value={data.linkTo[1]}
                          onChange={(e) => {
                            // Assuming index is the index of the value you want to edit
                            const updatedLinkTo = [...data.linkTo]
                            updatedLinkTo[1] = e.target.value // Update the second value
                            setData({ ...data, linkTo: updatedLinkTo })
                          }}
                          placeholder="Search for active processes"
                          className="w-full"
                        >
                          <option value="process">option 1</option>

                          <option value="template">option 2</option>
                        </Select2>
                      ) : null}
                      <div className="p-2 border my-4">
                        <Accordion allowToggle>
                          <AccordionItem>
                            <h2>
                              <AccordionButton>
                                <Text className="flex font-bold text-lg mt-4 mb-2">
                                  <DocumentDuplicateIcon className="w-6 h-6" />
                                  Forms
                                </Text>
                                <AccordionIcon />
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                              <FormBuilder
                                formData={data.forms}
                                data={data}
                                setData={setData}
                              />
                            </AccordionPanel>
                          </AccordionItem>
                        </Accordion>
                      </div>
                      <input
                        type="text"
                        placeholder="Tags"
                        onChange={(e) => setTagSearch(e.target.value)}
                        value={tagSearch}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            if (!data.tags.includes(tagSearch)) {
                              setData({
                                ...data,
                                tags: [...data.tags, tagSearch],
                              })
                            }
                            setTagSearch('')
                          }
                        }}
                      />
                      <div className="flex flex-row space-x-2 mt-2">
                        {data.tags.map((title, i) => (
                          <Tag
                            size={'md'}
                            key={i}
                            variant="solid"
                            colorScheme="teal"
                            className="w-fit"
                          >
                            {title}
                            <button
                              onClick={() => {
                                const updatedTags = data.tags
                                updatedTags.splice(i, 1)
                                setData({ ...data, tags: updatedTags })
                              }}
                            >
                              <TagCloseButton />
                            </button>
                          </Tag>
                        ))}
                      </div>
                    </div>
                    {/* <FormControl
                      className="flex flex-col space-y-4 py-4"
                      alignItems="left"
                    >
                      <div
                        onClick={(e) =>
                          setData({
                            ...data,
                            allAssigneesMustComplete: e.target.checked,
                          })
                        }
                        className="flex justify-between"
                      >
                        <FormLabel htmlFor="1" mb="0">
                          All assignees must complete this task
                        </FormLabel>
                        <Switch
                          id="1"
                          isChecked={data.allAssigneesMustComplete}
                        />
                      </div>

                      <div
                        onClick={(e) =>
                          setData({
                            ...data,
                            enforceMaxAssignees: e.target.checked,
                          })
                        }
                        className="flex justify-between"
                      >
                        <FormLabel htmlFor="2" mb="0">
                          Enforce a maximum number of assignees?
                        </FormLabel>
                        <Switch id="2" isChecked={data.enforceMaxAssignees} />
                      </div>
                      <Input
                        hidden={!data.enforceMaxAssignees}
                        className="my-2"
                        placeholder="Max No. of Assignees"
                        value={data.maxAssignees}
                        onChange={(e) => {
                          setData({ ...data, maxAssignees: e.target.value })
                        }}
                      />

                      <div
                        onClick={(e) =>
                          setData({
                            ...data,
                            preventGuestComments: e.target.checked,
                          })
                        }
                        className="flex justify-between"
                      >
                        <FormLabel htmlFor="3" mb="0">
                          Prevent guests from making or seeing any comments?
                        </FormLabel>
                        <Switch id="3" isChecked={data.preventGuestComments} />
                      </div> 

                      <div
                        onClick={(e) =>
                          setData({
                            ...data,
                            emitWebhookOnCompletion: e.target.checked,
                          })
                        }
                        className="flex justify-between"
                      >
                        <FormLabel htmlFor="4" mb="0">
                          Emit a webhook when this task is completed?
                        </FormLabel>
                        <Switch
                          id="4"
                          isChecked={data.emitWebhookOnCompletion}
                        />
                      </div>
                      <Input
                        className="my-2"
                        hidden={!data.emitWebhookOnCompletion}
                        placeholder="Target URL"
                        value={data.targetUrl}
                        onChange={(e) =>
                          setData({ ...data, targetUrl: e.target.value })
                        }
                      />
                    </FormControl> */}
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
          <div hidden={isEdit != 'activity'} className="border p-4 ">
            <div>
              <Button
                className="mb-4"
                onClick={() => {
                  setIsEdit('none')
                }}
              >
                Back
              </Button>
              {/* Group activities by date */}
              {allActivities?.map((group, index) => (
                <div key={index}>
                  {/* Heading */}
                  <div className="ps-2 my-2 first:mt-0">
                    <h3 className="text-xs font-medium uppercase text-gray-500">
                      {/* Format group date */}
                      {new Date(group.date).toLocaleDateString('en-US', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                      })}
                    </h3>
                  </div>
                  {/* End Heading */}
                  {/* Render activities for the current date */}
                  {group.activities.map((activity, index) => (
                    <div key={index} className="flex gap-x-3">
                      {/* Icon */}
                      <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                        <div className="relative z-10 size-7 flex justify-center items-center">
                          <div className="size-2 rounded-full bg-gray-400" />
                        </div>
                      </div>
                      {/* End Icon */}
                      {/* Right Content */}
                      <div className="grow pt-0.5 pb-8">
                        <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                          {/* Render activity message */}
                          {activity.message}
                        </h3>
                        {/* Render additional details */}
                        {activity.linkedTask && (
                          <p className="mt-1 text-sm text-gray-600">
                            Linked Task: {activity.taskName}
                          </p>
                        )}
                        {/* Render user */}
                        <button
                          type="button"
                          className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
                        >
                          <Avatar size="xs" alt="User Avatar" />
                          {activity.createdBy}{' '}
                          {/* Assuming name is present in createdBy object */}
                        </button>
                      </div>
                      {/* End Right Content */}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={selectedRow != null && isSmallScreen}
        onClose={() => setSelectedRow(null)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody className="my-10">
            <div hidden={isEdit != 'none'} className="border p-4">
              <div className="flex flex-row">
                <h1 className="flex-grow flex-1 font-bold text-lg">
                  {data?.name}
                </h1>
                <div className="flex flex-row space-x-2">
                  {/* <Button>
                    <LinkIcon className="w-4 h-4" />
                  </Button> */}
                  <Menu>
                    <MenuButton as={Button}>
                      <EllipsisVerticalIcon className="w-4 h-4" />
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => setIsEdit('edit')}>
                        Edit
                      </MenuItem>
                      {/* <MenuItem onClick={() => setIsEdit('activity')}>
                        Activity Logs
                      </MenuItem> */}
                      {/* <MenuItem>Resend to Assignees</MenuItem>
                      <MenuItem>Copy Link</MenuItem>
                      <MenuItem>View Process</MenuItem>
                      <MenuItem>Hide this Task</MenuItem>
                      <MenuItem>Add to Folder(s)</MenuItem>
                      <MenuItem>Duplicate</MenuItem> */}
                    </MenuList>
                  </Menu>
                  <Button onClick={() => setSelectedRow(null)}>
                    <XMarkIcon className="w-4 h-4" />
                  </Button>
                </div>
              </div>
              <div>
                <h1 className="font-semibold"> {data?.status}</h1>
                <p
                  dangerouslySetInnerHTML={{
                    __html: renderText(
                      data?.desc,
                      data?.templateId?.kickOffForm?.forms,
                    ),
                  }}
                ></p>
              </div>
              <div className="my-4 space-x-2">
                <Button
                  onClick={(e) =>
                    submitMarkComplete(
                      e,
                      data.status == 'Pending' ? 'Completed' : 'Pending',
                    )
                  }
                  colorScheme="green"
                >
                  {data.status == 'Pending' ? 'Mark Completed' : 'Mark Pending'}
                </Button>
                {/* <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon className="w-4 h-4" />}
                >
                  Comment
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={(e) => setHtml('I am working on this')}>
                    I'm working on this
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Request status')}>
                    Request status
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Offer help')}>
                    Offer help
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Say thanks')}>
                    Say thanks
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Quick Tutorial')}>
                    Quick Tutorial
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Something else')}>
                    Something else
                  </MenuItem>
                </MenuList>
              </Menu> */}
                <Button
                  onClick={(e) => submitUpdateTask(e)}
                  colorScheme="green"
                >
                  Save Changes
                </Button>
              </div>
              <div>
                <div className="flex flex-row space-x-4 border-y-2 p-2">
                  <h1 className="font-bold w-1/3">Milestones</h1>
                  <p className="w-2/3">None</p>
                </div>
                <div className="flex flex-row space-x-4 p-2 items-center">
                  <h1 className="font-bold w-1/3">Assigned</h1>
                  <InputGroup zIndex={1000}>
    <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
      <UserGroupIcon className="w-4 h-4" />
    </InputLeftAddon>
    <div className="flex-1 z-1000">

                                <Select
  value={data?.assignedTo.value?.map((email) => {
    // Find the member corresponding to the email
    const member = state?.members?.data?.members?.find((el) => el.email === email);
    return {
      label: member ? member.fName + ' ' + member.lName : '',
      value: email,
    };
  })}
  placeholder="Search"
  onChange={(selectedOptions) => {
    setData({
      ...data,
      assignedTo: {
        ...data.assignedTo,
        value: selectedOptions.map(option => option.value), // Extract email values
      },
    });
  }}
  isMulti // To allow multiple selections
  options={state?.members?.data?.members?.map((el) => ({
    label: el.fName + ' ' + el.lName,
    value: el.email,
  }))}
  styles={{
    control: (base) => ({
      ...base,
      border: "1px solid #CBD5E0", // Chakra gray-300 equivalent
      borderRadius: "0.375rem", // Chakra borderRadius 'md'
      minHeight: "2.5rem", // Adjust height
      boxShadow: "none",
      zIndex: "1000",
      borderLeft: "0"
    }),
  }}
/>
</div>

              </InputGroup>
                </div>
                <div className="flex flex-row space-x-4 border-y-2 p-2 items-center">
                  <h1 className="font-bold w-1/3">Due</h1>
                  <InputGroup className="w-2/3">
                    <Input
                      placeholder="Select Date and Time"
                      size="md"
                      type="date"
                      value={data?.deadline.vaue}
                      onChange={(e) =>
                        setData({
                          ...data,
                          deadline: { ...data.deadline, date: e.target.value },
                        })
                      }
                    />
                  </InputGroup>
                </div>
                <div className="my-2 p-4 border bg-gray-100 rounded-lg">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data?.html,
                    }}
                  />

                  <p>{data?.desc}</p>
                </div>

                <div className="bg-gray-100 rounded-lg p-4">
                  <Heading
                    className="flex flex-row items-center pb-4"
                    size={'md'}
                  >
                    <ChatBubbleBottomCenterIcon className="w-4 h-4 mr-4" />{' '}
                    Comments{' '}
                  </Heading>
                  <div className="py-6 max-h-[20rem] overflow-y-scroll">
                    {/* Comments */}
                    {data?.comments?.map((el, i) => (
                      <div className="p-2 border rounded-lg my-2 bg-gray-50 ">
                        <Avatar size="sm" />
                        <div className="flex-col flex mx-auto space-y-4">
                          <div className="flex flex-row  justify-left space-x-4 items-center">
                            <p
                              dangerouslySetInnerHTML={{ __html: el?.text }}
                            ></p>
                          </div>
                          <div className="text-sm flex flex-row space-between text-gray-400">
                            {el.email} {getFormattedDate(el?.createdAt)}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="my-4 border bg-white p-4 space-y-2">
                    <RichTextEditor value={html} onChange={(html) => setHtml(html)}  />
                    <Button onClick={(e) => handleAddComment(e, html)}>
                      Add Comment
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div hidden={isEdit != 'edit'} className="border p-4 ">
              <div className="flex flex-col mx-auto justify-center items-center space-y-4 w-full">
                <div className="flex flex-row space-x-4">
                  {' '}
                  <Button
                    onClick={() => {
                      setIsEdit('none')
                      setData(
                        tasks.filter((task) => task._id === selectedRow)[0],
                      )
                    }}
                  >
                    Discard
                  </Button>
                  <Button onClick={(e) => submitUpdateTask(e)}>Save</Button>
                </div>
                <InputGroup>
                  <Input
                    type="text"
                    value={data.name}
                    onChange={(e) => setData({ ...data, name: e.target.value })}
                    placeholder="Name this task"
                  />
                  <InputRightAddon className="p-0 m-0 w-[10rem]">
                    <Select2
                      value={data.taskType}
                      onChange={(e) =>
                        setData({ ...data, taskType: e.target.value })
                      }
                      placeholder="Select option"
                      className="w-[10rem]"
                    >
                      <option value="task">
                        {' '}
                        <CheckCircleIcon className="w-4 h-4" /> Task
                      </option>
                      <option value="approve/reject">
                        {' '}
                        <AdjustmentsHorizontalIcon className="w-4 h-4" />{' '}
                        Approve/Reject
                      </option>
                      <option value="expiring">
                        {' '}
                        <ClockIcon className="w-4 h-4" /> Expiring
                      </option>
                      <option value="emailDraft">
                        {' '}
                        <EnvelopeIcon className="w-4 h-4" /> Email Draft
                      </option>
                      <option value="emailAutoSend">
                        {' '}
                        <PaperAirplaneIcon className="w-4 h-4" /> Email
                        Auto-Send
                      </option>
                    </Select2>
                  </InputRightAddon>
                </InputGroup>
                <InputGroup>
                  <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                    Desc
                  </InputLeftAddon>
                  <Input
                    type="text"
                    value={data.desc}
                    onChange={(e) => setData({ ...data, desc: e.target.value })}
                    placeholder="Write a short description about this..."
                  />
                </InputGroup>
                <InputGroup zIndex={1000}>
    <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
      <UserGroupIcon className="w-4 h-4" />
    </InputLeftAddon>
    <div className="flex-1 z-1000">

                                <Select
  value={data?.assignedTo.value?.map((email) => {
    // Find the member corresponding to the email
    const member = state?.members?.data?.members?.find((el) => el.email === email);
    return {
      label: member ? member.fName + ' ' + member.lName : '',
      value: email,
    };
  })}
  placeholder="Search"
  onChange={(selectedOptions) => {
    setData({
      ...data,
      assignedTo: {
        ...data.assignedTo,
        value: selectedOptions.map(option => option.value), // Extract email values
      },
    });
  }}
  isMulti // To allow multiple selections
  options={state?.members?.data?.members?.map((el) => ({
    label: el.fName + ' ' + el.lName,
    value: el.email,
  }))}
  styles={{
    control: (base) => ({
      ...base,
      border: "1px solid #CBD5E0", // Chakra gray-300 equivalent
      borderRadius: "0.375rem", // Chakra borderRadius 'md'
      minHeight: "2.5rem", // Adjust height
      boxShadow: "none",
      zIndex: "1000",
      borderLeft: "0"
    }),
  }}
/>
</div>

              </InputGroup>
                <InputGroup zIndex={10}>
                  <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                    Start
                  </InputLeftAddon>
                  <Input
                    placeholder="Select Date and Time"
                    size="md"
                    type="date"
                    zIndex={10}
                    value={data.start.date}
                    onChange={(e) =>
                      setData({
                        ...data,
                        start: { ...data.start, date: e.target.value },
                      })
                    }
                  />
                </InputGroup>
                <InputGroup zIndex={10}>
                  <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                    Finish
                  </InputLeftAddon>
                  <Input
                    placeholder="Select Date and Time"
                    size="md"
                    type="date"
                    zIndex={10}
                    value={data.deadline.value}
                    onChange={(e) =>
                      setData({
                        ...data,
                        deadline: { ...data.deadline, date: e.target.value },
                      })
                    }
                  />
                </InputGroup>

                {/* Advance Settings */}
                <Accordion className="w-full" allowToggle>
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box
                          as="span"
                          flex="1"
                          textAlign="right"
                          className="mr-2"
                        >
                          Advance Settings
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel className="space-y-4 w-full" pb={4}>
                      <RichTextEditor
                        value={data.html}
                        onChange={(html) =>
                          setData({ ...data, html: html })
                        }
                      />
                      <div>
                        <Text className="flex font-bold text-lg mt-8 mb-2">
                          <PaperClipIcon className="w-6 h-6" />
                          Link this task to
                        </Text>
                        <Select2
                          value={data.linkTo[0]}
                          onChange={(e) => {
                            // Assuming index is the index of the value you want to edit
                            const updatedLinkTo = [...data.linkTo]
                            updatedLinkTo[0] = e.target.value // Update the second value
                            setData({ ...data, linkTo: updatedLinkTo })
                          }}
                          placeholder="Select option"
                          className="w-full"
                        >
                          <option value="process">
                            <DocumentCheckIcon className="w-4 h-4" />{' '}
                            <div>
                              <h1>Process - </h1>
                              <p>
                                Insert an ad-hoc task into an active process.
                              </p>
                            </div>
                          </option>

                          <option value="template">
                            {' '}
                            <InboxStackIcon className="w-4 h-4" />{' '}
                            <div>
                              <h1>Templates - </h1>
                              <p>
                                Link a standalone task to a template or template
                                step.
                              </p>
                            </div>
                          </option>
                        </Select2>
                        {data.linkTo[0] ? (
                          <Select2
                            value={data.linkTo[1]}
                            onChange={(e) => {
                              // Assuming index is the index of the value you want to edit
                              const updatedLinkTo = [...data.linkTo]
                              updatedLinkTo[1] = e.target.value // Update the second value
                              setData({ ...data, linkTo: updatedLinkTo })
                            }}
                            placeholder="Search for active processes"
                            className="w-full"
                          >
                            <option value="process">option 1</option>

                            <option value="template">option 2</option>
                          </Select2>
                        ) : null}
                        <div className="p-2 border my-4">
                          <Accordion allowToggle>
                            <AccordionItem>
                              <h2>
                                <AccordionButton>
                                  <Text className="flex font-bold text-lg mt-4 mb-2">
                                    <DocumentDuplicateIcon className="w-6 h-6" />
                                    Forms
                                  </Text>
                                  <AccordionIcon />
                                </AccordionButton>
                              </h2>
                              <AccordionPanel pb={4}>
                                <FormBuilder
                                  formData={data.forms}
                                  data={data}
                                  setData={setData}
                                />
                              </AccordionPanel>
                            </AccordionItem>
                          </Accordion>
                        </div>
                        <input
                          type="text"
                          placeholder="Tags"
                          onChange={(e) => setTagSearch(e.target.value)}
                          value={tagSearch}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              if (!data.tags.includes(tagSearch)) {
                                setData({
                                  ...data,
                                  tags: [...data.tags, tagSearch],
                                })
                              }
                              setTagSearch('')
                            }
                          }}
                        />
                        <div className="flex flex-row space-x-2 mt-2">
                          {data.tags.map((title, i) => (
                            <Tag
                              size={'md'}
                              key={i}
                              variant="solid"
                              colorScheme="teal"
                              className="w-fit"
                            >
                              {title}
                              <button
                                onClick={() => {
                                  const updatedTags = data.tags
                                  updatedTags.splice(i, 1)
                                  setData({ ...data, tags: updatedTags })
                                }}
                              >
                                <TagCloseButton />
                              </button>
                            </Tag>
                          ))}
                        </div>
                      </div>
                      {/* <FormControl
                        className="flex flex-col space-y-4 py-4"
                        alignItems="left"
                      >
                        <div
                          onClick={(e) =>
                            setData({
                              ...data,
                              allAssigneesMustComplete: e.target.checked,
                            })
                          }
                          className="flex justify-between"
                        >
                          <FormLabel htmlFor="1" mb="0">
                            All assignees must complete this task
                          </FormLabel>
                          <Switch
                            id="1"
                            isChecked={data.allAssigneesMustComplete}
                          />
                        </div>

                        <div
                          onClick={(e) =>
                            setData({
                              ...data,
                              enforceMaxAssignees: e.target.checked,
                            })
                          }
                          className="flex justify-between"
                        >
                          <FormLabel htmlFor="2" mb="0">
                            Enforce a maximum number of assignees?
                          </FormLabel>
                          <Switch id="2" isChecked={data.enforceMaxAssignees} />
                        </div>
                        <Input
                          hidden={!data.enforceMaxAssignees}
                          className="my-2"
                          placeholder="Max No. of Assignees"
                          value={data.maxAssignees}
                          onChange={(e) => {
                            setData({ ...data, maxAssignees: e.target.value })
                          }}
                        />

                        <div
                          onClick={(e) =>
                            setData({
                              ...data,
                              preventGuestComments: e.target.checked,
                            })
                          }
                          className="flex justify-between"
                        >
                          <FormLabel htmlFor="3" mb="0">
                            Prevent guests from making or seeing any comments?
                          </FormLabel>
                          <Switch
                            id="3"
                            isChecked={data.preventGuestComments}
                          />
                        </div>

                        <div
                          onClick={(e) =>
                            setData({
                              ...data,
                              emitWebhookOnCompletion: e.target.checked,
                            })
                          }
                          className="flex justify-between"
                        >
                          <FormLabel htmlFor="4" mb="0">
                            Emit a webhook when this task is completed?
                          </FormLabel>
                          <Switch
                            id="4"
                            isChecked={data.emitWebhookOnCompletion}
                          />
                        </div>
                        <Input
                          className="my-2"
                          hidden={!data.emitWebhookOnCompletion}
                          placeholder="Target URL"
                          value={data.targetUrl}
                          onChange={(e) =>
                            setData({ ...data, targetUrl: e.target.value })
                          }
                        />
                      </FormControl> */}
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
            <div hidden={isEdit != 'activity'} className="border p-4 ">
              <div>
                <Button
                  className="mb-4"
                  onClick={() => {
                    setIsEdit('none')
                  }}
                >
                  Back
                </Button>
                {/* Group activities by date */}
                {allActivities?.map((group, index) => (
                  <div key={index}>
                    {/* Heading */}
                    <div className="ps-2 my-2 first:mt-0">
                      <h3 className="text-xs font-medium uppercase text-gray-500">
                        {/* Format group date */}
                        {new Date(group.date).toLocaleDateString('en-US', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}
                      </h3>
                    </div>
                    {/* End Heading */}
                    {/* Render activities for the current date */}
                    {group.activities.map((activity, index) => (
                      <div key={index} className="flex gap-x-3">
                        {/* Icon */}
                        <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                          <div className="relative z-10 size-7 flex justify-center items-center">
                            <div className="size-2 rounded-full bg-gray-400" />
                          </div>
                        </div>
                        {/* End Icon */}
                        {/* Right Content */}
                        <div className="grow pt-0.5 pb-8">
                          <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                            {/* Render activity message */}
                            {activity.message}
                          </h3>
                          {/* Render additional details */}
                          {activity.linkedTask && (
                            <p className="mt-1 text-sm text-gray-600">
                              Linked Task: {activity.taskName}
                            </p>
                          )}
                          {/* Render user */}
                          <button
                            type="button"
                            className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
                          >
                            <Avatar size="xs" alt="User Avatar" />
                            {activity.createdBy}{' '}
                            {/* Assuming name is present in createdBy object */}
                          </button>
                        </div>
                        {/* End Right Content */}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={() => setSelectedRow(null)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <FilterSidebar
        filterSidebarIsOpen={filterSidebarIsOpen}
        filterSidebarOnOpen={filterSidebarOnOpen}
        filterSidebarOnClose={filterSidebarOnClose}
        applySidebarFilters={handleFilterChange}
        selectedFilters={filters}
        setSelectedFilters={setFilters}
        tempFolderName={tempFolderName}
        setTempFolderName={setTempFolderName}
        folderList={folderList}
        setFolderList={setFolderList}
        filteredFolderList={filteredFolderList}
        setFilteredFolderList={setFilteredFolderList}
      />
    </div>
  )
}

export default Tasks
