import Cookies from 'js-cookie'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const fetchTasks = createAsyncThunk('fetchTasks', async () => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/tasks`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('token')}`,
    },
  })
  return res?.json()
})

export const fetchAllTasks = createAsyncThunk('fetchAllTasks', async () => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/tasks/all`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('token')}`,
    },
  })
  return res?.json()
})

// Define an action to add a new task to the Redux store
export const addTask = createAsyncThunk('addTask', async (taskData) => {
  const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/tasks`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Cookies.get('token')}`,
    },
    body: JSON.stringify(taskData),
  })
  const response = await res.json()
  console.log(response)
  return response
})

export const updateTask = createAsyncThunk('updateTask', async (taskData) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/tasks/${taskData._id}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
      body: JSON.stringify(taskData),
    },
  )
  return res.json()
})

export const addComment = createAsyncThunk('addComment', async (data) => {
  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/api/tasks/${data._id}/comments`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
      body: JSON.stringify({ text: data.text }),
    },
  )
  return res.json()
})

const tasksSlice = createSlice({
  name: 'tasks',
  initialState: {
    isLoading: false,
    data: [],
    companyData: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTasks.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchTasks.fulfilled, (state, action) => {
      state.isLoading = false
      state.data = action.payload
    })
    builder.addCase(fetchTasks.rejected, (state, action) => {
      state.isError = true
    })
    builder.addCase(fetchAllTasks.pending, (state, action) => {
      state.isLoading = true
    })
    builder.addCase(fetchAllTasks.fulfilled, (state, action) => {
      state.isLoading = false
      state.companyData = action.payload
    })
    builder.addCase(fetchAllTasks.rejected, (state, action) => {
      state.isError = true
    })
    builder.addCase(addTask.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(addTask.fulfilled, (state, action) => {
      state.isLoading = false
      state.data.push(action.payload.task) // Add the new task to the state
    })
    builder.addCase(addTask.rejected, (state) => {
      state.isError = true
    })
    builder.addCase(updateTask.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(updateTask.fulfilled, (state, action) => {
      state.isLoading = false
      console.log(action.payload)
      const updatedTaskIndex = state.data.findIndex(
        (task) => task._id === action.payload._id,
      )
      if (updatedTaskIndex !== -1) {
        state.data[updatedTaskIndex] = action.payload // Update the task in the state
      }
    })
    builder.addCase(updateTask.rejected, (state) => {
      state.isError = true
    })
    builder.addCase(addComment.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(addComment.fulfilled, (state, action) => {
      state.isLoading = false
      console.log(action.payload.task, 'action.payload')
      console.log(state.data, 'state.data')
      const updatedTaskIndex = state.data.findIndex(
        (task) => task._id === action.payload.taskId,
      )
      if (updatedTaskIndex !== -1) {
        state.data[updatedTaskIndex] = action.payload.task // Insert comment at the beginning of the array
        console.log("FORM REUX", state.data[updatedTaskIndex])
      }
    })
    builder.addCase(addComment.rejected, (state) => {
      state.isError = true
    })
  },
})

export default tasksSlice.reducer
