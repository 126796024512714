import React, { useState, useRef, useEffect } from 'react'
import Header from '../../../components/Header'
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  CloseButton,
  FormLabel,
  Heading,
  Input,
  Switch,
  Text,
  useDisclosure,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Tag,
  TagCloseButton,
  useToast,
  Progress,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  TagLabel,
  FormControl,
} from '@chakra-ui/react'
import { Select as Select2 } from '@chakra-ui/react';
import Select from "react-select";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react'
import {
  Cog6ToothIcon,
  ChevronRightIcon,
  ClipboardIcon,
  XMarkIcon,
  CheckCircleIcon,
  AdjustmentsHorizontalIcon,
  ClockIcon,
  EnvelopeIcon,
  PaperAirplaneIcon,
  ChatBubbleBottomCenterIcon,
  EllipsisHorizontalIcon,
  LinkIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  PlusIcon,
  UserGroupIcon,
  PaperClipIcon,
  DocumentCheckIcon,
  InboxStackIcon,
  DocumentDuplicateIcon,
} from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import FormBuilder from '../../../components/utils/FormBuilder'
import TemplateLaunch from '../../../components/TemplateLaunch'
import Editor from 'react-simple-wysiwyg'
import DrawerExample from './ConfigDrawer'
import { MultiSelect } from 'chakra-multiselect'
import FocusLock from 'react-focus-lock'
import {
  openInviteMemberModal,
  closeInviteMemberModal,
} from '../../../redux/InviteMemberModal'
import {
  openCreateGroupModal,
  closeCreateGroupModal,
} from '../../../redux/CreateGroupsModal'
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Textarea,
} from '@chakra-ui/react'
import AddAutomations from './AddAutomations'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import { useMediaQuery } from 'react-responsive'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import CreateGroups from '../../../components/CreateGroups'

import {
  getLaunchedTemplate,
  updateLaunchTemplateWithoutPull,
  addComment,
  updateSteps,
} from '../../../redux/LaunchedTemplateSlice'
import TrackerStep from '../../../components/TrackerStep'
import { updateTask } from '../../../redux/TaskSlice'

import Cookies from 'js-cookie'
import moment from 'moment-timezone';
import { format } from 'date-fns';
import FormRender from '../../../components/utils/FormRender'
import RichTextEditor from '../../../components/utils/RichTextEditor'

function getFormattedDate (utcDate) {
  if(!utcDate) return 
  const date = moment.tz(utcDate, Cookies.get('timeZone'));
  return format(new Date(date.format()), Cookies.get('dateFormat'));
};

function KickOffFormComponent({ kickOffForm }) {
  // Function to render text with placeholders
  const renderText = (text) => {
    // Regular expression to find double curly braces
    const regex = /{{(.*?)}}/gm

    // Replace placeholders with actual data values
    const result = text?.replace(regex, (_, key) => {
      // Access the nested 'value' field dynamically
      return kickOffForm.forms[key]?.value || key
    })

    return result
  }

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">
          Assign Someone Else:
        </label>
        <span className="block text-gray-900">
          {kickOffForm?.assignSomeoneElse ? 'Yes' : 'No'}
        </span>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Assign To:</label>
        <span className="block text-gray-900">
          {kickOffForm?.assignTo.length > 0
            ? kickOffForm?.assignTo.join(', ')
            : 'None'}
        </span>
      </div>
      {/* <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Guests:</label>
        <span className="block text-gray-900">
          {kickOffForm.guests.length > 0
            ? kickOffForm.guests.join(', ')
            : 'None'}
        </span>
      </div> */}
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Forms:</label>
        <ul className="list-disc list-inside">
          {kickOffForm && kickOffForm.forms
            ? Object.entries(kickOffForm.forms).map(([key, form]) => (
                <li key={key} className="text-gray-900">
                  <strong>{key}</strong>: {form.value} (Required:{' '}
                  {form.required ? 'Yes' : 'No'})
                </li>
              ))
            : null}
        </ul>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">Name:</label>
        <span className="block text-gray-900">
          {renderText(kickOffForm.name)}
        </span>
      </div>
      <div className="mb-4">
        <label className="block text-gray-700 font-bold mb-2">
          Description:
        </label>
        <span className="block text-gray-900">
          {renderText(kickOffForm.desc)}
        </span>
      </div>
    </div>
  )
}

function TrackerView() {
  const { id } = useParams()
  const navigate = useNavigate()
  if (!id) {
    navigate('/dashboard/tracker')
  }
  const dispatch = useDispatch()
  const toast = useToast()

  const [html, setHtml] = useState('my <b>HTML</b>')
  const [trigger, setTrigger] = useState(null)
  const [step, setStep] = useState(null)
  const [assign, setAssign] = useState('fixed')
  const [isEdit, setIsEdit] = useState('none')

  const [milestoneName, setMilestoneName] = useState('')
  const [selectedMilestone, setSelectedMilestone] = useState('')

  const [startOnSpecificTime, setStartOnSpecificTime] = useState(false)

  const [taskType, setTaskType] = useState('task')

  const [value, setValue] = useState([])
  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    // Add more options as needed
  ]


  const [tags, setTags] = useState(['foo', 'bar'])
  const [tagSearch, setTagSearch] = useState('')

  const { isOpen, onOpen, onClose } = useDisclosure()

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1024px)' })

  const [data, setData] = useState()

  const state = useSelector((state) => state)
  console.log(state)

  const [steps, setSteps] = useState([])
  const [milestones, setMilestones] = useState([])

  const [stepData, setStepData] = useState({})

  const [disabled, setDisabled] = useState(false)

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault() // Prevent form submission
      if (tagSearch.trim() !== '') {
        setStepData({ ...stepData, tags: [...stepData.tags, tagSearch.trim()] })
        setTagSearch('') // Clear input field after adding tag
      }
    }
  }

  const removeTag = (index) => {
    const newTags = [...data.tags]
    newTags.splice(index, 1)
    setStepData({ ...data, tags: newTags })
  }

  const submitUpdateTask = async (e) => {
    e.preventDefault()

    console.log(stepData)

    if (stepData.name.length > 1) {
      setDisabled(true)
      dispatch(updateTask(stepData)) // Dispatch the addTask action with the new task data
        .then(() => {
          toast({
            title: 'Task Updated!',
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
        })
        .catch((error) => {
          toast({
            title: error.message || 'Something went wrong!',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        })
        .finally(() => {
          setDisabled(false)
        })
    } else {
      toast({
        title: 'Invalid task data!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  const submitMarkComplete = async (e, status) => {
    e.preventDefault()
    await setStepData({ ...stepData, status })

    // await dispatch(updateTask({ ...stepData, status })) // Dispatch the addTask action with the new task data
    //   .then(() => {
    //     toast({
    //       title: 'Task Updated!',
    //       status: 'success',
    //       duration: 9000,
    //       isClosable: true,
    //     })
    //   })
    //   .catch((error) => {
    //     toast({
    //       title: error.message || 'Something went wrong!',
    //       status: 'error',
    //       duration: 9000,
    //       isClosable: true,
    //     })
    //   })
    //   .finally(() => {
    //     setDisabled(false)
    //   })
  }

  const handleAddComment = async (e, text) => {
    e.preventDefault()

    if (text.length > 1) {
      console.log(text)
      dispatch(addComment({ stepId: stepData._id, text: text })) // Dispatch the addTask action with the new task data
        .then(() => {
          toast({
            title: 'Comment Updated!',
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
        })
        .catch((error) => {
          toast({
            title: error.message || 'Something went wrong!',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        })
    } else {
      toast({
        title: 'Invalid comment data!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    dispatch(getLaunchedTemplate())
  }, [dispatch, addComment])

  useEffect(() => {
    if (stepData && stepData._id) {
      const temp = steps.map((el, i) => {
        if (el._id == stepData._id) {
          return stepData
        } else {
          return el
        }
      })

      dispatch(updateSteps(temp))

      setSteps(temp)
    }
  }, [stepData])

  const [completedSteps, setCompletedSteps] = useState(0)

  useEffect(() => {
    if (
      state &&
      state.launchedTemplates &&
      state.launchedTemplates.data &&
      state.launchedTemplates.data.length > 0
    ) {
      setData(
        state?.launchedTemplates?.data?.filter((el, i) => el._id == id)[0],
      )

      setSteps(
        state?.launchedTemplates?.data?.filter((el, i) => el._id == id)[0]
          ?.steps,
      )
      const temp = state.launchedTemplates.data.filter(
        (el, i) => el._id == id,
      )[0]
      const temp2 =
        temp && temp.steps && temp.steps.length > 0 && stepData && stepData._id
          ? temp.steps.find((el, i) => el._id == stepData._id)
          : {}
      console.log(temp2, 'temp')
      setStepData(temp2)
    } else {
      setData([])
    }
  }, [state?.launchedTemplates?.data, addComment])

  useEffect(() => {
    if (data && data.steps && data.steps.length > 0) {
      setCompletedSteps(
        steps?.filter((step) => step.status != 'Pending').length,
      )
    }
  }, [data, steps])

  const [allActivities, setAllActivities] = useState([])
  useEffect(() => {
    if (stepData && stepData.activity && stepData.activity.length > 0) {
      let temp = []
      stepData?.activity?.forEach((activity) => {
        temp.push({ ...activity, taskName: stepData.name })
      })

      setAllActivities(groupActivitiesByDate(temp))
    } else {
      setAllActivities([])
    }
  }, [stepData])

  // Function to group activities by date
  function groupActivitiesByDate(activities) {
    const groupedActivities = activities.reduce((groups, activity) => {
      const date = new Date(activity?.createdAt?.split('T')).toLocaleDateString(
        'en-US',
      )
      if (!groups[date]) {
        groups[date] = { date, activities: [] }
      }
      groups[date].activities.push(activity)
      return groups
    }, {})
    return Object.values(groupedActivities)
  }

  const renderText = (text, data) => {
    console.log(text, data)
    // Regular expression to find double curly braces
    const regex = /{{(.*?)}}/gm

    // Replace placeholders with actual data values
    const result = text?.replace(regex, (_, key) => {
      // Access the nested 'value' field dynamically
      return data[key]?.value || key
    })

    return result
  }

  return (
    <div className="overflow-y-hidden max-h-screen">
      {' '}
      <Header />
      <div className="ml-2 lg:ml-40 mr-2 space-y-4 py-10 flex flex-row gap-x-4 max-h-[90vh] overflow-y-scroll">
        <div className="w-full mx-auto lg:p-6">
          <div className="w-full border-b border-gray-200 pb-4 mb-4">
            <div className="w-full flex justify-between items-center">
              <div className="w-full flex flex-col">
                <div className="w-full flex flex-col space-y-4 lg:flex-row">
                  {' '}
                  <div className="w-full flex-1">
                    <div className="mt-2 font-bold text-gray-600">
                      {renderText(data?.name, data?.kickOffForm?.forms)}
                    </div>
                    <Link className="text-blue-600" href="#">
                      Tracker
                    </Link>
                    <span className="mx-1 text-gray-500">{`>`}</span>
                    <Link className="text-blue-600" href="#">
                      {renderText(
                        data?.kickOffForm?.name,
                        data?.kickOffForm?.forms,
                      )}
                    </Link>

                    <div className="max-w-[30rem]">
                      <Progress
                        colorScheme={
                          completedSteps == data?.steps?.length
                            ? 'green'
                            : 'red'
                        }
                        size="sm"
                        value={(completedSteps / data?.steps?.length) * 100}
                        className="mt-4"
                      />
                      <div
                        className={`flex my-2 font-semibold text-${
                          completedSteps == data?.steps?.length
                            ? 'green'
                            : 'red'
                        }-500 text-lg`}
                      >
                        <h1 className="flex-grow flex-1">{data?.status}</h1>
                        <h1>
                          {completedSteps}/{data?.steps?.length}
                        </h1>
                      </div>
                    </div>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <Button
                      onClick={onOpen}
                      className="text-gray-600"
                      variant="ghost"
                      
                    >
                      <Cog6ToothIcon className="w-4 h-4 text-current" />
                    </Button>
                  </div>
                </div>

                <Tabs>
                  <TabList className="my-4">
                    <Tab>
                      <Button size="xs lg:md" variant="ghost">
                        Overview
                      </Button>
                    </Tab>
                    {/* <Tab>
                      <Button size="xs lg:md" variant="ghost">
                        Read
                      </Button>
                    </Tab>
                    <Tab>
                      <Button size="xs lg:md" variant="ghost">
                        Automations (0)
                      </Button>
                    </Tab>
                    <Tab>
                      <Button size="xs lg:md" variant="ghost">
                        Tools
                      </Button>
                    </Tab> */}
                  </TabList>

                  <TabPanels>
                    <TabPanel className="flex flex-row space-x-4 mb-20">
                      <div className="w-full lg:w-1/2 pb-20">
                        {' '}
                        <div className="grid grid-cols-1 gap-4">
                          <div className="border rounded-lg p-4 space-y-2">
                            <TrackerStep
                              _id={id}
                              setTrigger={setTrigger}
                              setStepData={setStepData}
                              steps={steps}
                              setSteps={setSteps}
                              milestones={milestones}
                              setMilestones={setMilestones}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="hidden lg:block lg:w-1/2 ">
                        {trigger == 'stepView' ? (
                          <>
                            <div
                              hidden={isEdit != 'none'}
                              className="border p-4 "
                            >
                              <div className="flex flex-row">
                                <h1 className="flex-grow flex-1 font-bold text-lg">
                                  {renderText(
                                    stepData?.name,
                                    data?.kickOffForm?.forms,
                                  )}
                                </h1>
                                <div className="flex flex-row space-x-2">
                                  {/* <Button>
                                    <LinkIcon className="w-4 h-4" />
                                  </Button> */}
                                  <Menu>
                                    <MenuButton as={Button}>
                                      <EllipsisVerticalIcon className="w-4 h-4" />
                                    </MenuButton>
                                    <MenuList>
                                      <MenuItem
                                        onClick={() => setIsEdit('edit')}
                                      >
                                        Edit
                                      </MenuItem>
                                      {/* <MenuItem
                                        onClick={() => setIsEdit('activity')}
                                      >
                                        Activity Logs
                                      </MenuItem>
                                      <MenuItem>Resend to Assignees</MenuItem>
                                      <MenuItem>Copy Link</MenuItem>
                                      <MenuItem>View Process</MenuItem>
                                      <MenuItem>Hide this Task</MenuItem>
                                      <MenuItem>Add to Folder(s)</MenuItem>
                                      <MenuItem>Duplicate</MenuItem> */}
                                    </MenuList>
                                  </Menu>
                                  <Button onClick={() => setTrigger(null)}>
                                    <XMarkIcon className="w-4 h-4" />
                                  </Button>
                                </div>
                              </div>
                              <div>
                                <h1 className="font-semibold">
                                  {' '}
                                  {stepData?.status}
                                </h1>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: renderText(
                                      stepData?.desc,
                                      data?.kickOffForm?.forms,
                                    ),
                                  }}
                                ></p>
                              </div>
                              <div className="my-4 space-x-2">
                                <Button
                                  onClick={(e) =>
                                    submitMarkComplete(
                                      e,
                                      stepData?.status == 'Pending'
                                        ? 'Completed'
                                        : 'Pending',
                                    )
                                  }
                                  colorScheme="green"
                                >
                                  {stepData?.status == 'Pending'
                                    ? 'Mark Completed'
                                    : 'Mark Pending'}
                                </Button>
                                {/* <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon className="w-4 h-4" />}
                >
                  Comment
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={(e) => setHtml('I am working on this')}>
                    I'm working on this
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Request status')}>
                    Request status
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Offer help')}>
                    Offer help
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Say thanks')}>
                    Say thanks
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Quick Tutorial')}>
                    Quick Tutorial
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Something else')}>
                    Something else
                  </MenuItem>
                </MenuList>
              </Menu> */}
                                <Button
                                hidden={Cookies.get('role') == "member"}
                                  onClick={(e) => submitUpdateTask(e)}
                                  colorScheme="green"
                                >
                                  Save Changes
                                </Button>
                              </div>
                              <div>
                                <div className="flex flex-row space-x-4 border-y-2 p-2">
                                  <h1 className="font-bold w-1/3">
                                    Milestones
                                  </h1>
                                  <div>
                                    <Select2
                                      id="status-toggle"
                                      placeholder="Milestone"
                                      className="w-full"
                                      disabled={Cookies.get('role') == "member"}
                                      onChange={(e) => {
                                        setStepData({
                                          ...stepData,
                                          milestone: e.target.value,
                                        })
                                      }}
                                      value={stepData?.milestone}
                                    >
                                      <option value="">None</option>
                                      {milestones.map((el, i) => (
                                        <option value={el}>{el}</option>
                                      ))}
                                    </Select2>
                                    <div className="flex flex-row space-x-4 mt-4">
                                      <Input
                                      hidden={Cookies.get('role') == "member"}
                                        onChange={(e) =>
                                          setMilestoneName(e.target.value)
                                        }
                                        placeholder="New milestone name"
                                        value={milestoneName}
                                      />
                                      <Button
                                      hidden={Cookies.get('role') == "member"}
                                        onClick={() => {
                                          // Find the step to be updated
                                          const stepToUpdate = steps.find(
                                            (step) => step._id === stepData._id,
                                          )
                                          // Create a new step object with the updated milestone
                                          const updatedStep = {
                                            ...stepToUpdate,
                                            milestone: milestoneName,
                                          }

                                          // Create a new array with the updated step
                                          const updatedSteps = steps.map(
                                            (step) =>
                                              step._id === stepData._id
                                                ? updatedStep
                                                : step,
                                          )

                                          // Update the state with the new array
                                          setSteps(updatedSteps)
                                          dispatch(updateSteps(updatedSteps))
                                          setMilestoneName('')
                                        }}
                                      >
                                        Add Milestone
                                      </Button>
                                    </div>
                                  </div>
                                </div>

                                <div className="flex flex-row space-x-4 p-2 items-center">
                                  <h1 className="font-bold w-1/3">Assigned</h1>

    <Select
      value={stepData?.assignedTo.value?.map((email) => {
        // Find the member corresponding to the email
        const member = state?.members?.data?.members?.find((el) => el.email === email);
        return {
          label: member ? member.fName + ' ' + member.lName : '',
          value: email,
        };
      })}
      placeholder="Search"
      onChange={(selectedOptions) => {
        setStepData({
          ...stepData,
          assignedTo: {
            ...stepData?.assignedTo,
            value: selectedOptions.map(option => option.value), // Extract email values
          },
        });
      }}
      isMulti // To allow multiple selections
      options={state?.members?.data?.members?.map((el) => ({
        label: el.fName + ' ' + el.lName,
        value: el.email,
      }))}
      styles={{
        control: (base) => ({
          ...base,
          border: "1px solid #CBD5E0", // Chakra gray-300 equivalent
          borderRadius: "0.375rem", // Chakra borderRadius 'md'
          minHeight: "2.5rem", // Adjust height
          boxShadow: "none",
          zIndex: "1000",
        }),
      }}
    />


                                </div>
                                <div className="flex flex-row space-x-4 border-y-2 p-2 items-center">
                                  <h1 className="font-bold w-1/3">Due</h1>
                                  <InputGroup className="w-2/3">
                                    <Input
                                      placeholder="Select Date and Time"
                                      size="md"
                                      type="date"
                                      value={stepData?.deadline.date}
                                      disabled={Cookies.get('role') == "member"}
                                      onChange={(e) =>
                                        setStepData({
                                          ...stepData,
                                          deadline: {
                                            ...stepData.deadline,
                                            date: e.target.value,
                                          },
                                        })
                                      }
                                    />
                                  </InputGroup>
                                </div>
                                <div className="my-2 p-4 border bg-gray-100 rounded-lg">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: stepData?.html,
                                    }}
                                  />

                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: renderText(
                                        stepData?.desc,
                                        data?.kickOffForm?.forms,
                                      ),
                                    }}
                                  ></p>
                                </div>
                                <Accordion allowToggle>
                                  <AccordionItem>
                                    <h2>
                                      <AccordionButton>
                                        <Text className="flex text-lg py-2 px-2">
                                          KickOff Form Details
                                        </Text>
                                        <AccordionIcon />
                                      </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                      <KickOffFormComponent
                                        kickOffForm={data?.kickOffForm}
                                      />
                                    </AccordionPanel>
                                  </AccordionItem>
                                  <AccordionItem>
                                    <h2>
                                      <AccordionButton>
                                        <Text className="flex text-lg py-2 px-2">
                                          Template Instructions
                                        </Text>
                                        <AccordionIcon />
                                      </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={2}>
                                      <div className="space-y-2 p-2">
                                        <p>
                                          {renderText(
                                            data?.desc,
                                            data?.kickOffForm?.forms,
                                          )}
                                        </p>{' '}
                                        <p>
                                          {renderText(
                                            data?.specialInstructions,
                                            data?.kickOffForm?.forms,
                                          )}
                                        </p>
                                      </div>
                                    </AccordionPanel>
                                  </AccordionItem>
                                </Accordion>

                                <div className="bg-gray-100 rounded-lg p-4">
                                  <Heading
                                    className="flex flex-row items-center pb-4"
                                    size={'md'}
                                  >
                                    <ChatBubbleBottomCenterIcon className="w-4 h-4 mr-4" />{' '}
                                    Comments{' '}
                                  </Heading>
                                  <div className="py-6 max-h-[20rem] overflow-y-scroll">
                                    {/* Comments */}
                                    {stepData?.comments?.map((el, i) => (
                                      <div className="p-2 border rounded-lg my-2 bg-gray-50 ">
                                        <Avatar size="sm" />
                                        <div className="flex-col flex mx-auto space-y-4">
                                          <div className="flex flex-row  justify-left space-x-4 items-center">
                                            <p
                                              dangerouslySetInnerHTML={{
                                                __html: renderText(
                                                  el?.text,
                                                  data?.kickOffForm?.forms,
                                                ),
                                              }}
                                            ></p>
                                          </div>
                                          <div className="text-sm flex flex-row space-between text-gray-400">
                                            {el.email}{' '}
                                            {getFormattedDate(el?.createdAt)}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="my-4 border bg-white p-4 space-y-2">
                                    <RichTextEditor value={html} onChange={(html) => setHtml(html)} />
                                    <Button
                                      onClick={(e) => handleAddComment(e, html)}
                                    >
                                      Add Comment
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              hidden={isEdit != 'edit'}
                              className="border p-4 "
                            >
                              <div className="flex flex-col mx-auto justify-center items-center space-y-4 w-full">
                                <div className="flex flex-row space-x-4">
                                  {' '}
                                  <Button
                                    onClick={() => {
                                      setIsEdit('none')
                                    }}
                                  >
                                    Discard
                                  </Button>
                                  <Button hidden={Cookies.get('role') == "member"} onClick={(e) => submitUpdateTask(e)}>
                                    Save
                                  </Button>
                                </div>
                                <InputGroup>
                                  <Input
                                    type="text"
                                    value={stepData.name}
                                    disabled={Cookies.get('role') == "member"}
                                    onChange={(e) =>
                                      setStepData({
                                        ...stepData,
                                        name: e.target.value,
                                      })
                                    }
                                    placeholder="Name this task"
                                  />
                                  <InputRightAddon className="p-0 m-0 w-[10rem]">
                                    <Select2
                                    disabled={Cookies.get('role') == "member"}
                                      value={data.taskType}
                                      onChange={(e) =>
                                        setData({
                                          ...data,
                                          taskType: e.target.value,
                                        })
                                      }
                                      placeholder="Select option"
                                      className="w-[10rem]"
                                    >
                                      <option value="task">
                                        {' '}
                                        <CheckCircleIcon className="w-4 h-4" />{' '}
                                        Task
                                      </option>
                                      <option value="approve/reject">
                                        {' '}
                                        <AdjustmentsHorizontalIcon className="w-4 h-4" />{' '}
                                        Approve/Reject
                                      </option>
                                      <option value="expiring">
                                        {' '}
                                        <ClockIcon className="w-4 h-4" />{' '}
                                        Expiring
                                      </option>
                                      <option value="emailDraft">
                                        {' '}
                                        <EnvelopeIcon className="w-4 h-4" />{' '}
                                        Email Draft
                                      </option>
                                      <option value="emailAutoSend">
                                        {' '}
                                        <PaperAirplaneIcon className="w-4 h-4" />{' '}
                                        Email Auto-Send
                                      </option>
                                    </Select2>
                                  </InputRightAddon>
                                </InputGroup>
                                <InputGroup>
                                  <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                                    Desc
                                  </InputLeftAddon>
                                  <Input
                                    type="text"
                                    disabled={Cookies.get('role') == "member"}
                                    value={stepData.desc}
                                    onChange={(e) =>
                                      setStepData({
                                        ...stepData,
                                        desc: e.target.value,
                                      })
                                    }
                                    placeholder="Write a short description about this..."
                                  />
                                </InputGroup>
                                <InputGroup zIndex={1000}>
  <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
    <UserGroupIcon className="w-4 h-4" />
  </InputLeftAddon>
  <div className="flex-1 z-1000">
    <Select
      value={stepData?.assignedTo.value?.map((email) => {
        // Find the member corresponding to the email
        const member = state?.members?.data?.members?.find((el) => el.email === email);
        return {
          label: member ? member.fName + ' ' + member.lName : '',
          value: email,
        };
      })}
      placeholder="Search"
      onChange={(selectedOptions) => {
        setStepData({
          ...stepData,
          assignedTo: {
            ...stepData?.assignedTo,
            value: selectedOptions.map(option => option.value), // Extract email values
          },
        });
      }}
      isMulti // To allow multiple selections
      options={state?.members?.data?.members?.map((el) => ({
        label: el.fName + ' ' + el.lName,
        value: el.email,
      }))}
      styles={{
        control: (base) => ({
          ...base,
          border: "1px solid #CBD5E0", // Chakra gray-300 equivalent
          borderRadius: "0.375rem", // Chakra borderRadius 'md'
          minHeight: "2.5rem", // Adjust height
          boxShadow: "none",
          zIndex: "1000",
          borderLeft: "0"
        }),
      }}
    />
  </div>
</InputGroup>

                                <InputGroup zIndex={10}>
                                  <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                                    Start
                                  </InputLeftAddon>
                                  <Input
                                    placeholder="Select Date and Time"
                                    size="md"
                                    type="date"
                                    zIndex={10}
                                    value={stepData.start.date}
                                    disabled={Cookies.get('role') == "member"}
                                    onChange={(e) =>
                                      setStepData({
                                        ...stepData,
                                        start: {
                                          ...stepData.start,
                                          date: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                </InputGroup>
                                <InputGroup zIndex={10}>
                                  <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                                    Finish
                                  </InputLeftAddon>
                                  <Input
                                    placeholder="Select Date and Time"
                                    size="md"
                                    type="date"
                                    zIndex={10}
                                    value={stepData.deadline.value}
                                    disabled={Cookies.get('role') == "member"}
                                    onChange={(e) =>
                                      setStepData({
                                        ...stepData,
                                        deadline: {
                                          ...stepData.deadline,
                                          date: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                </InputGroup>

                                {/* Advance Settings */}
                                <Accordion className="w-full" allowToggle>
                                  <AccordionItem>
                                    <h2>
                                      <AccordionButton>
                                        <Box
                                          as="span"
                                          flex="1"
                                          textAlign="right"
                                          className="mr-2"
                                        >
                                          Advance Settings
                                        </Box>
                                        <AccordionIcon />
                                      </AccordionButton>
                                    </h2>
                                    <AccordionPanel
                                      className="space-y-4 w-full"
                                      pb={4}
                                    >
                                      {Cookies.get('role') == "member" ? <div dangerouslySetInnerHTML={{ __html: data?.html }} />:<RichTextEditor
                                        value={data.html}
                                        onChange={(html) =>
                                          setStepData({
                                            ...stepData,
                                            html: html,
                                          })
                                        }
                                      />}
                                      <div>
                                        <Text className="flex font-bold text-lg mt-8 mb-2">
                                          <PaperClipIcon className="w-6 h-6" />
                                          Link this task to
                                        </Text>
                                        <Select2
                                          value={stepData.linkTo[0]}
                                          disabled={Cookies.get('role') == "member"}
                                          onChange={(e) => {
                                            // Assuming index is the index of the value you want to edit
                                            const updatedLinkTo = [
                                              ...stepData.linkTo,
                                            ]
                                            updatedLinkTo[0] = e.target.value // Update the second value
                                            setStepData({
                                              ...stepData,
                                              linkTo: updatedLinkTo,
                                            })
                                          }}
                                          placeholder="Select option"
                                          className="w-full"
                                        >
                                          <option value="process">
                                            <DocumentCheckIcon className="w-4 h-4" />{' '}
                                            <div>
                                              <h1>Process - </h1>
                                              <p>
                                                Insert an ad-hoc task into an
                                                active process.
                                              </p>
                                            </div>
                                          </option>

                                          <option value="template">
                                            {' '}
                                            <InboxStackIcon className="w-4 h-4" />{' '}
                                            <div>
                                              <h1>Templates - </h1>
                                              <p>
                                                Link a standalone task to a
                                                template or template step.
                                              </p>
                                            </div>
                                          </option>
                                        </Select2>
                                        {stepData.linkTo[0] ? (
                                          <Select2
                                            value={stepData.linkTo[1]}
                                            disabled={Cookies.get('role') == "member"}
                                            onChange={(e) => {
                                              // Assuming index is the index of the value you want to edit
                                              const updatedLinkTo = [
                                                ...stepData.linkTo,
                                              ]
                                              updatedLinkTo[1] = e.target.value // Update the second value
                                              setStepData({
                                                ...stepData,
                                                linkTo: updatedLinkTo,
                                              })
                                            }}
                                            placeholder="Search for active processes"
                                            className="w-full"
                                          >
                                            <option value="process">
                                              option 1
                                            </option>

                                            <option value="template">
                                              option 2
                                            </option>
                                          </Select2>
                                        ) : null}
                                        <div className="p-2 border my-4">
                                          <Accordion allowToggle>
                                            <AccordionItem>
                                              <h2>
                                                <AccordionButton>
                                                  <Text className="flex font-bold text-lg mt-4 mb-2">
                                                    <DocumentDuplicateIcon className="w-6 h-6" />
                                                    Forms
                                                  </Text>
                                                  <AccordionIcon />
                                                </AccordionButton>
                                              </h2>
                                              <AccordionPanel pb={4}>
                                              {Cookies.get('role') == "member" ? <FormRender
                                    formData={stepData.forms}
                                    formResponses={stepData}
                                    setFormResponses={setStepData}
                                  /> : <FormBuilder
                                                  formData={stepData.forms}
                                                  data={stepData}
                                                  setData={setStepData}
                                                />}
                                              </AccordionPanel>
                                            </AccordionItem>
                                          </Accordion>
                                        </div>
                                        <input
                                          type="text"
                                          placeholder="Tags"
                                          onChange={(e) =>
                                            setTagSearch(e.target.value)
                                          }
                                          disabled={Cookies.get('role') == "member"}
                                          value={tagSearch}
                                          onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                              if (
                                                !stepData.tags.includes(
                                                  tagSearch,
                                                )
                                              ) {
                                                setStepData({
                                                  ...stepData,
                                                  tags: [
                                                    ...stepData.tags,
                                                    tagSearch,
                                                  ],
                                                })
                                              }
                                              setTagSearch('')
                                            }
                                          }}
                                        />
                                        <div className="flex flex-row space-x-2 mt-2">
                                          {stepData.tags.map((title, i) => (
                                            <Tag
                                              size={'md'}
                                              key={i}
                                              variant="solid"
                                              colorScheme="teal"
                                              className="w-fit"
                                            >
                                              {title}
                                              <button
                                              disabled={Cookies.get('role') == "member"}
                                                onClick={() => {
                                                  const updatedTags =
                                                    stepData.tags
                                                  updatedTags.splice(i, 1)
                                                  setStepData({
                                                    ...stepData,
                                                    tags: updatedTags,
                                                  })
                                                }}
                                              >
                                                <TagCloseButton />
                                              </button>
                                            </Tag>
                                          ))}
                                        </div>
                                      </div>
                                      <FormControl
                                        className="flex flex-col space-y-4 py-4"
                                        alignItems="left"
                                      >
                                        {/* <div
                                        disabled={Cookies.get('role') == "member"}
                                          onClick={(e) =>
                                            setStepData({
                                              ...stepData,
                                              allAssigneesMustComplete:
                                                e.target.checked,
                                            })
                                          }
                                          className="flex justify-between"
                                        >
                                          <FormLabel htmlFor="1" mb="0">
                                            All assignees must complete this
                                            task
                                          </FormLabel>
                                          <Switch
                                            id="1"
                                            isChecked={
                                              stepData.allAssigneesMustComplete
                                            }
                                          />
                                        </div> */}

                                        {/* <div
                                        disabled={Cookies.get('role') == "member"}
                                          onClick={(e) =>
                                            stepData({
                                              ...stepData,
                                              enforceMaxAssignees:
                                                e.target.checked,
                                            })
                                          }
                                          className="flex justify-between"
                                        >
                                          <FormLabel htmlFor="2" mb="0">
                                            Enforce a maximum number of
                                            assignees?
                                          </FormLabel>
                                          <Switch
                                            id="2"
                                            isChecked={
                                              stepData.enforceMaxAssignees
                                            }
                                          />
                                        </div> */}
                                        <Input
                                        disabled={Cookies.get('role') == "member"}
                                          hidden={!stepData.enforceMaxAssignees}
                                          className="my-2"
                                          placeholder="Max No. of Assignees"
                                          value={stepData.maxAssignees}
                                          onChange={(e) => {
                                            setStepData({
                                              ...stepData,
                                              maxAssignees: e.target.value,
                                            })
                                          }}
                                        />

                                        {/* <div
                                          onClick={(e) =>
                                            setStepData({
                                              ...stepData,
                                              preventGuestComments:
                                                e.target.checked,
                                            })
                                          }
                                          className="flex justify-between"
                                        >
                                          <FormLabel htmlFor="3" mb="0">
                                            Prevent guests from making or seeing
                                            any comments?
                                          </FormLabel>
                                          <Switch
                                            id="3"
                                            isChecked={
                                              stepData.preventGuestComments
                                            }
                                          />
                                        </div> */}

                                        <div
                                        disabled={Cookies.get('role') == "member"}
                                          onClick={(e) =>
                                            setStepData({
                                              ...stepData,
                                              emitWebhookOnCompletion:
                                                e.target.checked,
                                            })
                                          }
                                          className="flex justify-between"
                                        >
                                          <FormLabel htmlFor="4" mb="0">
                                            Emit a webhook when this task is
                                            completed?
                                          </FormLabel>
                                          <Switch
                                            id="4"
                                            isChecked={
                                              stepData.emitWebhookOnCompletion
                                            }
                                          />
                                        </div>
                                        <Input
                                          className="my-2"
                                          hidden={
                                            !stepData.emitWebhookOnCompletion
                                          }
                                          placeholder="Target URL"
                                          value={stepData.targetUrl}
                                          disabled={Cookies.get('role') == "member"}
                                          onChange={(e) =>
                                            setStepData({
                                              ...stepData,
                                              targetUrl: e.target.value,
                                            })
                                          }
                                        />
                                      </FormControl>
                                    </AccordionPanel>
                                  </AccordionItem>
                                </Accordion>
                              </div>
                            </div>
                            <div
                              hidden={isEdit != 'activity'}
                              className="border p-4 "
                            >
                              <div>
                                <Button
                                  className="mb-4"
                                  onClick={() => {
                                    setIsEdit('none')
                                  }}
                                >
                                  Back
                                </Button>
                                {/* Group activities by date */}
                                {allActivities?.map((group, index) => (
                                  <div key={index}>
                                    {/* Heading */}
                                    <div className="ps-2 my-2 first:mt-0">
                                      <h3 className="text-xs font-medium uppercase text-gray-500">
                                        {/* Format group date */}
                                        {new Date(
                                          group.date,
                                        ).toLocaleDateString('en-US', {
                                          weekday: 'long',
                                          year: 'numeric',
                                          month: 'long',
                                          day: 'numeric',
                                        })}
                                      </h3>
                                    </div>
                                    {/* End Heading */}
                                    {/* Render activities for the current date */}
                                    {group.activities.map((activity, index) => (
                                      <div key={index} className="flex gap-x-3">
                                        {/* Icon */}
                                        <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                                          <div className="relative z-10 size-7 flex justify-center items-center">
                                            <div className="size-2 rounded-full bg-gray-400" />
                                          </div>
                                        </div>
                                        {/* End Icon */}
                                        {/* Right Content */}
                                        <div className="grow pt-0.5 pb-8">
                                          <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                                            {/* Render activity message */}
                                            {activity.message}
                                          </h3>
                                          {/* Render additional details */}
                                          {activity.linkedTask && (
                                            <p className="mt-1 text-sm text-gray-600">
                                              Linked Task: {activity.taskName}
                                            </p>
                                          )}
                                          {/* Render user */}
                                          <button
                                            type="button"
                                            className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
                                          >
                                            <Avatar
                                              size="xs"
                                              alt="User Avatar"
                                            />
                                            {activity.createdBy}{' '}
                                            {/* Assuming name is present in createdBy object */}
                                          </button>
                                        </div>
                                        {/* End Right Content */}
                                      </div>
                                    ))}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </>
                        ) : null}
                      </div>
                      <Modal
                        isOpen={
                          isSmallScreen &&
                          (trigger == 'kickOff' ||
                            trigger == 'other' ||
                            trigger == 'stepView' ||
                            (trigger == null && step))
                        }
                        onClose={() => {
                          setTrigger(null)
                          setStep(null)
                        }}
                      >
                        <ModalOverlay className="block lg:hidden" />
                        <ModalContent className="block lg:hidden">
                          <ModalBody>
                            <div className="py-4">
                              {trigger == 'stepView' ? (
                                <>
                                  <div
                                    hidden={isEdit != 'none'}
                                    className="border p-4 "
                                  >
                                    <div className="flex flex-row">
                                      <h1 className="flex-grow flex-1 font-bold text-lg">
                                        {stepData?.name}
                                      </h1>
                                      <div className="flex flex-row space-x-2">
                                        {/* <Button>
                                          <LinkIcon className="w-4 h-4" />
                                        </Button> */}
                                        <Menu>
                                          <MenuButton as={Button}>
                                            <EllipsisVerticalIcon className="w-4 h-4" />
                                          </MenuButton>
                                          <MenuList>
                                            <MenuItem
                                              onClick={() => setIsEdit('edit')}
                                            >
                                              Edit
                                            </MenuItem>
                                            <MenuItem
                                              onClick={() =>
                                                setIsEdit('activity')
                                              }
                                            >
                                              Activity Logs
                                            </MenuItem>
                                            {/* <MenuItem>
                                              Resend to Assignees
                                            </MenuItem>
                                            <MenuItem>Copy Link</MenuItem>
                                            <MenuItem>View Process</MenuItem>
                                            <MenuItem>Hide this Task</MenuItem>
                                            <MenuItem>
                                              Add to Folder(s)
                                            </MenuItem>
                                            <MenuItem>Duplicate</MenuItem> */}
                                          </MenuList>
                                        </Menu>
                                        <Button
                                          onClick={() => setTrigger(null)}
                                        >
                                          <XMarkIcon className="w-4 h-4" />
                                        </Button>
                                      </div>
                                    </div>
                                    <div>
                                      <h1 className="font-semibold">
                                        {' '}
                                        {stepData?.status}
                                      </h1>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: renderText(
                                            stepData?.desc,
                                            data?.kickOffForm?.forms,
                                          ),
                                        }}
                                      ></p>
                                    </div>
                                    <div className="my-4 space-x-2">
                                      <Button
                                        onClick={(e) =>
                                          submitMarkComplete(
                                            e,
                                            stepData?.status == 'Pending'
                                              ? 'Completed'
                                              : 'Pending',
                                          )
                                        }
                                        colorScheme="green"
                                      >
                                        {stepData?.status == 'Pending'
                                          ? 'Mark Completed'
                                          : 'Mark Pending'}
                                      </Button>
                                      {/* <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon className="w-4 h-4" />}
                >
                  Comment
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={(e) => setHtml('I am working on this')}>
                    I'm working on this
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Request status')}>
                    Request status
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Offer help')}>
                    Offer help
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Say thanks')}>
                    Say thanks
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Quick Tutorial')}>
                    Quick Tutorial
                  </MenuItem>
                  <MenuItem onClick={(e) => setHtml('Something else')}>
                    Something else
                  </MenuItem>
                </MenuList>
              </Menu> */}
                                      <Button
                                      hidden={Cookies.get('role') == "member"}
                                        onClick={(e) => submitUpdateTask(e)}
                                        colorScheme="green"
                                      >
                                        Save Changes
                                      </Button>
                                    </div>
                                    <div>
                                      <div className="flex flex-row space-x-4 border-y-2 p-2">
                                        <h1 className="font-bold w-1/3">
                                          Milestones
                                        </h1>
                                        <div>
                                          <Select2
                                            id="status-toggle"
                                            placeholder="Milestone"
                                            className="w-full"
                                            disabled={Cookies.get('role') == "member"}
                                            onChange={(e) => {
                                              setStepData({
                                                ...stepData,
                                                milestone: e.target.value,
                                              })
                                            }}
                                            value={stepData?.milestone}
                                          >
                                            <option value="">None</option>
                                            {milestones.map((el, i) => (
                                              <option value={el}>{el}</option>
                                            ))}
                                          </Select2>
                                          <div className="flex flex-row space-x-4 mt-4">
                                            <Input
                                            hidden={Cookies.get('role') == "member"}
                                              onChange={(e) =>
                                                setMilestoneName(e.target.value)
                                              }
                                              placeholder="New milestone name"
                                              value={milestoneName}
                                            />
                                            <Button
                                            hidden={Cookies.get('role') == "member"}
                                              onClick={() => {
                                                // Find the step to be updated
                                                const stepToUpdate = steps.find(
                                                  (step) =>
                                                    step._id === stepData._id,
                                                )
                                                // Create a new step object with the updated milestone
                                                const updatedStep = {
                                                  ...stepToUpdate,
                                                  milestone: milestoneName,
                                                }

                                                // Create a new array with the updated step
                                                const updatedSteps = steps.map(
                                                  (step) =>
                                                    step._id === stepData._id
                                                      ? updatedStep
                                                      : step,
                                                )

                                                // Update the state with the new array
                                                setSteps(updatedSteps)
                                                dispatch(
                                                  updateSteps(updatedSteps),
                                                )
                                                setMilestoneName('')
                                              }}
                                            >
                                              Add Milestone
                                            </Button>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="flex flex-row space-x-4 p-2 items-center">
                                        <h1 className="font-bold w-1/3">
                                          Assigned
                                        </h1>
                                        <InputGroup zIndex={1000}>
  <div className="flex-1 z-1000">
    <Select
      value={stepData?.assignedTo.value?.map((email) => {
        // Find the member corresponding to the email
        const member = state?.members?.data?.members?.find((el) => el.email === email);
        return {
          label: member ? member.fName + ' ' + member.lName : '',
          value: email,
        };
      })}
      placeholder="Search"
      onChange={(selectedOptions) => {
        setStepData({
          ...stepData,
          assignedTo: {
            ...stepData?.assignedTo,
            value: selectedOptions.map(option => option.value), // Extract email values
          },
        });
      }}
      isMulti // To allow multiple selections
      options={state?.members?.data?.members?.map((el) => ({
        label: el.fName + ' ' + el.lName,
        value: el.email,
      }))}
      styles={{
        control: (base) => ({
          ...base,
          border: "1px solid #CBD5E0", // Chakra gray-300 equivalent
          borderRadius: "0.375rem", // Chakra borderRadius 'md'
          minHeight: "2.5rem", // Adjust height
          boxShadow: "none",
          zIndex: "1000",
          borderLeft: "0"
        }),
      }}
    />
  </div>
</InputGroup>

                                      </div>
                                      <div className="flex flex-row space-x-4 border-y-2 p-2 items-center">
                                        <h1 className="font-bold w-1/3">Due</h1>
                                        <InputGroup className="w-2/3">
                                          <Input
                                            placeholder="Select Date and Time"
                                            size="md"
                                            type="date"
                                            value={stepData?.deadline.date}
                                            disabled={Cookies.get('role') == "member"}
                                            onChange={(e) =>
                                              setStepData({
                                                ...stepData,
                                                deadline: {
                                                  ...stepData.deadline,
                                                  date: e.target.value,
                                                },
                                              })
                                            }
                                          />
                                        </InputGroup>
                                      </div>
                                      <div className="my-2 p-4 border bg-gray-100 rounded-lg">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: stepData?.html,
                                          }}
                                        />

                                        <p>{stepData?.desc}</p>
                                      </div>
                                      <Accordion allowToggle>
                                        <AccordionItem>
                                          <h2>
                                            <AccordionButton>
                                              <Text className="flex text-lg py-2 px-2">
                                                KickOff Form Details
                                              </Text>
                                              <AccordionIcon />
                                            </AccordionButton>
                                          </h2>
                                          <AccordionPanel pb={4}>
                                            <KickOffFormComponent
                                              kickOffForm={data?.kickOffForm}
                                            />
                                          </AccordionPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                          <h2>
                                            <AccordionButton>
                                              <Text className="flex text-lg py-2 px-2">
                                                Template Instructions
                                              </Text>
                                              <AccordionIcon />
                                            </AccordionButton>
                                          </h2>
                                          <AccordionPanel pb={2}>
                                            <div className="space-y-2 p-2">
                                              <p>{data?.desc}</p>{' '}
                                              <p>{data?.specialInstructions}</p>
                                            </div>
                                          </AccordionPanel>
                                        </AccordionItem>
                                      </Accordion>

                                      <div className="bg-gray-100 rounded-lg p-4">
                                        <Heading
                                          className="flex flex-row items-center pb-4"
                                          size={'md'}
                                        >
                                          <ChatBubbleBottomCenterIcon className="w-4 h-4 mr-4" />{' '}
                                          Comments{' '}
                                        </Heading>
                                        <div className="py-6 max-h-[20rem] overflow-y-scroll">
                                          {/* Comments */}
                                          {stepData?.comments?.map((el, i) => (
                                            <div className="p-2 border rounded-lg my-2 bg-gray-50 ">
                                              <Avatar size="sm" />
                                              <div className="flex-col flex mx-auto space-y-4">
                                                <div className="flex flex-row  justify-left space-x-4 items-center">
                                                  <p
                                                    dangerouslySetInnerHTML={{
                                                      __html: el?.text,
                                                    }}
                                                  ></p>
                                                </div>
                                                <div className="text-sm flex flex-row space-between text-gray-400">
                                                  {el.email}{' '}
                                                  {el.createdAt?.replace(
                                                    'T',
                                                    ' ',
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                        <div className="my-4 border bg-white p-4 space-y-2">
                                          <RichTextEditor
                                            value={html}
                                            onChange={(html) => setHtml(html)}
                                          />
                                          <Button
                                            onClick={(e) =>
                                              handleAddComment(e, html)
                                            }
                                          >
                                            Add Comment
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    hidden={isEdit != 'edit'}
                                    className="border p-4 "
                                  >
                                    <div className="flex flex-col mx-auto justify-center items-center space-y-4 w-full">
                                      <div className="flex flex-row space-x-4">
                                        {' '}
                                        <Button
                                          onClick={() => {
                                            setIsEdit('none')
                                          }}
                                        >
                                          Discard
                                        </Button>
                                        <Button
                                          onClick={(e) => submitUpdateTask(e)}
                                          hidden={Cookies.get('role') == "member"}
                                        >
                                          Save
                                        </Button>
                                      </div>
                                      <InputGroup>
                                        <Input
                                          type="text"
                                          value={stepData.desc}
                                          disabled={Cookies.get('role') == "member"}
                                          onChange={(e) =>
                                            setStepData({
                                              ...stepData,
                                              desc: e.target.value,
                                            })
                                          }
                                          placeholder="Name this task"
                                        />
                                        <InputRightAddon className="p-0 m-0 w-[10rem]">
                                          <Select2
                                            value={data.taskType}
                                            disabled={Cookies.get('role') == "member"}
                                            onChange={(e) =>
                                              setData({
                                                ...data,
                                                taskType: e.target.value,
                                              })
                                            }
                                            placeholder="Select option"
                                            className="w-[10rem]"
                                          >
                                            <option value="task">
                                              {' '}
                                              <CheckCircleIcon className="w-4 h-4" />{' '}
                                              Task
                                            </option>
                                            <option value="approve/reject">
                                              {' '}
                                              <AdjustmentsHorizontalIcon className="w-4 h-4" />{' '}
                                              Approve/Reject
                                            </option>
                                            <option value="expiring">
                                              {' '}
                                              <ClockIcon className="w-4 h-4" />{' '}
                                              Expiring
                                            </option>
                                            <option value="emailDraft">
                                              {' '}
                                              <EnvelopeIcon className="w-4 h-4" />{' '}
                                              Email Draft
                                            </option>
                                            <option value="emailAutoSend">
                                              {' '}
                                              <PaperAirplaneIcon className="w-4 h-4" />{' '}
                                              Email Auto-Send
                                            </option>
                                          </Select2>
                                        </InputRightAddon>
                                      </InputGroup>
                                      <InputGroup>
                                        <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                                          Desc
                                        </InputLeftAddon>
                                        <Input
                                          type="text"
                                          value={stepData.desc}
                                          disabled={Cookies.get('role') == "member"}
                                          onChange={(e) =>
                                            setStepData({
                                              ...stepData,
                                              desc: e.target.value,
                                            })
                                          }
                                          placeholder="Write a short description about this..."
                                        />
                                      </InputGroup>
                                      <InputGroup zIndex={1000}>
  <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
    <UserGroupIcon className="w-4 h-4" />
  </InputLeftAddon>
  <div className="flex-1 z-1000">
    <Select
      value={stepData?.assignedTo.value?.map((email) => {
        // Find the member corresponding to the email
        const member = state?.members?.data?.members?.find((el) => el.email === email);
        return {
          label: member ? member.fName + ' ' + member.lName : '',
          value: email,
        };
      })}
      placeholder="Search"
      onChange={(selectedOptions) => {
        setStepData({
          ...stepData,
          assignedTo: {
            ...stepData?.assignedTo,
            value: selectedOptions.map(option => option.value), // Extract email values
          },
        });
      }}
      isMulti // To allow multiple selections
      options={state?.members?.data?.members?.map((el) => ({
        label: el.fName + ' ' + el.lName,
        value: el.email,
      }))}
      styles={{
        control: (base) => ({
          ...base,
          border: "1px solid #CBD5E0", // Chakra gray-300 equivalent
          borderRadius: "0.375rem", // Chakra borderRadius 'md'
          minHeight: "2.5rem", // Adjust height
          boxShadow: "none",
          zIndex: "1000",
          borderLeft: "0"
        }),
      }}
    />
  </div>
</InputGroup>

                                      <InputGroup zIndex={10}>
                                        <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                                          Start
                                        </InputLeftAddon>
                                        <Input
                                          placeholder="Select Date and Time"
                                          size="md"
                                          type="date"
                                          zIndex={10}
                                          value={stepData.start.date}
                                          disabled={Cookies.get('role') == "member"}
                                          onChange={(e) =>
                                            setStepData({
                                              ...stepData,
                                              start: {
                                                ...stepData.start,
                                                date: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                      </InputGroup>
                                      <InputGroup zIndex={10}>
                                        <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                                          Finish
                                        </InputLeftAddon>
                                        <Input
                                          placeholder="Select Date and Time"
                                          size="md"
                                          type="date"
                                          zIndex={10}
                                          value={stepData.deadline.value}
                                          disabled={Cookies.get('role') == "member"}
                                          onChange={(e) =>
                                            setStepData({
                                              ...stepData,
                                              deadline: {
                                                ...stepData.deadline,
                                                date: e.target.value,
                                              },
                                            })
                                          }
                                        />
                                      </InputGroup>

                                      {/* Advance Settings */}
                                      <Accordion className="w-full" allowToggle>
                                        <AccordionItem>
                                          <h2>
                                            <AccordionButton>
                                              <Box
                                                as="span"
                                                flex="1"
                                                textAlign="right"
                                                className="mr-2"
                                              >
                                                Advance Settings
                                              </Box>
                                              <AccordionIcon />
                                            </AccordionButton>
                                          </h2>
                                          <AccordionPanel
                                            className="space-y-4 w-full"
                                            pb={4}
                                          >
                                            {Cookies.get('role') == "member" ? <div dangerouslySetInnerHTML={{ __html: data?.specialInstructions }} />:<RichTextEditor
                                              value={data.html}
                                              onChange={(html) =>
                                                setStepData({
                                                  ...stepData,
                                                  html: html,
                                                })
                                              }
                                            />}
                                            <div>
                                              <Text className="flex font-bold text-lg mt-8 mb-2">
                                                <PaperClipIcon className="w-6 h-6" />
                                                Link this task to
                                              </Text>
                                              <Select2
                                                value={stepData.linkTo[0]}
                                                disabled={Cookies.get('role') == "member"}
                                                onChange={(e) => {
                                                  // Assuming index is the index of the value you want to edit
                                                  const updatedLinkTo = [
                                                    ...stepData.linkTo,
                                                  ]
                                                  updatedLinkTo[0] =
                                                    e.target.value // Update the second value
                                                  setStepData({
                                                    ...stepData,
                                                    linkTo: updatedLinkTo,
                                                  })
                                                }}
                                                placeholder="Select option"
                                                className="w-full"
                                              >
                                                <option value="process">
                                                  <DocumentCheckIcon className="w-4 h-4" />{' '}
                                                  <div>
                                                    <h1>Process - </h1>
                                                    <p>
                                                      Insert an ad-hoc task into
                                                      an active process.
                                                    </p>
                                                  </div>
                                                </option>

                                                <option value="template">
                                                  {' '}
                                                  <InboxStackIcon className="w-4 h-4" />{' '}
                                                  <div>
                                                    <h1>Templates - </h1>
                                                    <p>
                                                      Link a standalone task to
                                                      a template or template
                                                      step.
                                                    </p>
                                                  </div>
                                                </option>
                                              </Select2>
                                              {stepData.linkTo[0] ? (
                                                <Select2
                                                  value={stepData.linkTo[1]}
                                                  disabled={Cookies.get('role') == "member"}
                                                  onChange={(e) => {
                                                    // Assuming index is the index of the value you want to edit
                                                    const updatedLinkTo = [
                                                      ...stepData.linkTo,
                                                    ]
                                                    updatedLinkTo[1] =
                                                      e.target.value // Update the second value
                                                    setStepData({
                                                      ...stepData,
                                                      linkTo: updatedLinkTo,
                                                    })
                                                  }}
                                                  placeholder="Search for active processes"
                                                  className="w-full"
                                                >
                                                  <option value="process">
                                                    option 1
                                                  </option>

                                                  <option value="template">
                                                    option 2
                                                  </option>
                                                </Select2>
                                              ) : null}
                                              <div className="p-2 border my-4">
                                                <Accordion allowToggle>
                                                  <AccordionItem>
                                                    <h2>
                                                      <AccordionButton>
                                                        <Text className="flex font-bold text-lg mt-4 mb-2">
                                                          <DocumentDuplicateIcon className="w-6 h-6" />
                                                          Forms
                                                        </Text>
                                                        <AccordionIcon />
                                                      </AccordionButton>
                                                    </h2>
                                                    <AccordionPanel pb={4}>
                                                    {Cookies.get('role') == "member" ? <FormRender
                                                    formData={stepData.forms}
                                                    formResponses={stepData}
                                                    setFormResponses={setStepData}
                                                  /> :  <FormBuilder
                                                        formData={
                                                          stepData.forms
                                                        }
                                                        data={stepData}
                                                        setData={setStepData}
                                                      />}
                                                    </AccordionPanel>
                                                  </AccordionItem>
                                                </Accordion>
                                              </div>
                                              <input
                                                type="text"
                                                placeholder="Tags"
                                                onChange={(e) =>
                                                  setTagSearch(e.target.value)
                                                }
                                                value={tagSearch}
                                                disabled={Cookies.get('role') == "member"}
                                                onKeyDown={(e) => {
                                                  if (e.key === 'Enter') {
                                                    if (
                                                      !stepData.tags.includes(
                                                        tagSearch,
                                                      )
                                                    ) {
                                                      setStepData({
                                                        ...stepData,
                                                        tags: [
                                                          ...stepData.tags,
                                                          tagSearch,
                                                        ],
                                                      })
                                                    }
                                                    setTagSearch('')
                                                  }
                                                }}
                                              />
                                              <div className="flex flex-row space-x-2 mt-2">
                                                {stepData.tags.map(
                                                  (title, i) => (
                                                    <Tag
                                                      size={'md'}
                                                      key={i}
                                                      variant="solid"
                                                      colorScheme="teal"
                                                      className="w-fit"
                                                    >
                                                      {title}
                                                      <button
                                                      disabled={Cookies.get('role') == "member"}
                                                        onClick={() => {
                                                          const updatedTags =
                                                            stepData.tags
                                                          updatedTags.splice(
                                                            i,
                                                            1,
                                                          )
                                                          setStepData({
                                                            ...stepData,
                                                            tags: updatedTags,
                                                          })
                                                        }}
                                                      >
                                                        <TagCloseButton />
                                                      </button>
                                                    </Tag>
                                                  ),
                                                )}
                                              </div>
                                            </div>
                                            <FormControl
                                              className="flex flex-col space-y-4 py-4"
                                              alignItems="left"
                                            >
                                              {/* <div
                                              disabled={Cookies.get('role') == "member"}
                                                onClick={(e) =>
                                                  setStepData({
                                                    ...stepData,
                                                    allAssigneesMustComplete:
                                                      e.target.checked,
                                                  })
                                                }
                                                className="flex justify-between"
                                              >
                                                <FormLabel htmlFor="1" mb="0">
                                                  All assignees must complete
                                                  this task
                                                </FormLabel>
                                                <Switch
                                                  id="1"
                                                  isChecked={
                                                    stepData.allAssigneesMustComplete
                                                  }
                                                />
                                              </div> */}

                                              {/* <div
                                              disabled={Cookies.get('role') == "member"}
                                                onClick={(e) =>
                                                  stepData({
                                                    ...stepData,
                                                    enforceMaxAssignees:
                                                      e.target.checked,
                                                  })
                                                }
                                                className="flex justify-between"
                                              >
                                                <FormLabel htmlFor="2" mb="0">
                                                  Enforce a maximum number of
                                                  assignees?
                                                </FormLabel>
                                                <Switch
                                                  id="2"
                                                  isChecked={
                                                    stepData.enforceMaxAssignees
                                                  }
                                                />
                                              </div> */}
                                              <Input
                                                hidden={
                                                  !stepData.enforceMaxAssignees
                                                }
                                                className="my-2"
                                                placeholder="Max No. of Assignees"
                                                value={stepData.maxAssignees}
                                                disabled={Cookies.get('role') == "member"}
                                                onChange={(e) => {
                                                  setStepData({
                                                    ...stepData,
                                                    maxAssignees:
                                                      e.target.value,
                                                  })
                                                }}
                                              />

                                              {/* <div
                                                onClick={(e) =>
                                                  setStepData({
                                                    ...stepData,
                                                    preventGuestComments:
                                                      e.target.checked,
                                                  })
                                                }
                                                className="flex justify-between"
                                              >
                                                <FormLabel htmlFor="3" mb="0">
                                                  Prevent guests from making or
                                                  seeing any comments?
                                                </FormLabel>
                                                <Switch
                                                  id="3"
                                                  isChecked={
                                                    stepData.preventGuestComments
                                                  }
                                                />
                                              </div> */}

                                              <div
                                              disabled={Cookies.get('role') == "member"}
                                                onClick={(e) =>
                                                  setStepData({
                                                    ...stepData,
                                                    emitWebhookOnCompletion:
                                                      e.target.checked,
                                                  })
                                                }
                                                className="flex justify-between"
                                              >
                                                <FormLabel htmlFor="4" mb="0">
                                                  Emit a webhook when this task
                                                  is completed?
                                                </FormLabel>
                                                <Switch
                                                  id="4"
                                                  isChecked={
                                                    stepData.emitWebhookOnCompletion
                                                  }
                                                />
                                              </div>
                                              <Input
                                              disabled={Cookies.get('role') == "member"}
                                                className="my-2"
                                                hidden={
                                                  !stepData.emitWebhookOnCompletion
                                                }
                                                placeholder="Target URL"
                                                value={stepData.targetUrl}
                                                onChange={(e) =>
                                                  setStepData({
                                                    ...stepData,
                                                    targetUrl: e.target.value,
                                                  })
                                                }
                                              />
                                            </FormControl>
                                          </AccordionPanel>
                                        </AccordionItem>
                                      </Accordion>
                                    </div>
                                  </div>
                                  <div
                                    hidden={isEdit != 'activity'}
                                    className="border p-4 "
                                  >
                                    <div>
                                      <Button
                                        className="mb-4"
                                        onClick={() => {
                                          setIsEdit('none')
                                        }}
                                      >
                                        Back
                                      </Button>
                                      {/* Group activities by date */}
                                      {allActivities?.map((group, index) => (
                                        <div key={index}>
                                          {/* Heading */}
                                          <div className="ps-2 my-2 first:mt-0">
                                            <h3 className="text-xs font-medium uppercase text-gray-500">
                                              {/* Format group date */}
                                              {new Date(
                                                group.date,
                                              ).toLocaleDateString('en-US', {
                                                weekday: 'long',
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                              })}
                                            </h3>
                                          </div>
                                          {/* End Heading */}
                                          {/* Render activities for the current date */}
                                          {group.activities.map(
                                            (activity, index) => (
                                              <div
                                                key={index}
                                                className="flex gap-x-3"
                                              >
                                                {/* Icon */}
                                                <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                                                  <div className="relative z-10 size-7 flex justify-center items-center">
                                                    <div className="size-2 rounded-full bg-gray-400" />
                                                  </div>
                                                </div>
                                                {/* End Icon */}
                                                {/* Right Content */}
                                                <div className="grow pt-0.5 pb-8">
                                                  <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                                                    {/* Render activity message */}
                                                    {activity.message}
                                                  </h3>
                                                  {/* Render additional details */}
                                                  {activity.linkedTask && (
                                                    <p className="mt-1 text-sm text-gray-600">
                                                      Linked Task:{' '}
                                                      {activity.taskName}
                                                    </p>
                                                  )}
                                                  {/* Render user */}
                                                  <button
                                                    type="button"
                                                    className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
                                                  >
                                                    <Avatar
                                                      size="xs"
                                                      alt="User Avatar"
                                                    />
                                                    {activity.createdBy}{' '}
                                                    {/* Assuming name is present in createdBy object */}
                                                  </button>
                                                </div>
                                                {/* End Right Content */}
                                              </div>
                                            ),
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </ModalBody>
                        </ModalContent>
                      </Modal>
                    </TabPanel>
                    <TabPanel>
                      <div className="flex space-x-4 w-full">
                        <aside className="hidden lg:block w-1/3 bg-gray-100 rounded-lg">
                          <nav className="p-4">
                            <h1 className="font-bold">Navigate</h1>
                            <ul className="space-y-2">
                              <li>
                                <a
                                  className="block py-2 px-4 rounded-md hover:bg-gray-200 dark:hover:bg-gray-700"
                                  href="#"
                                >
                                  Step 1
                                </a>
                              </li>
                            </ul>
                          </nav>
                        </aside>
                        <div className="flex justify-center py-10 w-full">
                          <div className="bg-white shadow-lg rounded-lg overflow-hidden w-full lg:w-2/3">
                            <div className="bg-[#D1FAE5] p-6">
                              <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-4">
                                  <CheckCircleIcon className="text-green-500 h-8 w-8" />
                                  <div className="text-lg font-semibold">
                                    step 1
                                  </div>
                                </div>
                                <Button className="bg-green-500 text-white">
                                  GO
                                </Button>
                              </div>
                            </div>
                            <div className="p-6">
                              <div className="flex items-center justify-between">
                                <div className="flex items-center space-x-4">
                                  <Badge variant="secondary">1</Badge>
                                  <div className="text-lg font-semibold">
                                    step 1
                                  </div>
                                </div>
                                <div className="flex items-center space-x-2">
                                  <CheckCircleIcon className="text-green-500 h-6 w-6" />
                                  <div className="text-lg">Task</div>
                                </div>
                              </div>
                            </div>
                            <div className="flex justify-end p-4">
                              <EllipsisHorizontalIcon className="h-6 w-6 text-gray-400" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <AddAutomations />
                    </TabPanel>
                    <TabPanel>
                      <div>
                        <Tabs>
                          <TabList>
                            <Tab className="w-1/4">BULK-CREATE STEPS</Tab>
                            <Tab className="w-1/4">BULK-CHANGE ASSIGNEES</Tab>
                            <Tab className="w-1/4">BULK-CHANGE DEADLINES</Tab>
                            <Tab className="w-1/4">BULK FAVORITE</Tab>
                          </TabList>

                          <TabPanels>
                            <TabPanel>
                              <p className="my-4">
                                Type in every new step you want to create in a
                                new row below. This helps you create lots of
                                steps quickly.
                              </p>
                              <Textarea />
                            </TabPanel>
                            <TabPanel>
                              <div>
                                <InputGroup className="my-4 space-x-4">
                                  <Select2
                                    id="status-toggle"
                                    placeholder="To Do"
                                    className="w-full"
                                    value="days"
                                  >
                                    <option value="min">Assign</option>
                                    <option value="hrs">Un Assign</option>
                                  </Select2>
                                  <Select2
                                    id="status-toggle"
                                    placeholder="Assign"
                                    className="w-full"
                                    value={value}
                                  >
                                    {options.map((el, i) => (
                                      <option value={el.value}>
                                        {el.label}
                                      </option>
                                    ))}
                                  </Select2>
                                </InputGroup>
                                <Button>Assign to all steps</Button>
                                <TableContainer>
                                  <Table variant="simple">
                                    <Thead>
                                      <Tr>
                                        <Th>Action</Th>
                                        <Th>Step Name</Th>
                                      </Tr>
                                    </Thead>
                                    <Tbody>
                                      <Tr>
                                        <Td>
                                          <Button>Assign</Button>
                                        </Td>
                                        <Td>Step1</Td>
                                      </Tr>
                                    </Tbody>
                                  </Table>
                                </TableContainer>
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <TableContainer>
                                <Table variant="simple">
                                  <Thead>
                                    <Tr>
                                      <Th>Task name</Th>
                                      <Th>Original</Th>
                                      <Th>New</Th>
                                    </Tr>
                                  </Thead>
                                  <Tbody>
                                    <Tr>
                                      <Td>1. Step 1</Td>
                                      <Td>5 Days</Td>
                                      <Td>
                                        <div className="flex flex-row space-x-2">
                                          <Input
                                            typr="number"
                                            placeholder="5"
                                          />{' '}
                                          <Select2
                                            id="status-toggle"
                                            placeholder="Timeline"
                                            className="w-full"
                                          >
                                            <option value="inProgress">
                                              Mins
                                            </option>
                                            <option value="inProgress">
                                              Hrs
                                            </option>
                                            <option value="started">
                                              Days
                                            </option>
                                            <option value="inProgress">
                                              Weeks
                                            </option>

                                            <option value="started">
                                              Months
                                            </option>
                                          </Select2>
                                        </div>
                                      </Td>
                                    </Tr>
                                  </Tbody>
                                </Table>
                              </TableContainer>
                              <Button className="my-4 flex justify-end ml-auto">
                                Save All
                              </Button>
                            </TabPanel>
                            <TabPanel>
                              <p className="my-4">
                                Push this template into the favorites list of
                                these coworkers:
                              </p>
                              <MultiSelect
                                options={options}
                                value={value}
                                label="Type member name, guest email, or group name"
                                onChange={setValue}
                              />
                            </TabPanel>
                          </TabPanels>
                        </Tabs>
                      </div>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DrawerExample
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        _id={id}
      />
      <TemplateLaunch formData={data} />
      <CreateGroups />
    </div>
  )
}

export default TrackerView
