import React, { useState, useCallback } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
  useDisclosure,
  Box,
  Stack,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import {
  AdjustmentsHorizontalIcon,
  ArrowsPointingInIcon,
  CheckCircleIcon,
  CheckIcon,
  ClipboardDocumentCheckIcon,
  ClockIcon,
  DocumentCheckIcon,
  DocumentDuplicateIcon,
  EnvelopeIcon,
  InboxStackIcon,
  PaperAirplaneIcon,
  PaperClipIcon,
  PencilIcon,
  PlayIcon,
  PlusIcon,
  RectangleStackIcon,
  RocketLaunchIcon,
  SpeakerWaveIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import { Select } from '@chakra-ui/react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
} from '@chakra-ui/react'
import {
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
} from '@chakra-ui/react'
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import Editor from 'react-simple-wysiwyg'
import FormBuilder from '../utils/FormBuilder'
import { Switch } from '@chakra-ui/react'
import {
  openInviteMemberModal,
  closeInviteMemberModal,
} from '../../redux/InviteMemberModal'
import { useDispatch, useSelector } from 'react-redux'
import RichTextEditor from '../utils/RichTextEditor'

function ShareUpdate({
  shareUpdateIsOpen,
  shareUpdateOnOpen,
  shareUpdateOnClose,
}) {
  const [taskType, setTaskType] = useState('task')

  const [html, setHtml] = useState('my <b>HTML</b>')


  const [tags, setTags] = useState(['foo', 'bar'])
  const [tagSearch, setTagSearch] = useState('')
  const dispatch = useDispatch()

  const [data, setData] = useState({
    taskType: 'task',
    name: '',
    desc: '',
    assignedTo: { type: 'fixed', value: [] },

    guests: [],
    start: {
      date: null
    },
    deadline: {
      date: null
    },
    html: '',
    linkTo: ['', ''],
    forms: [
      // {
      //   type: 'header',
      //   subtype: 'h1',
      //   label: 'formBuilder in React',
      // },
      // {
      //   type: 'paragraph',
      //   label:
      //     'This is a demonstration of formBuilder running in a React project.',
      // },
    ],
    tags: ['Project', 'Proposal'],
    allAssigneesMustComplete: false,
    enforceMaxAssignees: true,
    maxAssignees: null,
    preventGuestComments: true,
    emitWebhookOnCompletion: false,
    targetUrl: null,
  })

  return (
    <>
      <Modal
        size={'2xl'}
        isOpen={shareUpdateIsOpen}
        onClose={shareUpdateOnClose}
      >
        <ModalOverlay />
        <ModalContent className="w-full">
          <ModalHeader>Share Update</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            zIndex={10}
            className="flex flex-col mx-auto justify-center items-center space-y-4 w-full"
          >
            <InputGroup>
              <Input type="text" placeholder="Name this task" />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                <UserGroupIcon className="w-4 h-4" />
              </InputLeftAddon>
              <Input type="text" placeholder="Recepients" />

              <Popover size={'2xl'}>
                <PopoverTrigger>
                  <InputRightAddon className="w-[10rem] mx-auto justify-center flex center text-center">
                    <AvatarGroup className="mr-2" size="xs" max={2}>
                      <Avatar
                        name="Ryan Florence"
                        src="https://bit.ly/ryan-florence"
                      />
                      <Avatar
                        name="Segun Adebayo"
                        src="https://bit.ly/sage-adebayo"
                      />
                      <Avatar
                        name="Kent Dodds"
                        src="https://bit.ly/kent-c-dodds"
                      />
                      <Avatar
                        name="Prosper Otemuyiwa"
                        src="https://bit.ly/prosper-baba"
                      />
                      <Avatar
                        name="Christian Nwamba"
                        src="https://bit.ly/code-beast"
                      />
                    </AvatarGroup>
                    <PencilIcon className="w-4 h-4" />
                  </InputRightAddon>
                </PopoverTrigger>
                <Portal>
                  <Box zIndex="popover" w="full" h="full" position={'relative'}>
                    <PopoverContent zIndex={10}>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody className="space-y-4 w-[20rem]">
                        <div>
                          <Text className="font-bold">
                            Add Members or Groups
                          </Text>
                          <InputGroup>
                            <InputLeftAddon className="my-2 w-[4rem]">
                              <Tag
                                size="sm"
                                colorScheme="red"
                                borderRadius="full"
                              >
                                <Avatar
                                  src="https://bit.ly/sage-adebayo"
                                  size="xs"
                                  name="Segun Adebayo"
                                  ml={-1}
                                  mr={2}
                                />
                                <TagLabel>Segun</TagLabel>
                              </Tag>
                            </InputLeftAddon>
                            <Input className="my-2" placeholder="Search" />
                          </InputGroup>
                          {/* <Button
                            variant="ghost"
                            leftIcon={<PlusIcon className="w-4 h-4" />}
                            onClick={() => dispatch(openInviteMemberModal())}
                          >
                            Invite New Members
                          </Button> */}
                        </div>
                        <div>
                          <Text className="font-bold">Add Guests</Text>
                          <p className="text-sm">
                            Add comma separated email addresses
                          </p>
                          <InputGroup>
                            <Input className="my-2" placeholder="Search" />
                          </InputGroup>
                        </div>
                      </PopoverBody>
                    </PopoverContent>
                  </Box>
                </Portal>
              </Popover>
            </InputGroup>
            <InputGroup>
              <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                Start
              </InputLeftAddon>
              <Input
                placeholder="Select Date and Time"
                size="md"
                type="date"
              />
            </InputGroup>
            <InputGroup>
              <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                Finish
              </InputLeftAddon>
              <Input
                placeholder="Select Date and Time"
                size="md"
                type="date"
              />
            </InputGroup>

            {/* Advance Settings */}
            <Accordion className="w-full" allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="right" className="mr-2">
                      Advance Settings
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel className="space-y-4 w-full" pb={4}>
                  <RichTextEditor value={html} onChange={(html) => setHtml(html)} />
                  <div>
                    <Text className="flex font-bold text-lg mt-8 mb-2">
                      <PaperClipIcon className="w-6 h-6" />
                      Link this task to
                    </Text>
                    <Select
                      value={taskType}
                      onChange={(e) => setTaskType(e.target.value)}
                      placeholder="Select option"
                      className="w-full"
                    >
                      <option value="process">
                        <DocumentCheckIcon className="w-4 h-4" />{' '}
                        <div>
                          <h1>Process - </h1>
                          <p>Insert an ad-hoc task into an active process.</p>
                        </div>
                      </option>

                      <option value="template">
                        {' '}
                        <InboxStackIcon className="w-4 h-4" />{' '}
                        <div>
                          <h1>Templates - </h1>
                          <p>
                            Link a standalone task to a template or template
                            step.
                          </p>
                        </div>
                      </option>
                    </Select>
                    {taskType ? (
                      <Select
                        onChange={(e) => setTaskType(e.target.value)}
                        placeholder="Search for active processes"
                        className="w-full"
                      >
                        <option value="process">option 1</option>

                        <option value="template">option 2</option>
                      </Select>
                    ) : null}
                    <div className="p-2 border my-4">
                      <Accordion allowToggle>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Text className="flex font-bold text-lg mt-4 mb-2">
                                <DocumentDuplicateIcon className="w-6 h-6" />
                                Forms
                              </Text>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <FormBuilder
                              formData={data.forms}
                              data={data}
                              setData={setData}
                            />
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>
                    <Input
                      type="text"
                      placeholder="Tags"
                      onChange={(e) => setTagSearch(e.target.value)}
                      value={tagSearch}
                    />
                    <div className="flex flex-row space-x-2 mt-2">
                      {tags.map((title, i) => (
                        <Tag
                          size={'md'}
                          key={i}
                          variant="solid"
                          colorScheme="teal"
                          className="w-fit"
                        >
                          {title}
                          <TagCloseButton />
                        </Tag>
                      ))}
                    </div>
                  </div>
                  <FormControl
                    className="flex flex-col space-y-4 py-4"
                    alignItems="left"
                  >
                    <div className="flex justify-between">
                      <FormLabel htmlFor="1" mb="0">
                        Enforce a maximum number of assignees?
                      </FormLabel>
                      <Switch id="1" />
                    </div>

                    <div className="flex justify-between">
                      <FormLabel htmlFor="2" mb="0">
                        Prevent guests from making or seeing any comments?
                      </FormLabel>
                      <Switch id="2" />
                    </div>

                    <div className="flex justify-between">
                      <FormLabel htmlFor="3" mb="0">
                        Emit a webhook when this task is completed?
                      </FormLabel>
                      <Switch id="3" />
                    </div>
                  </FormControl>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </ModalBody>

          <ModalFooter zIndex={1} className="mt-8">
            <Button colorScheme="blue" mr={3}>
              Share Update
            </Button>
            <Button onClick={shareUpdateOnClose} colorScheme="gray">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ShareUpdate
