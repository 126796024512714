import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Heading,
  Text,
  Button,
  Progress,
  Avatar,
  Input,
  useDisclosure,
  Box,
  TagCloseButton,
  Spinner,
} from '@chakra-ui/react'
import { Select as Select2 } from '@chakra-ui/react'
import Select from "react-select";
import { Tag } from '@chakra-ui/react'
import {
  ClipboardDocumentListIcon,
  EllipsisVerticalIcon,
  StarIcon,
} from '@heroicons/react/24/outline'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'
import { ChevronDownIcon, PlusIcon } from '@heroicons/react/24/solid'
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import CreatePreset from '../../components/CreatePresetModal'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import {
  deleteLaunchTemplate,
  getLaunchedTemplate,
  updateLaunchTemplate,
} from '../../redux/LaunchedTemplateSlice'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useToast,
} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

const FilterSidebar = ({
  filterSidebarIsOpen,
  filterSidebarOnOpen,
  filterSidebarOnClose,
  applySidebarFilters,
  selectedFilters,
  setSelectedFilters,
  tempFolderName,
  setTempFolderName,
  folderList,
  setFolderList,
  filteredFolderList,
  setFilteredFolderList,
}) => {
  const btnRef = React.useRef()
  const [tags, setTags] = useState(['foo', 'bar'])
  const [tagSearch, setTagSearch] = useState('')

  const handleFilterSelection = (key, value) => {
    setSelectedFilters((prev) => ({ ...prev, [key]: value }))
  }

  const handleApplyFilters = () => {
    applySidebarFilters(selectedFilters)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault() // Prevent form submission
      if (tagSearch.trim() !== '') {
        setSelectedFilters({
          ...selectedFilters,
          tags: [...selectedFilters.tags, tagSearch.trim()],
        })
        setTagSearch('') // Clear input field after adding tag
      }
    }
  }

  const removeTag = (index) => {
    const newTags = [...selectedFilters.tags]
    newTags.splice(index, 1)
    setSelectedFilters({ ...selectedFilters, tags: newTags })
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Drawer
        isOpen={filterSidebarIsOpen}
        placement="right"
        onClose={filterSidebarOnClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Filter</DrawerHeader>
          <DrawerBody>
            <Accordion allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Show
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel className="flex flex-col space-y-4" pb={4}>
                  <Button
                    colorScheme={
                      selectedFilters.show == 'All' ? 'green' : 'gray'
                    }
                    onClick={() => handleFilterSelection('show', 'All')}
                  >
                    All
                  </Button>
                  <Button
                    colorScheme={
                      selectedFilters.show == 'Overdue' ? 'green' : 'gray'
                    }
                    onClick={() => handleFilterSelection('show', 'Overdue')}
                  >
                    Overdue
                  </Button>
                  <Button
                    colorScheme={
                      selectedFilters.show == 'Favourites' ? 'green' : 'gray'
                    }
                    onClick={() => handleFilterSelection('show', 'Favourites')}
                  >
                    Favourites
                  </Button>
                  <Button
                    colorScheme={
                      selectedFilters.show == 'Untagged' ? 'green' : 'gray'
                    }
                    onClick={() => handleFilterSelection('show', 'Untagged')}
                  >
                    Untagged
                  </Button>
                  <Button
                    colorScheme={
                      selectedFilters.show == 'Archived' ? 'green' : 'gray'
                    }
                    onClick={() => handleFilterSelection('show', 'Archived')}
                  >
                    Archived
                  </Button>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Type
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel className="flex flex-col space-y-4" pb={4}>
                  <Button
                    colorScheme={
                      selectedFilters.type == 'Procedure' ? 'green' : 'gray'
                    }
                    onClick={() => handleFilterSelection('type', 'Procedure')}
                  >
                    Procedure
                  </Button>
                  <Button
                    colorScheme={
                      selectedFilters.type == 'Documents' ? 'green' : 'gray'
                    }
                    onClick={() => handleFilterSelection('type', 'Documents')}
                  >
                    Documents
                  </Button>
                  <Button
                    colorScheme={
                      selectedFilters.type == 'Forms' ? 'green' : 'gray'
                    }
                    onClick={() => handleFilterSelection('type', 'Forms')}
                  >
                    Forms
                  </Button>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Process Folder
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel className="flex flex-col space-y-4" pb={4}>
                  <Button
                    onClick={() =>
                      handleFilterSelection('processFolders', null)
                    }
                    variant="outline"
                  >
                    Uncategorized
                  </Button>
                  {filteredFolderList.map((el, i) => (
                    <Button
                      onClick={() =>
                        handleFilterSelection('processFolders', el)
                      }
                      key={i}
                      variant="outline"
                    >
                      {el}
                    </Button>
                  ))}
                  <Button hidden={Cookies.get('role') == "member"} onClick={onOpen} variant="outline">
                    + New Folder
                  </Button>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="left">
                      Tags
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel className="flex flex-col space-y-4" pb={4}>
                  <Input
                    type="text"
                    placeholder="Tags"
                    onChange={(e) => setTagSearch(e.target.value)}
                    value={tagSearch}
                    onKeyDown={handleKeyDown}
                  />
                  <div className="flex flex-row space-x-2 mt-2">
                    {selectedFilters.tags.map((title, i) => (
                      <Tag
                        size={'md'}
                        key={i}
                        variant="solid"
                        colorScheme="teal"
                        className="w-fit"
                      >
                        {title}
                        <TagCloseButton onClick={() => removeTag(i)} />
                      </Tag>
                    ))}
                  </div>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </DrawerBody>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={filterSidebarOnClose}>
              Cancel
            </Button>
            <Button colorScheme="blue" onClick={handleApplyFilters}>
              Save
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create New Folder</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Input
              value={tempFolderName}
              onChange={(e) => setTempFolderName(e.target.value)}
              placeholder="Type a name for this Folder"
            />
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} variant="ghost">
              {' '}
              Close
            </Button>
            <Button
              onClick={() => {
                if (tempFolderName) {
                  setFolderList([...folderList, tempFolderName])
                  onClose()
                }
              }}
              colorScheme="blue"
              ml={3}
            >
              Create Folder
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default function Tracker() {
  const [trackData, setTrackData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [sort, setSort] = useState('')
  const [tempFolderName, setTempFolderName] = useState('')
  const [folderList, setFolderList] = useState([])
  const [filteredFolderList, setFilteredFolderList] = useState(folderList)
  const [filters, setFilters] = useState({
    show: 'All',
    type: 'Procedure',
    processFolders: 'All',
    tags: [],
  })

  const [isLoading, setIsLoading] = useState(true)

  const router = useNavigate()

  const dispatch = useDispatch()
  const state = useSelector((state) => state)

  useEffect(() => {
    dispatch(getLaunchedTemplate())
    setIsLoading(false)
  }, [dispatch])

  useEffect(() => {
    if (state?.launchedTemplates?.data?.length > 0) {
      const updatedData = state.launchedTemplates.data.map((template) => {
        const completedSteps = template?.steps?.filter(
          (step) => step.status === 'completed',
        ).length
        return {
          ...template,
          completedSteps: completedSteps,
        }
      })

      setTrackData(updatedData)

      // Extract unique folders
      const uniqueFolders = [
        ...new Set(
          updatedData
            .filter((item) => item.folder && item.folder.length > 0)
            .map((item) => item.folder),
        ),
      ]
      setFolderList(uniqueFolders)
      console.log(uniqueFolders, 'uniqueFolders')

      setFilteredFolderList(uniqueFolders)
    }
  }, [state?.launchedTemplates?.data])

  useEffect(() => {
    if (trackData.length > 0) {
      applyFilters(filters)
    }
  }, [trackData, filters])

  useEffect(() => {
    setFilteredFolderList(folderList)
  }, [folderList])

  const handleClearFilters = () => {
    setFilters({
      show: 'All',
      type: 'Procedure',
      processFolders: 'All',
      tags: [],
    })
  }

  const handleRemoveTag = (tag) => {
    const newTags = filters.tags.filter((t) => t !== tag)
    setFilters((prevFilters) => ({
      ...prevFilters,
      tags: newTags,
    }))
  }

  const applyFilters = (filters) => {
    let data = trackData

    if (filters.show != 'All') {
      if (filters.show == 'Overdue') {
        data = data.filter((item) => item.completedSteps < item.steps.length)
      } else if (filters.show == 'Favourites') {
        data = data.filter((item) => item.isFavourite)
      } else if (filters.show == 'Archived') {
        data = data.filter((item) => item.isArchived)
      } else if (filters.show == 'Untagged') {
        data = data.filter((item) => item.tags.length == 0)
      }
    } else {
      data = data.filter((item) => !item.isArchived)
    }

    if (filters.type != 'All') {
      data = data.filter((item) => item.type == filters.type)
    }

    if (filters.processFolders != 'All') {
      data = data.filter((item) => item.folder == filters.processFolders)
    }

    if (filters.tags.length > 0) {
      data = data.filter((item) =>
        filters.tags.every((tag) => item.tags.includes(tag)),
      )
    }

    setFilteredData(data)
  }

  const handleFilterChange = (key, value) => {
    const newFilters = { ...filters, [key]: value }
    setFilters(newFilters)
  }

  const handleSortChange = (value) => {
    setSort(value)
    const sortedData = [...filteredData]
    switch (value) {
      case 'Newest First':
        sortedData.sort(
          (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated),
        )
        break
      case 'Oldest First':
        sortedData.sort(
          (a, b) => new Date(a.dateCreated) - new Date(b.dateCreated),
        )
        break
      case 'By Due Date':
        sortedData.sort(
          (a, b) =>
            new Date(a.steps[0].deadline.date) -
            new Date(b.steps[0].deadline.date),
        )
        break
      case 'Sort A-Z':
        sortedData.sort((a, b) => a.name.localeCompare(b.name))
        break
      case 'Sort Z-A':
        sortedData.sort((a, b) => b.name.localeCompare(a.name))
        break
      default:
        break
    }
    setFilteredData(sortedData)
  }

  const {
    isOpen: filterSidebarIsOpen,
    onOpen: filterSidebarOnOpen,
    onClose: filterSidebarOnClose,
  } = useDisclosure()

  const {
    isOpen: createPresetIsOpen,
    onOpen: createPresetOnOpen,
    onClose: createPresetOnClose,
  } = useDisclosure()

  const handleToggleFavorite = (e, data) => {
    e.preventDefault()
    dispatch(
      updateLaunchTemplate({ _id: data._id, isFavourite: !data.isFavourite }),
    )
  }

  const renderText = (text, data) => {
    // Regular expression to find double curly braces
    const regex = /{{(.*?)}}/gm

    // Replace placeholders with actual data values
    const result = text?.replace(regex, (_, key) => {
      // Access the nested 'value' field dynamically
      return data[key]?.value || key
    })

    return result
  }

  return (
    <div className="overflow-x-hidden min-h-screen">
      <Modal isCentered isOpen={isLoading}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
          className="items-center flex justify-center"
        >
          <Spinner size="xl" />
        </ModalOverlay>
      </Modal>{' '}
      <Header />
      <div className="ml-1 md:ml-2 lg:ml-40 my-8 md:my-4 lg:my-2 mr-1 md:mr-2 space-y-4 py-10">
        <div className="flex flex-col space-y-4 border p-4 rounded-lg">
          <div className="flex flex-col md:flex-row space-y-1 md:space-x-1 md:space-x-4">
            <Tag
              size={'md'}
              variant="solid"
              colorScheme="gray"
              className="w-fit"
            >
              Show: {filters.show}
              <TagCloseButton
                onClick={() => handleFilterChange('show', 'All')}
              />
            </Tag>
            <Tag
              size={'md'}
              variant="solid"
              colorScheme="gray"
              className="w-fit"
            >
              Type: {filters.type}
              <TagCloseButton
                onClick={() => handleFilterChange('type', 'All')}
              />
            </Tag>
            <Tag
              size={'md'}
              variant="solid"
              colorScheme="gray"
              className="w-fit"
            >
              Process Folders: {filters.processFolders}
              <TagCloseButton
                onClick={() => handleFilterChange('processFolders', 'All')}
              />
            </Tag>
            {filters.tags.map((tag, index) => (
              <Tag
                key={index}
                size={'md'}
                variant="solid"
                colorScheme="gray"
                className="w-fit"
                onClick={() => handleRemoveTag(tag)}
              >
                {tag}
                <TagCloseButton />
              </Tag>
            ))}
            <Button size="xs" colorScheme="red" onClick={handleClearFilters}>
              Clear Filters
            </Button>
          </div>

          <Button
            className="w-[10rem]"
            leftIcon={<PlusIcon className="w-4 h-4" />}
            onClick={filterSidebarOnOpen}
          >
            Add Filter
          </Button>

          <Menu>
            <MenuButton
              className="w-[10rem]"
              as={Button}
              rightIcon={<ChevronDownIcon className="w-4 h-4" />}
            >
              {sort || 'Sort'}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => handleSortChange('Newest First')}>
                Newest First
              </MenuItem>
              <MenuItem onClick={() => handleSortChange('Oldest First')}>
                Oldest First
              </MenuItem>
              <MenuItem onClick={() => handleSortChange('By Due Date')}>
                By Due Date
              </MenuItem>
              <MenuItem onClick={() => handleSortChange('Sort A-Z')}>
                Sort A-Z
              </MenuItem>
              <MenuItem onClick={() => handleSortChange('Sort Z-A')}>
                Sort Z-A
              </MenuItem>
            </MenuList>
          </Menu>

          <div className="grid lg:grid-cols-3 gap-4">
            {filteredData && filteredData.length > 0
              ? filteredData.map((el, i) => {
                  return (
                    <div
                      key={i}
                      className="bg-gray-100 p-1 md:p-4 cursor-pointer"
                    >
                      <div className="w-full p-1 md:p-4">
                        <div className="flex flex-col space-y-4 md:flex-row w-full">
                          <Heading
                            className="flex-grow flex-1 w-full md:w-2/3"
                            size="md"
                            onClick={() =>
                              router(`/dashboard/tracker/${el._id}`)
                            }
                          >
                            <Text
                              isTruncated
                              className="text-sm font-normal mb-4 w-[90%]"
                            >
                              {el?.name}
                            </Text>
                            <Text isTruncated className="w-full">
                              {renderText(
                                el?.kickOffForm?.name,
                                el?.kickOffForm?.forms,
                              )}
                            </Text>
                          </Heading>
                          <div className=" w-full md:w-1/3">
                            {' '}
                            <Tag size={'sm'} variant="solid" colorScheme="teal">
                              <ClipboardDocumentListIcon className="w-4 h-4" />{' '}
                              {el.type}
                            </Tag>
                            <Menu>
                              <MenuButton className="pl-2">
                                <EllipsisVerticalIcon className="w-4 h-4" />
                              </MenuButton>
                              <MenuList>
                                <Menu>
                                  <MenuButton>
                                    <MenuItem>Move to</MenuItem>
                                  </MenuButton>
                                  <MenuList>
                                    <MenuItem
                                      onClick={() =>
                                        dispatch(
                                          updateLaunchTemplate({
                                            _id: el._id,
                                            folder: '',
                                          }),
                                        )
                                      }
                                      hidden={
                                        el.folder == '' ||
                                        el.folder == null ||
                                        !el.folder
                                      }
                                    >
                                      Uncategorized
                                    </MenuItem>
                                    {folderList.map((folder, i) => (
                                      <MenuItem
                                        hidden={folder == el.folder}
                                        onClick={() =>
                                          dispatch(
                                            updateLaunchTemplate({
                                              _id: el._id,
                                              folder: folder,
                                            }),
                                          )
                                        }
                                        key={i}
                                      >
                                        {folder}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </Menu>
                                <MenuItem
                                  hidden={!el.isArchived}
                                  onClick={(e) => {
                                    dispatch(
                                      deleteLaunchTemplate({
                                        _id: el._id,
                                      }),
                                    )
                                  }}
                                >
                                  Delete
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </div>
                        </div>
                        <Progress
                          colorScheme={
                            el.completedSteps == el.steps.length
                              ? 'green'
                              : 'red'
                          }
                          size="sm"
                          value={(el.completedSteps / el.steps.length) * 100}
                          className="mt-4"
                        />
                        <div
                          className={`flex my-2 font-semibold text-${
                            el.completedSteps == el.steps.length
                              ? 'green'
                              : 'red'
                          }-500 text-lg`}
                        >
                          <h1 className="flex-grow flex-1">{el.status}</h1>
                          <h1>
                            {el.completedSteps}/{el.steps.length}
                          </h1>
                        </div>
                        <div
                          hidden={true}
                          className="h-[15rem] overflow-y-scroll [&::-webkit-scrollbar]:hidden"
                        >
                          {el.activity &&
                            el.activity.map((day) => (
                              <div key={day.date} className="my-4">
                                {/* Heading */}
                                <div className="ps-2 my-2 first:mt-0">
                                  <h3 className="text-xs font-medium uppercase text-gray-500">
                                    {day.date}
                                  </h3>
                                </div>
                                {/* End Heading */}
                                {/* Items */}
                                {day.data.map((item, index) => (
                                  <div key={index} className="flex gap-x-3">
                                    {/* Icon */}
                                    <div className="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 ">
                                      <div className="relative z-10 size-7 flex justify-center items-center">
                                        <div className="size-2 rounded-full bg-gray-400" />
                                      </div>
                                    </div>
                                    {/* End Icon */}
                                    {/* Right Content */}
                                    <div className="grow pt-0.5 pb-8">
                                      <h3 className="flex gap-x-1.5 font-semibold text-gray-800">
                                        <svg
                                          className="flex-shrink-0 size-4 mt-1"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={24}
                                          height={24}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        >
                                          <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z" />
                                          <polyline points="14 2 14 8 20 8" />
                                          <line
                                            x1={16}
                                            x2={8}
                                            y1={13}
                                            y2={13}
                                          />
                                          <line
                                            x1={16}
                                            x2={8}
                                            y1={17}
                                            y2={17}
                                          />
                                          <line x1={10} x2={8} y1={9} y2={9} />
                                        </svg>
                                        {item.heading}
                                      </h3>
                                      <p className="mt-1 text-sm text-gray-600">
                                        {item.desc}
                                      </p>
                                      <button
                                        type="button"
                                        className="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none "
                                      >
                                        <img
                                          className="flex-shrink-0 size-4 rounded-full"
                                          src={
                                            item.avatar || 'default-avatar-url'
                                          }
                                          alt="Image Description"
                                        />
                                        {item.username}
                                      </button>
                                    </div>
                                    {/* End Right Content */}
                                  </div>
                                ))}
                                {/* End Items */}
                              </div>
                            ))}
                        </div>
                        <div className="flex">
                          <div className="flex flex-grow flex-1">
                            <StarIcon
                              fill={el.isFavourite ? '#FFC923' : 'white'}
                              className="w-6 h-6 cursor-pointer"
                              onClick={(e) => {
                                handleToggleFavorite(e, el)
                                e.stopPropagation()
                              }}
                            />
                            <div
                              onClick={(e) => {
                                dispatch(
                                  updateLaunchTemplate({
                                    _id: el._id,
                                    isArchived: !el.isArchived,
                                  }),
                                )
                                e.stopPropagation()
                              }}
                              className="mx-4 cursor-pointer"
                            >
                              {el.isArchived ? 'Unarchive' : 'Archive'}
                            </div>
                          </div>
                          <Avatar size="xs" name={el.createdBy} />
                        </div>
                      </div>
                    </div>
                  )
                })
              : null}
          </div>
        </div>
        <FilterSidebar
          filterSidebarIsOpen={filterSidebarIsOpen}
          filterSidebarOnOpen={filterSidebarOnOpen}
          filterSidebarOnClose={filterSidebarOnClose}
          applySidebarFilters={handleFilterChange}
          selectedFilters={filters}
          setSelectedFilters={setFilters}
          tempFolderName={tempFolderName}
          setTempFolderName={setTempFolderName}
          folderList={folderList}
          setFolderList={setFolderList}
          filteredFolderList={filteredFolderList}
          setFilteredFolderList={setFilteredFolderList}
        />
        <CreatePreset
          createPresetIsOpen={createPresetIsOpen}
          createPresetOnOpen={createPresetOnOpen}
          createPresetOnClose={createPresetOnClose}
        />
      </div>
    </div>
  )
}
