import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
  useDisclosure,
  Box,
  Stack,
  FormControl,
  FormLabel,
} from '@chakra-ui/react'
import {
  AdjustmentsHorizontalIcon,
  ArrowsPointingInIcon,
  CheckCircleIcon,
  CheckIcon,
  ClipboardDocumentCheckIcon,
  ClockIcon,
  DocumentCheckIcon,
  DocumentDuplicateIcon,
  EnvelopeIcon,
  InboxStackIcon,
  PaperAirplaneIcon,
  PaperClipIcon,
  PencilIcon,
  PlayIcon,
  PlusIcon,
  RectangleStackIcon,
  RocketLaunchIcon,
  SpeakerWaveIcon,
  UserGroupIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import { Select as Select2 } from '@chakra-ui/react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Portal,
} from '@chakra-ui/react'
import {
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  TagCloseButton,
} from '@chakra-ui/react'
import { Avatar, AvatarBadge, AvatarGroup } from '@chakra-ui/react'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import Editor from 'react-simple-wysiwyg'
import FormBuilder from '../utils/FormBuilder'
import { Switch, useToast } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import {
  openCreateTaskModal,
  closeCreateTaskModal,
} from '../../redux/CreateTaskModal'
import {
  openInviteMemberModal,
  closeInviteMemberModal,
} from '../../redux/InviteMemberModal'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import FocusLock from 'react-focus-lock'
import { fetchMembers } from '../../redux/MemberSlice'
import { addTask } from '../../redux/TaskSlice'
import RichTextEditor from '../utils/RichTextEditor'
import Select from "react-select";


function CreateTask() {
  const [tags, setTags] = useState(['foo'])
  const [tagSearch, setTagSearch] = useState('')

  const dispatch = useDispatch()

  const navigate = useNavigate()
  const isModalOpen = useSelector((state) => state.createTaskModal.isModalOpen)

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleOpenModal = () => {
    dispatch(openCreateTaskModal())
    // Additional logic, if needed
    onOpen()
  }

  const handleCloseModal = () => {
    dispatch(closeCreateTaskModal())
    // Additional logic, if needed
    onClose()
  }

  const state = useSelector((state) => state)

  const [data, setData] = useState({
    taskType: 'task',
    name: '',
    desc: '',
    assignedTo: { type: 'fixed', value: [] },
    guests: [],
    start: {
      date: null,
    },
    deadline: {
      date: null,
    },
    html: '',
    linkTo: ['', ''],
    forms: [
    ],
    tags: ['Project', 'Proposal'],
    allAssigneesMustComplete: false,
    enforceMaxAssignees: true,
    maxAssignees: null,
    preventGuestComments: true,
    emitWebhookOnCompletion: false,
    targetUrl: null,
  })

  const [disabled, setDisabled] = useState(false)

  const toast = useToast()

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(data)
    if (data && data.name && data.name.length > 1) {
      setDisabled(true)
      dispatch(addTask(data)) // Dispatch the addTask action with the new task data
        .then(() => {
          toast({
            title: 'Task Created!',
            status: 'success',
            duration: 9000,
            isClosable: true,
          })
        })
        .catch((error) => {
          toast({
            title: error.message || 'Something went wrong!',
            status: 'error',
            duration: 9000,
            isClosable: true,
          })
        })
        .finally(() => {
          setDisabled(false)
        })
    } else {
      toast({
        title: 'Invalid task data!',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }

  
  const [options, setOptions] = useState([])


  useEffect(() => {
    dispatch(fetchMembers())
    
    const temp = state?.members?.data?.members?.map((el) => ({
      value: el.email,
      label: `${el.fName} ${el.lName}`,
    }));


    setOptions(temp)

  }, [])

  useEffect(()=>{
    setData({
      ...data,
      name: state?.createTaskModal?.searchTask || "",
      
    })
  }, [state])

  return (
    <>
      <Modal size={'2xl'} isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent zIndex={10} className="w-full">
          <ModalHeader>Create a Task</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            zIndex={10}
            className="flex flex-col mx-auto justify-center items-center space-y-4 w-full"
          >
            <InputGroup>
              <Input
                type="text"
                value={data.name}
                onChange={(e) => setData({ ...data, name: e.target.value })}
                placeholder="Name this task"
              />
              <InputRightAddon className="p-0 m-0 w-[10rem]">
                <Select2
                  value={data.taskType}
                  onChange={(e) =>
                    setData({ ...data, taskType: e.target.value })
                  }
                  placeholder="Select option"
                  className="w-[10rem]"
                >
                  <option value="task">
                    {' '}
                    <CheckCircleIcon className="w-4 h-4" /> Task
                  </option>
                  <option value="approve/reject">
                    {' '}
                    <AdjustmentsHorizontalIcon className="w-4 h-4" />{' '}
                    Approve/Reject
                  </option>
                  <option value="expiring">
                    {' '}
                    <ClockIcon className="w-4 h-4" /> Expiring
                  </option>
                  <option value="emailDraft">
                    {' '}
                    <EnvelopeIcon className="w-4 h-4" /> Email Draft
                  </option>
                  <option value="emailAutoSend">
                    {' '}
                    <PaperAirplaneIcon className="w-4 h-4" /> Email Auto-Send
                  </option>
                </Select2>
              </InputRightAddon>
            </InputGroup>
            <InputGroup>
              <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                Desc
              </InputLeftAddon>
              <Input
                type="text"
                value={data.desc}
                onChange={(e) => setData({ ...data, desc: e.target.value })}
                placeholder="Write a short description about this..."
              />
            </InputGroup>
            <InputGroup zIndex={1000}>
    <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
      <UserGroupIcon className="w-4 h-4" />
    </InputLeftAddon>
    <div className="flex-1 z-1000">
      <Select
        placeholder="Search"
        options={options} // Dropdown options with names as labels and emails as values
        isMulti // Allow multiple selection
        onChange={(selectedOptions) => {
          setData({
            ...data,
            assignedTo: {
              ...data?.assignedTo,
              value: selectedOptions.map((option) => option.value), // Store emails
            },
          });
        }}
        value={data?.assignedTo?.value.map((email) => {
          // Map selected emails to corresponding member names
          const member = state?.members?.data?.members?.find(
            (el) => el.email === email
          );
          return member
            ? { value: member.email, label: `${member.fName} ${member.lName}` }
            : { value: email, label: email }; // Fallback for missing members
        })}
        styles={{
          control: (base) => ({
            ...base,
            border: "1px solid #CBD5E0", // Chakra gray-300 equivalent
            borderRadius: "0.375rem", // Chakra borderRadius 'md'
            minHeight: "2.5rem", // Adjust height
            boxShadow: "none",
            zIndex: "1000",
            borderLeft: "0"
          }),
        }}
      />
    </div>
  </InputGroup>
            <InputGroup zIndex={10}>
              <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                Start
              </InputLeftAddon>
              <Input
                placeholder="Select Date and Time"
                size="md"
                type="date"
                zIndex={10}
                value={data.start.date}
                onChange={(e) =>
                  setData({
                    ...data,
                    start: { ...data.start, date: e.target.value },
                  })
                }
              />
            </InputGroup>
            <InputGroup zIndex={10}>
              <InputLeftAddon className="w-[4rem] mx-auto justify-center flex center text-center">
                Finish
              </InputLeftAddon>
              <Input
                placeholder="Select Date and Time"
                size="md"
                type="date"
                zIndex={10}
                value={data.deadline.date}
                onChange={(e) =>
                  setData({
                    ...data,
                    deadline: { ...data.deadline, date: e.target.value },
                  })
                }
              />
            </InputGroup>

            {/* Advance Settings */}
            <Accordion className="w-full" allowToggle>
              <AccordionItem>
                <h2>
                  <AccordionButton>
                    <Box as="span" flex="1" textAlign="right" className="mr-2">
                      Advance Settings
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel className="space-y-4 w-full" pb={4}>
                  <RichTextEditor
                    value={data.html}
                    onChange={(html) => setData({ ...data, html: html })}
                  />
                  <div>
                    <Text className="flex font-bold text-lg mt-8 mb-2">
                      <PaperClipIcon className="w-6 h-6" />
                      Link this task to
                    </Text>
                    <Select2
                      value={data.linkTo[0]}
                      onChange={(e) => {
                        // Assuming index is the index of the value you want to edit
                        const updatedLinkTo = [...data.linkTo]
                        updatedLinkTo[0] = e.target.value // Update the second value
                        setData({ ...data, linkTo: updatedLinkTo })
                      }}
                      placeholder="Select option"
                      className="w-full"
                    >
                      <option value="process">
                        <DocumentCheckIcon className="w-4 h-4" />{' '}
                        <div>
                          <h1>Process - </h1>
                          <p>Insert an ad-hoc task into an active process.</p>
                        </div>
                      </option>

                      <option value="template">
                        {' '}
                        <InboxStackIcon className="w-4 h-4" />{' '}
                        <div>
                          <h1>Templates - </h1>
                          <p>
                            Link a standalone task to a template or template
                            step.
                          </p>
                        </div>
                      </option>
                    </Select2>
                    {data.linkTo[0] ? (
                      <Select2
                        value={data.linkTo[1]}
                        onChange={(e) => {
                          // Assuming index is the index of the value you want to edit
                          const updatedLinkTo = [...data.linkTo]
                          updatedLinkTo[1] = e.target.value // Update the second value
                          setData({ ...data, linkTo: updatedLinkTo })
                        }}
                        placeholder="Search for active processes"
                        className="w-full"
                      >
                        <option value="process">option 1</option>

                        <option value="template">option 2</option>
                      </Select2>
                    ) : null}
                    <div className="p-2 border my-4">
                      <Accordion allowToggle>
                        <AccordionItem>
                          <h2>
                            <AccordionButton>
                              <Text className="flex font-bold text-lg mt-4 mb-2">
                                <DocumentDuplicateIcon className="w-6 h-6" />
                                Forms
                              </Text>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <FormBuilder
                              formData={data.forms}
                              data={data}
                              setData={setData}
                            />
                          </AccordionPanel>
                        </AccordionItem>
                      </Accordion>
                    </div>
                    <input
                      type="text"
                      placeholder="Tags"
                      onChange={(e) => setTagSearch(e.target.value)}
                      value={tagSearch}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          if (!data.tags.includes(tagSearch)) {
                            setData({
                              ...data,
                              tags: [...data.tags, tagSearch],
                            })
                          }
                          setTagSearch('')
                        }
                      }}
                    />
                    <div className="flex flex-row space-x-2 mt-2">
                      {data.tags.map((title, i) => (
                        <Tag
                          size={'md'}
                          key={i}
                          variant="solid"
                          colorScheme="teal"
                          className="w-fit"
                        >
                          {title}
                          <button
                            onClick={() => {
                              const updatedTags = data.tags
                              updatedTags.splice(i, 1)
                              setData({ ...data, tags: updatedTags })
                            }}
                          >
                            <TagCloseButton />
                          </button>
                        </Tag>
                      ))}
                    </div>
                  </div>
                  {/* <FormControl
                    className="flex flex-col space-y-4 py-4"
                    alignItems="left"
                  >
                    <div
                      onClick={(e) =>
                        setData({
                          ...data,
                          allAssigneesMustComplete: e.target.checked,
                        })
                      }
                      className="flex justify-between"
                    >
                      <FormLabel htmlFor="1" mb="0">
                        All assignees must complete this task
                      </FormLabel>
                      <Switch
                        id="1"
                        isChecked={data.allAssigneesMustComplete}
                      />
                    </div>

                    <div
                      onClick={(e) =>
                        setData({
                          ...data,
                          enforceMaxAssignees: e.target.checked,
                        })
                      }
                      className="flex justify-between"
                    >
                      <FormLabel htmlFor="2" mb="0">
                        Enforce a maximum number of assignees?
                      </FormLabel>
                      <Switch id="2" isChecked={data.enforceMaxAssignees} />
                    </div>
                    <Input
                      hidden={!data.enforceMaxAssignees}
                      className="my-2"
                      placeholder="Max No. of Assignees"
                      value={data.maxAssignees}
                      onChange={(e) => {
                        setData({ ...data, maxAssignees: e.target.value })
                      }}
                    />

                    <div
                      onClick={(e) =>
                        setData({
                          ...data,
                          preventGuestComments: e.target.checked,
                        })
                      }
                      className="flex justify-between"
                    >
                      <FormLabel htmlFor="3" mb="0">
                        Prevent guests from making or seeing any comments?
                      </FormLabel>
                      <Switch id="3" isChecked={data.preventGuestComments} />
                    </div> 

                    <div
                      onClick={(e) =>
                        setData({
                          ...data,
                          emitWebhookOnCompletion: e.target.checked,
                        })
                      }
                      className="flex justify-between"
                    >
                      <FormLabel htmlFor="4" mb="0">
                        Emit a webhook when this task is completed?
                      </FormLabel>
                      <Switch id="4" isChecked={data.emitWebhookOnCompletion} />
                    </div>
                    <Input
                      className="my-2"
                      hidden={!data.emitWebhookOnCompletion}
                      placeholder="Target URL"
                      value={data.targetUrl}
                      onChange={(e) =>
                        setData({ ...data, targetUrl: e.target.value })
                      }
                    />
                  </FormControl> */}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </ModalBody>

          <ModalFooter zIndex={1} className="mt-8">
            <Button colorScheme="blue" mr={3} onClick={(e) => handleSubmit(e)}>
              Create Task
            </Button>
            <Button onClick={handleCloseModal} colorScheme="gray">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default CreateTask
