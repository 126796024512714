import React, { useState } from 'react'
import Header from '../../../components/Header'
import {
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  CloseButton,
  FormLabel,
  Heading,
  Input,
  Select,
  Switch,
  Text,
  useDisclosure,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Tag,
  TagCloseButton,
} from '@chakra-ui/react'
import {
  openTemplateLaunchModal,
  closeTemplateLaunchModal,
} from '../../../redux/TemplateLaunchModal'
import TemplateLaunch from '../../../components/TemplateLaunch'

import {
  Cog6ToothIcon,
  ChevronRightIcon,
  DocumentDuplicateIcon,
  ClipboardIcon,
  XMarkIcon,
  CheckCircleIcon,
  AdjustmentsHorizontalIcon,
  ClockIcon,
  EnvelopeIcon,
  PaperAirplaneIcon,
  ChatBubbleBottomCenterIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/solid'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/react'
import FormBuilder from '../../../components/utils/FormBuilder'
import Editor from 'react-simple-wysiwyg'
import DrawerExample from './ConfigDrawer'
import { MultiSelect } from 'chakra-multiselect'
import {
  openInviteMemberModal,
  closeInviteMemberModal,
} from '../../../redux/InviteMemberModal'
import {
  openCreateGroupModal,
  closeCreateGroupModal,
} from '../../../redux/CreateGroupsModal'
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Textarea,
} from '@chakra-ui/react'
import AddAutomations from './AddAutomations'
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
function CreateDocument() {
  const dispatch = useDispatch()

  const [html, setHtml] = useState('my <b>HTML</b>')
  const [trigger, setTrigger] = useState(null)
  const [step, setStep] = useState(null)
  const [assign, setAssign] = useState('fixed')

  const [startOnSpecificTime, setStartOnSpecificTime] = useState(false)

  const [taskType, setTaskType] = useState('task')

  const [value, setValue] = useState([])
  const options = [
    { value: 'option1', label: 'Option 1' },
    { value: 'option2', label: 'Option 2' },
    { value: 'option3', label: 'Option 3' },
    // Add more options as needed
  ]

  const [tags, setTags] = useState(['foo', 'bar'])
  const [tagSearch, setTagSearch] = useState('')

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [data, setData] = useState({
    taskType: 'task',
    name: '',
    desc: '',
    assignedTo: { type: 'fixed', value: [] },

    guests: [],
    start: {
      date: null
    },
    deadline: {
      date: null
    },
    html: '',
    linkTo: ['', ''],
    forms: [
      // {
      //   type: 'header',
      //   subtype: 'h1',
      //   label: 'formBuilder in React',
      // },
      // {
      //   type: 'paragraph',
      //   label:
      //     'This is a demonstration of formBuilder running in a React project.',
      // },
    ],
    tags: ['Project', 'Proposal'],
    allAssigneesMustComplete: false,
    enforceMaxAssignees: true,
    maxAssignees: null,
    preventGuestComments: true,
    emitWebhookOnCompletion: false,
    targetUrl: null,
  })

  return (
    <div className="overflow-y-hidden max-h-screen">
      {' '}
      <Header />
      <div className="ml-2 lg:ml-40 mr-2 space-y-4 py-10 flex flex-row gap-x-4 max-h-[90vh] overflow-y-scroll">
        <div className="w-full mx-auto p-6 shadow-sm">
          <div className="w-full border-b border-gray-200 pb-4 mb-4">
            <div className="w-full flex justify-between items-center">
              <div className="w-full flex flex-col space-x-2">
                <div className="w-full flex flex-col space-y-4 lg:flex-row">
                  {' '}
                  <div className="w-full flex-1">
                    <div className="mt-2 font-bold text-gray-600">Document</div>
                    <Link className="text-blue-600" href="#">
                      Templates
                    </Link>
                    <span className="mx-1 text-gray-500">{`>`}</span>
                    <Link className="text-blue-600" href="#">
                      Template Test Name
                    </Link>
                  </div>
                  <div className="flex space-x-2 items-center">
                    <Button
                      onClick={onOpen}
                      className="text-gray-600"
                      variant="ghost"
                    >
                      <Cog6ToothIcon className="w-4 h-4 text-current" />
                    </Button>
                    <Button className="bg-blue-600 text-white">Share</Button>
                    <Button className="bg-blue-600 text-white">
                      USE DOCUMENT
                    </Button>
                  </div>
                </div>
                <div className="py-20 ">
                  <FormBuilder
                    formData={data.forms}
                    data={data}
                    setData={setData}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DrawerExample isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
      <TemplateLaunch />
    </div>
  )
}

export default CreateDocument
